import React from "react";
import { connect } from "react-redux";
import PDFUtil from "../../util/PDFUtil";
import DateUtil from "../../util/DateUtil";
import { FormattedMessage, injectIntl } from "react-intl";
import OrderProducts from "./OrderProducts";
import { NotificationManager } from "react-notifications";
import OrderPDF from "./OrderPDF";
import PDFViewer from "../pdf/PDFViewer";
import OrderStatus from "../../enums/OrderStatus";
import { deleteOrder, duplicate, validate, modifyunvalidated } from "../../actions/orders/orders";
import "react-notifications/lib/notifications.css";
import Maths from "../../util/Maths";
import Icon from "../sub/Icon";
import ActionMenu from "../sub/ActionMenu";

class OrderRow extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      collapsed: true,
      displayPDF: false,
      disableExport: false,
      disabledDuplicat: false,
    };

    // List of products we have already attempted to fetch from BE
    this.alreadySent = {};

    // Enabled to know if the order contains outdated products or not
    this.outdated = false;
  }

  collapse() {
    this.setState({
      collapsed: !this.state.collapsed,
    });
  }

  deleteOrder(e, orderId, role) {
    var data = { orderId: orderId };
    this.props.onDeleteOrder(data);
  }

  hasUrgent(products) {
    for (var product of products) {
      if (product.urgent) return true;
    }

    return false;
  }

  canPDF(order) {
    return (
      order.status === OrderStatus.TBV ||
      order.status === OrderStatus.NEW ||
      order.status === OrderStatus.PENDING ||
      order.status === OrderStatus.SENT ||
      order.status === OrderStatus.RECEIVED
    );
  }

  togglePDF(pdfElemId, pdfFileName) {
    if (this.state.disableExport || !this.canPDF(this.props.order)) return;
    if (process.env.REACT_APP_PDF_OVERVIEW === "true") {
      // Possibility n°1 (dev env) : display preview of PDF (without header, footer, pages etc.)
      this.setState({ displayPDF: !this.state.displayPDF });
    } else {
      // Possibility n°2 (prod env) : directly save to PDF
      this.setState({ disableExport: true });
      var successCallback = () => this.setState({ disableExport: false });
      PDFUtil.toPDF(
        this.props.generalSettings,
        this.props.company,
        pdfElemId,
        pdfFileName,
        "/css/orderPdf.css",
        successCallback
      );
    }
  }

  duplicate(orderId) {
    if (this.state.disabledDuplicat) return;

    this.setState({ disabledDuplicat: true });

    var successCallback = () => {
      NotificationManager.info(
        this.props.intl.formatMessage({ id: "Cart.duplicate" })
      );
      this.setState({ disabledDuplicat: false });
    };

    this.props.onDuplicate(orderId, successCallback);
  }

  validate(e, order) {
    // e.target.disabled = true;

    this.props.onValidate(order._id);
  }

  modify(e, order) {
    // e.target.disabled = true;

    this.props.onModifyUnvalidated(order._id);
  }

  checkActiveMercurial(mercurialId) {
    if (mercurialId === this.props.establishmentSettings.mercurial_id) {
      return false;
    }
    return true;
  }

  render() {
    const orderDate = DateUtil.getDateWithOffset(this.props.order.date, 0, true)
    const shippingDate = DateUtil.getDateWithOffset(this.props.order.date, this.props.order.shipping_delay);

    // 'Delete order' modal setup
    var modalDeleteTitle = <FormattedMessage id="Confirm" />;
    var modalDeleteContent = (
      <FormattedMessage id="Order.Remove.Confirmation" />
    );
    var successDeleteCallback = (e) =>
      this.deleteOrder(e, this.props.order._id);

    // 'Validate order' modal setup
    var modalValidateTitle = <FormattedMessage id="Confirmation.Required" />;
    var modalValidateContent = (
      <FormattedMessage id="Order.Validate.Confirmation" />
    );
    var modalModifyTitle = <FormattedMessage id="Confirmation.Required" />;
    var modalModifyContent = (
      <div>
        <p>
          <FormattedMessage id="Order.Modify.Confirmation" />
        </p>
        <p>
          <FormattedMessage id="Confirm.Choice" />
        </p>
      </div>
    );
    var successValidateCallback = (e) => this.validate(e, this.props.order);
    var successModifyCallback = (e) => this.modify(e, this.props.order);
    let pdfElemId = "order-pdf-" + this.props.order._id;
    let pdfFileName = "order-" + this.props.order._id;
    let pdfOrder = null;
    pdfOrder = (
      <OrderPDF
        id={pdfElemId}
        order={this.props.order}
        admin={this.props.admin}
        clientName={this.props.order.client_name}
        establishment={this.props.establishment}
      />
    );

    var orderStatusMessage = (
      <FormattedMessage id={"Order.Status." + this.props.order.status} />
    );

    let orderStatusClassName;

    switch (this.props.order.status) {
      case OrderStatus.NEW:
      case OrderStatus.PENDING:
        orderStatusClassName = "info";
        break;
      case OrderStatus.SENT:
        orderStatusClassName = "success";
        break;
      case OrderStatus.BLOCKED:
        orderStatusClassName = "warning";
        break;
      case OrderStatus.CANCELED:
        orderStatusClassName = "secondary";
        break;
      case OrderStatus.SENDING:
        orderStatusClassName = "info";
        break;
      case OrderStatus.TBV:
      default:
        orderStatusClassName = "danger";
        break;
    }

    let menuItems = [];

    menuItems.push(
      // Show order details
      {
        icon: this.state.collapsed ? "eye" : "eye-slash",
        action: () => {
          this.collapse("prods-" + this.props.order._id);
        },
        text: <FormattedMessage id="Display.Product.Order" />,
      },
      // Show/hide products
      (this.props.order.status === OrderStatus.TBV &&
        this.props.user.validator) && {
        id: "validate" + this.props.order._id,
        icon: "thumbs-up",
        action: () => {
          this.props.openConfModal(
            modalValidateTitle,
            modalValidateContent,
            successValidateCallback
          );
        },
        text: <FormattedMessage id="Validate.Order" />,
      },
      // Decline/Modify order
      (this.props.order.status === OrderStatus.TBV &&
        this.props.user.validator) && {
        icon: "pen-to-square",
        action: (e) => {
          this.props.openConfModal(
            modalModifyTitle,
            modalModifyContent,
            successModifyCallback
          )
        },
        text: <FormattedMessage id="Modify.Order.TBV" />,
      },
      // Split orders
      {
        id: "duplicate" + this.props.order._id,
        icon: "cart-plus",
        disabled: this.checkActiveMercurial(this.props.order.mercurial._id) ||
          this.props.order.mercurial.status === 1 ||
          new Date(this.props.order.mercurial.end_date).getTime() <
          Date.now() ||
          this.state.disabledDuplicat ||
          this.props.order.status === OrderStatus.TBV,
        action: () => {
          this.duplicate({ orderId: this.props.order._id });
        },
        text: <FormattedMessage id="Duplicate.To.New.Order" />,
      },
      // Download PDF
      {
        id: "downloadPdf" + this.props.order._id,
        icon: "file-pdf",
        disabled: this.state.disableExport || !this.canPDF(this.props.order),
        action: () => {
          this.togglePDF(pdfElemId, pdfFileName);
        },
        text: <FormattedMessage id="Download.PDF" />,
      },
      // Delete order
      {
        id: "delete" + this.props.order._id,
        icon: "trash",
        disabled: !(
          (this.props.order.status === OrderStatus.TBV &&
            this.props.user.validator)
        ),
        action: () => this.props.openConfModal(modalDeleteTitle, modalDeleteContent, successDeleteCallback),
        text: <FormattedMessage id="Delete" />,
      },
    );

    let menuVariant;
    let menuIcon;
    let menuHeader;

    switch (true) {
      case (this.props.order.status === OrderStatus.TBV && this.props.user.validator):
        menuIcon = "thumbs-up";
        menuVariant = "danger";
        menuHeader = <span className="text-danger"><Icon icon="exclamation-triangle" className="mr-2" /><FormattedMessage id="Waiting.Validation" /></span>;
        break;
      case this.hasUrgent(this.props.order.products):
        menuIcon = "exclamation-triangle";
        menuVariant = "warning";
        menuHeader = <span className="text-danger"><Icon icon="exclamation-triangle" className="mr-2" /><FormattedMessage id="Order.Urgent" /></span>;
        break;
      default:
        break;
    }

    let menuAction = (
      <ActionMenu
        items={menuItems}
        variant={menuVariant}
        icon={menuIcon}
        header={menuHeader}
        processing={this.state.disableExport}
      />
    );

    return (
      <React.Fragment>
        <tr key={this.props.order._id} className={"d-flex"}>
          <td className="col align-middle">
            {this.props.order.client_name}
          </td>
          <td className="col align-middle">
            {this.props.order.ref}
            {this.props.order.comment && this.props.comment !== "" && (
              <span>
                <Icon
                  id={"comment" + this.props.order._id}
                  size="xl" className="text-info float-right"
                  icon="comments"
                  hover={<><FormattedMessage id="Available.Comment" /> :<br />{this.props.order.comment}</>}
                  clickable
                />
              </span>
            )}
          </td>
          <td className="col align-middle">
            {orderDate}
          </td>
          <td className="col align-middle">
            {shippingDate}
          </td>
          <td className="col align-middle">
            <div>{Maths.round(this.props.order.total_ttc)}€</div>
          </td>
          <td className="col col-2 align-middle">
            <div className={"alert alert-" + orderStatusClassName + " p-2"}>
              {orderStatusMessage}
            </div>
          </td>
          {!this.props.limit && (
            <td className="col col-1 text-center">
              {menuAction}
              {
                <PDFViewer
                  open={this.state.displayPDF}
                  toggle={(pdfElemId, pdfFileName) => {
                    this.togglePDF(pdfElemId, pdfFileName);
                  }}
                  template={pdfOrder}
                  fileName={pdfFileName}
                />
              }
            </td>
          )}
        </tr>

        {!this.state.collapsed && <tr className="d-flex">
          <td colSpan="7" className="col p-1">
            <OrderProducts order={this.props.order} paginate={true} />
          </td>
        </tr>}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.user,
    establishmentSettings: state.establishmentSettings,
    clients: state.clients,
    generalSettings: state.generalSettings,
    company: state.company,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onDeleteOrder: (data) => dispatch(deleteOrder(data)),
    onDuplicate: (data, successCallback) =>
      dispatch(duplicate(data, successCallback)),
    onValidate: (orderId) => dispatch(validate(orderId)),
    onModifyUnvalidated: (orderId) => dispatch(modifyunvalidated(orderId)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(OrderRow));
