import { GET_PAYMENT_DEADLINES } from "../../actions/configurables/paymentDeadlines";

export default function paymentDeadlines(state = [], action) {
  switch (action.type) {
    case GET_PAYMENT_DEADLINES:
      return action.paymentDeadlines;
    default:
      return state;
  }
}
