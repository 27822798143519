import { Pagination } from "react-bootstrap";
import Icon from "./Icon.js";

const CustomPagination = ({
  activePage,
  itemsCountPerPage,
  totalItemsCount,
  pageRangeDisplayed,
  onChange,
  variant = "info",
  disabled = false,
}) => {
  const totalPages = Math.ceil(totalItemsCount / itemsCountPerPage);

  const startPage = Math.max(
    1,
    activePage - Math.floor(pageRangeDisplayed / 2)
  );
  const endPage = Math.min(totalPages, startPage + pageRangeDisplayed - 1);

  const handleChange = (pageNumber) => {
    if (
      pageNumber !== activePage &&
      pageNumber > 0 &&
      pageNumber <= totalPages
    ) {
      onChange(pageNumber);
    }
  };

  const pageItems = [];
  for (let i = startPage; i <= endPage; i++) {
    pageItems.push(
      <Pagination.Item
        key={i}
        active={i === activePage}
        disabled={disabled}
        onClick={() => handleChange(i)}
        className={`page-item-${variant} ${
          i === activePage ? `bg-${variant} text-white` : `text-${variant}`
        }`}
      >
        {i}
      </Pagination.Item>
    );
  }

  return (
    <Pagination className={`justify-content-center pagination-${variant}`}>
      <Pagination.First
        onClick={() => handleChange(1)}
        disabled={activePage === 1 || disabled}
        className={`text-${variant}`}
      >
        <Icon icon="caret-left" fw />
        <Icon icon="caret-left" fw />
      </Pagination.First>
      <Pagination.Prev
        onClick={() => handleChange(activePage - 1)}
        disabled={activePage === 1 || disabled}
        className={`text-${variant}`}
      >
        <Icon icon="caret-left" fw />
      </Pagination.Prev>

      {pageItems}

      <Pagination.Next
        onClick={() => handleChange(activePage + 1)}
        disabled={activePage === totalPages || disabled}
        className={`text-${variant}`}
      >
        <Icon icon="caret-right" fw />
      </Pagination.Next>
      <Pagination.Last
        onClick={() => handleChange(totalPages)}
        disabled={activePage === totalPages || disabled}
        className={`text-${variant}`}
      >
        <Icon icon="caret-right" fw />
        <Icon icon="caret-right" fw />
      </Pagination.Last>
    </Pagination>
  );
};

export default CustomPagination;
