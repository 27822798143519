import React from "react";
import { connect } from "react-redux";
import { FormattedMessage } from "react-intl";
import { withRouter } from "react-router-dom";
import MenuProfile from "./MenuProfile";
import RequestStatus from "../../../enums/RequestStatus";
import OrderStatus from "../../../enums/OrderStatus";
import RequestTypes from "../../../enums/RequestTypes";
import APIUrl from "../../../APIUrl";
import { NavLink } from "react-router-dom";
import MenuLogo from "./MenuLogo";
import { logout } from "../../../actions/authentication/authentication";
import { Link } from "react-router-dom";
import Util from "../../../util/Util"

class MenuAdmin extends React.Component {
  logout(e) {
    e.preventDefault();
    e.stopPropagation();

    // Store a copy of the token before deleting it
    let token = APIUrl.jwtToken;

    // let that = this;

    function onLogoutSuccess() {
      // that.props.history.push('/');
      return window.location.replace(Util.checkHttps(process.env.REACT_APP_LOGIN_URL) + token);
    }

    // Call logout and redirect on success
    this.props.onLogout(onLogoutSuccess);
  }

  countNewRequests(requests) {
    var nbr = 0;
    for (var request of requests) {
      if (request.request_type === RequestTypes.NEW_MATERIAL) continue;

      if (request.status === RequestStatus.NEW) nbr++;
    }

    return nbr;
  }

  countNewOrders() {
    var nbr = 0;

    for (let order of this.props.orders) {
      if (order.status === OrderStatus.NEW) nbr++;
    }

    return nbr;
  }

  render() {
    var newOrdersNbr = this.countNewOrders();

    return (
      <nav className="navbar navbar-expand-lg menu">
        <div
          id="menu"
          className="collapse navbar-collapse mb-3 mb-md-0 mt-2 pb-2"
        >
          <ul className="navbar-nav list-inline text-center align-items-center">
            <li className="nav-item list-inline-item align-middle">
              <Link
                to="/#"
                className="logo-item"
                onClick={(e) => this.logout(e)}
              >
                <img
                  className="logo-header"
                  alt="logo e4coll"
                  src="./images/svg/logo-header-e4coll.svg"
                />
              </Link>
            </li>

            <li className="nav-item list-inline-item align-middle text-nowrap">
              <NavLink
                className="w-100 p-3"
                to="/home"
                exact
                activeClassName="selected"
              >
                <FormattedMessage id="Home" />
              </NavLink>
            </li>

            <li className="nav-item list-inline-item align-middle text-nowrap">
              <NavLink
                className="w-100 p-3"
                to="/home/mercuriales"
                exact
                activeClassName="selected"
              >
                <FormattedMessage id="Mercurials" />
              </NavLink>
            </li>

            <li className="nav-item list-inline-item align-middle text-nowrap">
              <NavLink
                className="w-100 p-3"
                to="/home/orders"
                exact
                activeClassName="selected"
              >
                <FormattedMessage id="Orders" />
                {newOrdersNbr > 0 && (
                  <span className="badge badge-danger">{newOrdersNbr}</span>
                )}
              </NavLink>
            </li>

            <li className="nav-item list-inline-item align-middle text-nowrap">
              <NavLink
                className="w-100 p-3"
                to="/home/fleet"
                exact
                activeClassName="selected"
              >
                <FormattedMessage id="Fleet" />
              </NavLink>
            </li>

            <li className="nav-item list-inline-item align-middle text-nowrap">
              <NavLink
                className="w-100 p-3"
                to="/home/clients"
                exact
                activeClassName="selected"
              >
                <FormattedMessage id="Clients" />
              </NavLink>
            </li>

            <li className="nav-item list-inline-item align-middle text-nowrap">
              <NavLink
                className="w-100 p-3"
                to="/home/requests"
                exact
                activeClassName="selected"
              >
                <FormattedMessage id="Interventions" />
                {this.countNewRequests(this.props.requests) > 0 && (
                  <span className="badge badge-danger">
                    {this.countNewRequests(this.props.requests)}
                  </span>
                )}
              </NavLink>
            </li>

            <li className="nav-item list-inline-item align-middle text-nowrap">
              <NavLink
                className="w-100 p-3"
                to="/home/estimates"
                exact
                activeClassName="selected"
              >
                <FormattedMessage id="Estimates" />
              </NavLink>
            </li>

            <li className="nav-item list-inline-item align-middle text-nowrap">
              <NavLink
                className="w-100 p-3"
                to="/home/budget"
                exact
                activeClassName="selected"
              >
                <FormattedMessage id="Budget" />
              </NavLink>
            </li>

            <li className="nav-item list-inline-item align-middle text-nowrap">
              <NavLink
                className="w-100 p-3"
                to="/home/settings"
                exact
                activeClassName="selected"
              >
                <FormattedMessage id="Settings" />
              </NavLink>
            </li>

            <MenuLogo generalSettings={this.props.generalSettings} />

            {/*
                            
                        <div className="ml-md-auto">
                            <li className="nav-item list-inline-item align-middle text-nowrap mx-auto p-2 col-12">
                              {(this.props.generalSettings.logo) && <img src={APIUrl.getLogo + this.props.generalSettings._id + "/" + Math.random() + "?token=" + APIUrl.jwtToken} className="logo-img" width="70" height="70" alt="logo"/>}
                            </li>
                        </div>
                            */}

            <li className="menuProfile nav-item list-inline-item align-middle text-nowrap">
              <MenuProfile />
            </li>
          </ul>
        </div>
      </nav>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.user,
    lang: state.i18n.lang,
    cart: state.cart,
    orders: state.orders,
    requests: state.requests,
    generalSettings: state.generalSettings,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onLogout: (successCallback) => dispatch(logout(successCallback)),
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(MenuAdmin)
);
