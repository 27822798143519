import React from "react";
import { FormattedMessage } from "react-intl";
import { Button, Modal, Alert } from "react-bootstrap";
import BarCodesPDF from "./BarCodesPDF";
import PDFUtil from "../../../util/PDFUtil";
import PDFViewer from "../../pdf/PDFViewer";
import { connect } from "react-redux";
import Icon from "../../sub/Icon";

const BAR_CODE_INDEX_DEFAULT = 0;
const BAR_CODE_INDEX_MAX = 23;

class EquipmentBarCodeModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      barCodeIndex: BAR_CODE_INDEX_DEFAULT,
      pdfViewer: null,
      disabled: false,
      processing: false
    };
  }

  onChange = (value) => {
    this.setState({ barCodeIndex: value });
  };

  printBarCode() {

    this.setState({
      processing: true,
      disabled: true
    });

    if (
      !this.props.company ||
      !this.props.generalSettings ||
      !this.props.barCode ||
      !this.props.internalRef
    )
      return;

    let id = `bar-code-${this.props.internalRef}`;
    let fileName = `bar-code-${this.props.internalRef}`;
    let template = (
      <BarCodesPDF
        id={id}
        barCodeIndex={this.state.barCodeIndex}
        barCode={this.props.barCode}
      />
    );

    const resetCallback = () => this.setState({
      pdfViewer: null,
      processing: false,
      disabled: false
    });

    const toPDFCallback = () => {
      PDFUtil.toPDF(
        this.props.generalSettings,
        this.props.company,
        id,
        fileName,
        "/css/pdf.css",
        resetCallback
      );
    };

    this.setState(
      {
        pdfViewer: (
          <PDFViewer
            open={false}
            toggle={() => { }}
            template={template}
            fileName={fileName}
          />
        ),
      },
      toPDFCallback
    );
  }

  processingButton() {

  }

  render() {
    let selectionNode = [...Array(BAR_CODE_INDEX_MAX + 1)].map((e, i) => (
      <Button
        key={i}
        variant={this.state.barCodeIndex === i ? "info" : "outline-info"}
        className="m-3 w-25"
        size="lg"
        active={this.state.barCodeIndex === i}
        onClick={() => this.onChange(i)}
      >
        {i + 1}
      </Button>
    ));

    return (
      <Modal
        show={true}
        onHide={() => this.props.close()}
        backdrop={"static"}
        size={"lg"}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <FormattedMessage id="Single.Print" />
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div className="text-center">
            <Alert variant="info"><FormattedMessage id="Choose.Print.Location" /></Alert>
            {selectionNode}
          </div>
        </Modal.Body>

        <Modal.Footer>
          <Button variant="secondary" onClick={() => this.props.close()}>
            <FormattedMessage id="Cancel" />
          </Button>
          <Button
            variant="info"
            disabled={this.state.disabled}
            onClick={() => this.printBarCode()}
          >
            {this.state.processing && <Icon icon="spinner" className="fa-spin mr-1" />}
            <FormattedMessage id="Print" />
          </Button>
        </Modal.Footer>
        <div style={{ position: "absolute", marginLeft: "-10000px" }}>
          {this.state.pdfViewer}
        </div>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    company: state.company,
    generalSettings: state.generalSettings,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    //
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EquipmentBarCodeModal);
