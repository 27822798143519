import { GET_EQUIPMENTS } from "../../actions/equipments/equipments";

export default function equipments(state = [], action) {
  switch (action.type) {
    case GET_EQUIPMENTS:
      return action.equipments;
    default:
      return state;
  }
}
