import { FormattedMessage } from "react-intl";
import { Button, Modal } from "react-bootstrap";
import Icon from "../sub/Icon";
import { FunctionComponent } from "react";

interface MercurialArchiveModalProps {
  closeModal: () => void;
  openModalDelete: () => void;
  updateStatus: () => void;
}

const MercurialArchiveModal: FunctionComponent<MercurialArchiveModalProps> = ({
  closeModal,
  openModalDelete,
  updateStatus,
}) => {
  return (
    <div className="modal-bg show">
      <Modal
        show={true}
        onHide={() => closeModal()}
        backdrop={"static"}
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <FormattedMessage id="Warning" />
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <FormattedMessage id="Mercurial.Remove.Confirmation" />
          <br />
          <br />
          <div className="card text-white bg-danger">
            <div className="card-header">
              <Icon
                icon="triangle-exclamation"
                className="mr-2 text-white"
                aria-label="Warning Icon"
              />
              <FormattedMessage id="Archive" />
            </div>
            <div className="card-body">
              <p className="card-text">
                <FormattedMessage id="Mercurials.Warning.Content" />
              </p>
            </div>
          </div>
        </Modal.Body>

        <Modal.Footer>
          <Button variant="secondary" onClick={() => updateStatus()}>
            <FormattedMessage id="Make.Inactive" />
          </Button>
          <Button variant="danger" onClick={() => openModalDelete()}>
            <FormattedMessage id="Delete" />
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default MercurialArchiveModal;
