import { GET_OLD_PRODUCTS } from "../../actions/products/oldProducts";

export default function oldProducts(state = [], action) {
  switch (action.type) {
    case GET_OLD_PRODUCTS:
      return [...state, ...action.oldProducts];
    default:
      return state;
  }
}
