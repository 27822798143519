import React from "react";
import { connect } from "react-redux";
import { FormattedMessage } from "react-intl";
import ResidentModal from "./ResidentModal";
import Residents from "./Residents";
import { Button, Modal } from "react-bootstrap";
import TableToolbar from "../sub/bootstrap/TableToolbar";

class ResidentsModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      modal: null,
    };
  }

  openResidentModal() {
    this.setState({
      modal: (
        <ResidentModal
          establishmentId={this.props.establishmentId}
          close={() => this.closeModal()}
        />
      ),
    });
  }

  closeModal() {
    this.setState({ modal: null });
  }

  render() {
    var residentsNode = null;

    if (this.props.establishmentId !== undefined) {
      var ResidentInEstablishement = this.props.residents
        .filter(
          (resident) => resident.establishment_id === this.props.establishmentId
        )
        .sort(function (a, b) {
          if (a.name < b.name) {
            return -1;
          }
          if (a.name > b.name) {
            return 1;
          }
          return 0;
        });

      if (ResidentInEstablishement.length === 0) {
        residentsNode = (
          <TableToolbar>
            <div className="alert alert-info w-100">
              <FormattedMessage id="No.Residents" />
            </div>
            <button
              className="btn btn-info ml-auto"
              onClick={(e) => this.openResidentModal()}
            >
              <FormattedMessage id="Resident.Add" />
            </button>
          </TableToolbar>
        );
      } else {
        residentsNode = (
          <Residents
            residents={ResidentInEstablishement}
            establishmentId={this.props.establishmentId}
            openResidentModal={(e) => this.openResidentModal()}
          />
        );
      }
    } else {
      if (this.props.residents.length === 0) {
        residentsNode = (
          <TableToolbar>
            <div className="alert alert-info w-100">
              <FormattedMessage id="No.Residents" />
            </div>
            <button
              className="btn btn-info ml-auto"
              onClick={(e) => this.openResidentModal()}
            >
              <FormattedMessage id="Resident.Add" />
            </button>
          </TableToolbar>
        );
      } else {
        residentsNode = (
          <Residents
            residents={this.props.residents}
            openResidentModal={(e) => this.openResidentModal()}
          />
        );
      }
    }

    return (
      <Modal
        show={true}
        onHide={() => this.props.close()}
        backdrop={"static"}
        size="xl"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <FormattedMessage id="Residents.Management" />
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>{residentsNode}</Modal.Body>

        <Modal.Footer>
          <Button variant="info" onClick={() => this.props.close()}>
            <FormattedMessage id="Close" />
          </Button>
        </Modal.Footer>
        {this.state.modal}
      </Modal>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    residents: state.residents,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    //
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ResidentsModal);
