import {
  GET_MERCURIALS_ADMIN,
  GET_MERCURIAL,
} from "../../actions/mercurials/mercurials";

export default function mercurials(state = [], action) {
  switch (action.type) {
    case GET_MERCURIALS_ADMIN:
      return action.mercurials;
    case GET_MERCURIAL:
      return action.mercurial;
    default:
      return state;
  }
}
