import React from "react";
import { connect } from "react-redux";
import { FormattedMessage, injectIntl } from "react-intl";
import { getOldProducts } from "../../actions/products/oldProducts";
import CustomLabel from "../sub/CustomLabel";
import DateUtil from "../../util/DateUtil";
import Maths from "../../util/Maths";

import { Line } from "react-chartjs-2";
import Util from "../../util/Util";

class OverTimeBudget extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      graphData: "totalHT",
      startDate: new Date(new Date().getFullYear(), 0, 1),
      endDate: Date.now(),
    };
  }

  onChange(field, value) {
    this.setState({ [field]: value });
  }

  currentMonth() {
    var date = new Date();
    this.setState({
      startDate: new Date(date.getFullYear(), date.getMonth(), 1),
      endDate: Date.now(),
    });
  }

  currentYear() {
    this.setState({
      startDate: new Date(new Date().getFullYear(), 0, 1),
      endDate: Date.now(),
    });
  }

  sortMap(map) {
    return Object.keys(map)
      .sort((a, b) => new Date(a).getTime() - new Date(b).getTime())
      .reduce(
        (_sortedObj, key) => ({
          ..._sortedObj,
          [key]: map[key],
        }),
        {}
      );
  }

  groupByMonth(ordersData) {
    var newMap = {};

    for (let id of Object.keys(ordersData)) {
      var toFirstDayOfMonth = new Date(
        new Date(ordersData[id].date).getFullYear(),
        new Date(ordersData[id].date).getMonth(),
        1
      );
      if (!newMap[toFirstDayOfMonth]) newMap[toFirstDayOfMonth] = 0;
      newMap[toFirstDayOfMonth] = Maths.round(
        newMap[toFirstDayOfMonth] + ordersData[id].amount
      );
    }

    return newMap;
  }

  buildChartData(orders) {
    var ordersData = {};
    for (let id of Object.keys(orders)) {
      if (this.state.graphData === "totalHT")
        ordersData[id] = {
          date: orders[id].date,
          amount: orders[id].totalHt,
        };
      else if (this.state.graphData === "totalTTC")
        ordersData[id] = {
          date: orders[id].date,
          amount: orders[id].totalTtc,
        };
    }

    // Group by month...
    ordersData = this.groupByMonth(ordersData);
    // And sort
    ordersData = this.sortMap(ordersData);

    var data = {
      labels: [],
      datasets: [
        {
          label: this.props.intl.formatMessage({ id: "Expenses" }),
          fill: false,
          lineTension: 0,
          data: [],
          backgroundColor: [],
          pointBackgroundColor: [],
          pointBorderColor: [],

          borderColor: "rgba(75,192,192,1)",
          borderWidth: 3,
          borderDashOffset: 0,
          borderJoinStyle: "miter",
          pointBorderWidth: 1,
          pointHoverRadius: 10,
          pointHoverBackgroundColor: "rgba(75,192,192,1)",
          pointHoverBorderColor: "rgba(220,220,220,1)",
          pointHoverBorderWidth: 2,
          pointRadius: 5,
          pointHitRadius: 10,
        },
        {
          label: this.props.intl.formatMessage({ id: "Budget" }),
          fill: false,
          lineTension: 0,
          data: [],
          backgroundColor: [],
          pointBackgroundColor: [],
          pointBorderColor: [],

          borderColor: "red",
          borderWidth: 3,
          borderDashOffset: 0,
          borderJoinStyle: "miter",
          pointBorderWidth: 1,
          pointHoverRadius: 10,
          pointHoverBackgroundColor: "red",
          pointHoverBorderColor: "rgba(220,220,220,1)",
          pointHoverBorderWidth: 2,
          pointRadius: 5,
          pointHitRadius: 10,
        },
      ],
    };

    for (let date of Object.keys(ordersData)) {
      // Label
      data.labels.push(DateUtil.toyyyyMMdd(date));

      // Data
      data.datasets[0].data.push(ordersData[date]);

      if (
        this.props.establishmentSettings &&
        this.props.establishmentSettings.budgets
      ) {
        for (let budget of this.props.establishmentSettings.budgets) {
          if (new Date(date).getFullYear() === budget.year)
            data.datasets[1].data.push(Math.round(budget.budget / 12));
        }
      }
    }

    return data;
  }

  isPositive(number) {
    let isPositive = true;
    if (Math.sign(number) === -1) isPositive = false;
    return isPositive;
  }

  getAnnualBudget() {
    let date = new Date(this.state.startDate);
    let annualBudget = [];
    if (
      this.props.establishmentSettings &&
      this.props.establishmentSettings.budgets &&
      this.state.startDate
    ) {
      for (let budget of this.props.establishmentSettings.budgets) {
        if (date.getFullYear() === budget.year)
          annualBudget.push(budget.budget);
      }
    }
    return annualBudget;
  }

  getTotalBudgetConsumed(orders) {
    let total = 0;
    for (let id of Object.keys(orders)) {
      if (this.state.graphData === "totalHT")
        total += parseInt(orders[id].totalHt);
      else if (this.state.graphData === "totalTTC")
        total += parseInt(orders[id].totalTtc);
    }
    return total;
  }

  getRestBudget(budget, budgetConsumed) {
    return Maths.round(parseInt(budget) - parseInt(budgetConsumed));
  }

  getDailyCost(budget, residents) {
    return Maths.round(parseInt(budget) / parseInt(residents) / 365);
  }

  getDailyCostResident(consumedBudget, residents) {
    return Maths.round(parseInt(consumedBudget) / parseInt(residents) / 365);
  }

  render() {
    if (!this.props.orders || !this.props.establishmentSettings) return null;

    // Gather up all orders from targeted dates
    var orders = {};
    for (let order of this.props.orders) {
      // Careful with start & end dates
      if (
        new Date(order.date) < new Date(this.state.startDate) ||
        new Date(order.date) > new Date(this.state.endDate)
      ) {
        continue;
      }

      orders[order._id] = {
        date: order.date,
        totalHt: order.total_ht + order.shipping_costs + order.urgent_costs,
        totalTtc:
          order.total_ttc +
          order.shipping_costs * 1.2 +
          order.urgent_costs * 1.2,
      };
    }

    var userLang = this.props.lang;

    var options = {
      legend: false,
      scales: {
        xAxes: [
          {
            ticks: {
              // Include a dollar sign in the ticks
              callback: function (value, index, values) {
                var month = new Date(value).toLocaleString(userLang, {
                  month: "long",
                });
                return month.charAt(0).toUpperCase() + month.slice(1);
              },
            },
          },
        ],
        yAxes: [
          {
            ticks: {
              beginAtZero: true,
            },
          },
        ],
      },
    };

    if (
      this.state.graphData === "totalHT" ||
      this.state.graphData === "totalTTC"
    ) {
      options.tooltips = {
        enabled: true,
        mode: "index",
        callbacks: {
          label: function (tooltipItems, data) {
            var value = tooltipItems.yLabel;
            if (!value) value = data.datasets[0].data[tooltipItems.index];

            if (!tooltipItems.label)
              return (
                " " + data.labels[tooltipItems.index] + " : " + value + "€"
              );
            return (
              " " +
              data.datasets[tooltipItems.datasetIndex].label +
              ": " +
              value +
              "€"
            );
          },
        },
      };
    }

    let countResidents = this.props.residents.length;
    let budget = this.getAnnualBudget();
    let consumedBudget = this.getTotalBudgetConsumed(orders);
    let restBudget = this.getRestBudget(budget, consumedBudget);
    let dailyCost = this.getDailyCost(budget, countResidents);
    let dailyCostResident = this.getDailyCostResident(
      consumedBudget,
      countResidents
    );

    return (
      <React.Fragment>
        <div className="row pt-3">
          <div className="col-6 col-lg-6 mb-5">
            <Line
              key={Math.random()}
              options={options}
              data={this.buildChartData(orders)}
            />
          </div>

          <div className="col-3 col-lg-3">
            {!Util.emptyArray(budget) ? (
              <table className="table tablee4coll">
                <tbody>
                  <tr>
                    <td>
                      <FormattedMessage id="Budget.Available" />
                    </td>
                    <td className="text-right">{budget}€</td>
                  </tr>
                  <tr>
                    <td>
                      <FormattedMessage id="Budget.Consumed" />
                    </td>
                    <td className="text-right">{consumedBudget}€</td>
                  </tr>
                  <tr>
                    <td>
                      <FormattedMessage id="Budget.Rest" />
                    </td>
                    <td
                      className={
                        this.isPositive(restBudget)
                          ? "text-right text-success"
                          : "text-right text-danger"
                      }
                    >
                      {restBudget}€
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <FormattedMessage id="Budget.Cost.Daily" />
                    </td>
                    <td className="text-right">{dailyCostResident}€</td>
                  </tr>
                  <tr>
                    <td>
                      <FormattedMessage id="Budget.Cost.Resident" />
                    </td>
                    <td className="text-right">{dailyCost}€</td>
                  </tr>
                </tbody>
              </table>
            ) : (
              <div className={"text-center form-control alert-danger"}>
                <FormattedMessage id={"Budget.Empty"} />
              </div>
            )}
          </div>

          <div className="col-3 col-lg-3">
            <div className="p-4 border border-light rounded bg-light">
              <div className="form-group row pb-0 mb-0">
                <input
                  id="overtime-graphData-totalHT"
                  className="form-control col-2 h-20 mt-10 nofocus"
                  type="radio"
                  name="overtime-budget-graphData"
                  onChange={(e) => this.onChange("graphData", "totalHT")}
                  value="number"
                  checked={this.state.graphData === "totalHT"}
                />
                <CustomLabel
                  label={this.props.intl.formatMessage({
                    id: "Total.Excl.Tax",
                  })}
                  htmlFor="overtime-graphData-totalHT"
                  labelClassName="form-control col-10 no-border bg-light pb-0 mb-0 clickable"
                />
              </div>

              <div className="form-group row pb-0 mb-0">
                <input
                  id="overtime-graphData-totalTTC"
                  className="form-control col-2 h-20 mt-10 nofocus"
                  type="radio"
                  name="overtime-budget-graphData"
                  onChange={(e) => this.onChange("graphData", "totalTTC")}
                  value="number"
                  checked={this.state.graphData === "totalTTC"}
                />
                <CustomLabel
                  label={this.props.intl.formatMessage({
                    id: "Total.Incl.Tax",
                  })}
                  htmlFor="overtime-graphData-totalTTC"
                  labelClassName="form-control col-10 no-border bg-light pb-0 mb-0 clickable"
                />
              </div>

              <hr className="mt-4" />

              <div className="form-group row">
                <CustomLabel
                  label={this.props.intl.formatMessage({ id: "Start.Date" })}
                  htmlFor="overtime-graphData-startDate"
                  labelClassName="form-control col-12 no-border bg-light"
                />
                <input
                  className="form-control col-12"
                  type="date"
                  name="startDate"
                  onChange={(e) => this.onChange("startDate", e.target.value)}
                  value={DateUtil.toyyyyMMdd(this.state.startDate)}
                />
              </div>

              <div className="form-group row">
                <CustomLabel
                  label={this.props.intl.formatMessage({ id: "End.Date" })}
                  htmlFor="overtime-graphData-endDate"
                  labelClassName="form-control col-12 no-border bg-light"
                />
                <input
                  className="form-control col-12"
                  type="date"
                  name="endDate"
                  onChange={(e) => this.onChange("endDate", e.target.value)}
                  value={DateUtil.toyyyyMMdd(this.state.endDate)}
                />
              </div>

              <div className="form-group row">
                <button
                  className="btn btn-m btn-outline-secondary btn-block"
                  onClick={(e) => this.currentMonth()}
                >
                  <FormattedMessage id="Current.Month" />
                </button>
                <button
                  className="btn btn-m btn-outline-secondary btn-block"
                  onClick={(e) => this.currentYear()}
                >
                  <FormattedMessage id="Current.Year" />
                </button>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    lang: state.user.lang,
    products: state.products,
    oldProducts: state.oldProducts,
    mercurials: state.mercurials,
    residents: state.residents,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onGetOldProducts: (data) => dispatch(getOldProducts(data)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(OverTimeBudget));
