import React from "react";
import { connect } from "react-redux";
import { FormattedMessage, injectIntl } from "react-intl";
import { findClient } from "../../../actions/apiMust/apiMust";
import Util from "../../../util/Util";
import CustomLabel from "../../sub/CustomLabel";
import { Button } from "react-bootstrap";
import FindCliModal from "./FindCliModal";
import ModalManager from "../../sub/modals/ModalManager";
import Icon from "../../sub/Icon";
import { findClientLomaco } from "../../../actions/apiLomaco/apiLomaco";
import { CrmProviders } from "../../../enums/crmProviders";
import { updateEstablishment } from "../../../actions/establishments/establishments";

class ApiConfiguration extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      crmId: this.props.establishment
        ? this.props.establishment.crm_id &&
          Util.typeOf(this.props.establishment.crm_id !== "Null")
          ? this.props.establishment.crm_id
          : ""
        : "",
      linkedAccount: this.props.establishment
        ? this.props.establishment.crm_id &&
          Util.typeOf(this.props.establishment.crm_id !== "Null")
          ? true
          : false
        : false,
      loading: false,
      modal: null,
    };
  }

  onFieldChange(field, value) {
    this.setState({ [field]: value });
  }

  findClient(crmSoftware) {
    this.setState({ loading: true });
    var data = {
      crmId: this.state.crmId,
    };

    // Send to BE
    if (this.props.company.crm.enabled && this.props.company.crm.software === CrmProviders.MUST.software) {
      this.props.onFindClient(data, (result) =>
        this.openModal(crmSoftware, result)
        ,(result) =>
          this.openModal(crmSoftware, result, data));
    } else if (this.props.company.crm.enabled && this.props.company.crm.software === CrmProviders.LOMACO.software) {
      this.props.onLomacoClient(data, (result) =>
        this.openModal(crmSoftware, result), (result) =>
        this.openModal(crmSoftware, result.data, data));
    }

  }

  openModal(crmSoftware, data, dataCodeSearch) {
    if (data.code !== 512) {
      this.setState({
        loading: false,
        modal: (
          <FindCliModal
            apiData={data}
            closeModal={() => this.closeModal()}
            establishment={this.props.establishment}
            crmId={this.state.crmId}
            linkedAccount={this.state.linkedAccount}
            onComplete={(crmId) => this.onComplete(crmId)}
          />
        ),
      });
    } else {
      if(data.data){
        if(data.errorMessage === "No client found"){
            this.props.onUpdateEstablishment({
                establishmentId: this.props.establishment._id,
                updatedField: 'crm_id',
                updatedValue: ""
            }, ()=>{});
        }
        this.setState({
            loading: false,
            modal: (
                <ModalManager
                    closeModal={() => this.closeModal()}
                    content={<>
                      <FormattedMessage
                        id="API.CRM.Client.Find.Error"
                        values={{
                          crmSoftware: crmSoftware,
                          dataCodeSearch:dataCodeSearch.crmId
                        }}
                      />
                      <br />
                      <br />
                      <label><FormattedMessage id="Support.Error.code" /></label>
                      <br />
                      <code>{data ? JSON.stringify(data) : JSON.stringify(data.toString())}</code>
                    </>
                    }
                    modalType={"error"}
                    showModal="true"
                    title={<FormattedMessage id="API.CRM.Client.Find.Error.Title" />}
                />
            )
        });
      }else{
        this.setState({
            loading: false,
            modal: (
              <ModalManager
                modalType={"error"}
                showModal="true"
                closeModal={() => this.closeModal()}
                content={
                  <FormattedMessage id="API.CRM.Client.Find.Error" values={{ dataCodeSearch: dataCodeSearch.crmId, crmSoftware: crmSoftware }} />
                }
                title={<FormattedMessage id="API.CRM.Client.Find.Error.Title" />}
              />
            )
        });
    }

    }
  }

  closeModal() {
    this.setState({
      modal: null,
    });
  }

  onComplete(crmId) {
    this.setState({
      crmId: crmId,
      linkedAccount: crmId !== "",
    });

    return this.closeModal();
  }

  definedClientCode() {
    return this.state.crmId && Util.typeOf(this.state.crmId === "Number");
  }

  render() {
    // https://stackoverflow.com/questions/37308719/react-component-wait-for-required-props-to-render
    // https://zaiste.net/posts/javascript-destructuring-assignment-default-values/
    const { software: crmSoftware = null } = this.props.company.crm
      ? this.props.company.crm
      : {};

    return (
      <React.Fragment>
        <div className="mb-3">
          {this.state.linkedAccount ? (
            <div className="alert alert-success">
              <Icon icon="circle-check" className="mr-2" />
              <FormattedMessage
                id="API.CRM.Client.Linked"
                values={{ crmSoftware: crmSoftware }}
              />
            </div>
          ) : (
            <div className="alert alert-info">
              <Icon icon="info-circle" className="mr-2" />
              <FormattedMessage
                id="API.CRM.Client.Link.Info"
                values={{ crmSoftware: crmSoftware }}
              />
            </div>
          )}

          <div className="col-12 col-lg-12 col-xl-9">
            <div className="form-group row">
              <CustomLabel
                label={this.props.intl.formatMessage({ id: "Client.Code" })}
                htmlFor="crmId"
                labelClassName="col-3"
              />
              <div className="form-inline col-9">
                <input
                  disabled={this.state.linkedAccount}
                  type="text"
                  className="form-control w-50"
                  id="crmId"
                  value={this.state.crmId}
                  maxLength={(crmSoftware === CrmProviders.MUST.software) ? 10 : 32}
                  onChange={(e) => this.onFieldChange("crmId", e.target.value)}
                // onBlur={e => this.sendUpdate("crmId", e.target.value)}
                />
                <Button
                  id="checkClient"
                  className="btn btn-info ml-2"
                  disabled={!this.definedClientCode() || this.state.loading}
                  onClick={() => this.findClient(crmSoftware)}
                >
                  <Icon
                    icon={this.state.loading
                      ? "spinner"
                      : "magnifying-glass"}
                    className={
                      this.state.loading
                        ? "fa-spin mr-2"
                        : "mr-2"
                    }
                  />
                  {this.state.loading ? (
                    <FormattedMessage id="Plz.Wait" />
                  ) : (
                    <FormattedMessage id="Verify" />
                  )}
                </Button>
              </div>
            </div>
          </div>
        </div>

        {this.state.modal}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    company: state.company,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onFindClient: (data, successCallback, failureCallback) =>
      dispatch(findClient(data, successCallback, failureCallback)),
    onLomacoClient: (data, successCallback,failureCallback) =>
      dispatch(findClientLomaco(data, successCallback,failureCallback)),
    onUpdateEstablishment: (data, successCallback) => dispatch(updateEstablishment(data, successCallback)),

  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(ApiConfiguration));
