import Maths from "./Maths";

export default class PriceUtil {
  constructor() {
    this.store = []; // {product, quantity}
  }

  // =====================================================
  // ================ IN / OUT OPERATIONS ================
  // =====================================================

  add(product, quantity) {
    this.store.push({ product: product, quantity: quantity });
  }

  reset() {
    this.store = [];
  }

  // =====================================================
  // ============ SINGLE PRODUCT OPERATIONS ==============
  // =====================================================

  static priceHt(product, quantity = 1, decimals = 4) {
    return Maths.round(
      Maths.round(product.prix_u_ht_emera, 4) * quantity,
      decimals
    );
  }

  static priceTtc(product, quantity = 1, decimals = 4) {
    return Maths.round(
      Maths.round(this.priceHt(product) * (1 + this.tva(product)), 4) *
        quantity,
      decimals
    );
  }

  static tva(product, quantity = 1, decimals = 4) {
    return Maths.round(
      Maths.round(parseFloat(product.tva), 4) * quantity,
      decimals
    );
  }

  // =====================================================
  // ================== TOTAL OPERATIONS =================
  // =====================================================

  quantity() {
    var total = 0;

    for (let s of this.store) {
      total += s.quantity;
    }

    return total;
  }

  totalHt() {
    var totalHt = 0;

    for (let s of this.store) {
      let currentHt = PriceUtil.priceHt(s.product) * s.quantity;
      totalHt = totalHt + currentHt;
    }

    return Maths.round(totalHt);
  }

  totalTtc() {
    var totalTtc = 0;

    for (let s of this.store) {
      let currentTtc = PriceUtil.priceTtc(s.product) * s.quantity;
      totalTtc = totalTtc + currentTtc;
    }

    return Maths.round(totalTtc);
  }

  totalTva() {
    var totalTva = 0;

    for (let s of this.store) {
      let currentTva = Maths.round(
        PriceUtil.priceHt(s.product) * PriceUtil.tva(s.product) * s.quantity,
        4
      );
      totalTva = totalTva + currentTva;
    }

    return Maths.round(totalTva);
  }
}
