export default class ArrayUtil {
  // Get the difference between two arrays
  // https://stackoverflow.com/questions/1187518/how-to-get-the-difference-between-two-arrays-in-javascript

  /**
   * intersection
   * For [1,2,3] [2,3] it will yield [2,3]. On the other hand, for [1,2,3] [2,3,5] will return the same thing.
   * @param {*} arr1
   * @param {*} arr2
   */
  static intersection(arr1, arr2) {
    return arr1.filter((x) => arr2.includes(x));
  }

  /**
   * difference
   * For [1,2,3] [2,3] it will yield [1]. On the other hand, for [1,2,3] [2,3,5] will return the same thing.
   * @param {*} arr1
   * @param {*} arr2
   */
  static difference(arr1, arr2) {
    return arr1.filter((x) => !arr2.includes(x));
  }

  static ascendingSort(a, b) {
    if (a < b) return -1;
    if (a > b) return 1;
    return 0;
  }

  static descendingSort(a, b) {
    if (a < b) return 1;
    if (a > b) return -1;
    return 0;
  }
}
