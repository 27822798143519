import React from "react";
import { nanoid } from "nanoid";
import { Dropdown, Badge } from "react-bootstrap";
import Icon from "./Icon";

class ActionMenu extends React.Component {

  setVariant() {
    return this.props.processing ? "danger" : this.props.variant ? this.props.variant : this.props.disabled ? "outline-info" : "info";
  }

  setIcon() {
    return this.props.processing ? "cog" : this.props.icon ? this.props.icon : "circle-info";
  }

  setLabel() {
    return this.props.label ? this.props.label : "";
  }

  setAdditionalClasses() {
    return this.props.className || "";
  }

  setDisabled() {
    // If processing is true (waiting for a process like an ajax request to complete)
    return this.props.processing ? true : this.props.disabled ? this.props.disabled : false;
  }

  // https://react-bootstrap.netlify.app/docs/components/dropdowns/#menu-headers
  setHeader() {
    return this.props.header || false;
  }

  setProcessing() {
    return this.props.processing ? "fa-fw fa-spin" : "fa-fw";
  }

  setSize() {
    return this.props.size || "";
  }

  render() {
    const items = this.props.items.filter(
      (value) => Object.keys(value).length !== 0
    );

    const nuberOfItems = items.length;

    let menuLink = items.map((item, i) => {
      var id = item.id ? item.id : "id" + nanoid();

      // https://stackoverflow.com/questions/18535684/how-to-give-font-awesomes-icon-a-fixed-width

      return (
        <div key={id}>
          <Dropdown.Item
            id={id}
            disabled={item.disabled}
            onClick={!item.disabled && item.action}
            href={item.href || false}
          >
            <Icon className={"mr-2"} icon={item.icon} />
            {item.text} {item.notification && <Badge variant="danger">{item.notification}</Badge>}
          </Dropdown.Item>
          {(nuberOfItems !== i + 1) && <Dropdown.Divider />}
        </div>
      );
    });

    return (
      <Dropdown className={this.setAdditionalClasses()}>
        <Dropdown.Toggle variant={this.setVariant()} disabled={this.setDisabled()} size={this.setSize()}>
          <Icon icon={this.setIcon()} className={this.setProcessing()} /> {this.setLabel()}
        </Dropdown.Toggle>

        <Dropdown.Menu>
          {this.setHeader() && <div><Dropdown.Header>
            {this.setHeader()}
          </Dropdown.Header>
            <Dropdown.Divider />
          </div>}
          {menuLink}
        </Dropdown.Menu>
      </Dropdown>
    );
  }
}

export default ActionMenu;
