import React from "react";
import { connect } from "react-redux";
import { FormattedMessage } from "react-intl";
import PDFUtil from "../../util/PDFUtil";

class PDFViewer extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      open: true,
    };
  }

  close(e) {
    e.preventDefault();
    e.stopPropagation();

    this.setState({ open: false });
  }

  render() {
    // The viewer is not really 'closed', because for PDF export the element needs to be in the HTML.
    // We just hide it very far away.
    if (!this.props.open) {
      return (
        <div style={{ position: "absolute", marginLeft: "-10000px" }}>
          {this.props.template}
        </div>
      );
    }

    return (
      <div className="pdf-overview-bg" onClick={(e) => this.props.toggle(e)}>
        <button
          className={"pdf-overview-export-button pdf-overview-blue-btn"}
          onClick={(e) =>
            PDFUtil.toPDF(
              this.props.generalSettings,
              this.props.company,
              this.props.template.props.id,
              this.props.fileName,
              "/css/pdf.css"
            )
          }
        >
          <FormattedMessage id="Export.PDF" />
        </button>

        <div className="pdf-overview">{this.props.template}</div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    lang: state.i18n.lang,
    user: state.user,
    generalSettings: state.generalSettings,
    company: state.company,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    //
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PDFViewer);
