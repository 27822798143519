import React from "react";
import { connect } from "react-redux";
import { FormattedMessage } from "react-intl";
import FleetSummary from "./FleetSummary";
import { Modal, Button } from "react-bootstrap";

class FleetSummaryModal extends React.Component {
  close() {
    this.props.close();
  }

  stopEvent(e) {
    e.stopPropagation();
  }

  render() {
    return (
      <Modal
        show={true}
        onHide={() => this.close()}
        size={"lg"}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <FormattedMessage id="Park.Summary" />
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <FleetSummary equipments={this.props.equipments} />
        </Modal.Body>

        <Modal.Footer>
          <Button variant="secondary" onClick={() => this.close()}>
            <FormattedMessage id="Close" />
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.user,
    materialsTypes: state.materialsTypes,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    //
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(FleetSummaryModal);
