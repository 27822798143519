import React from "react";
import { connect } from "react-redux";
import { FormattedMessage, injectIntl } from "react-intl";
import DateUtil from "../../util/DateUtil";
import EqAffectation from "../../enums/EqAffectation";
import EqOwner from "../../enums/EqOwner";
import EqState from "../../enums/EqState";
import {
  addEquipment,
  checkIfEquipmentExists,
  updateEquipment,
} from "../../actions/equipments/equipments";
import ComboBox from "../sub/ComboBox";
import Select from "react-select";
import { addBrand } from "../../actions/configurables/brands";
import { addModel } from "../../actions/configurables/models";
import APIUrl from "../../APIUrl";
import FileUtil from "../../util/FileUtil";
import CustomLabel from "../sub/CustomLabel";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css"; // This only needs to be imported once in your app
import { Modal } from "react-bootstrap";
import ArrayUtil from "../../util/ArrayUtil";

class EquipmentModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      material_type_id: "",
      internal_ref: "",
      brand_id: "",
      model_id: "",
      serial_nbr: "",
      purchase_date: "",
      warranty: false,
      affectation: "",
      resident_id: "",
      floor: "",
      room: "",
      owner: "",
      state: "",
      materiovigilance: "",
      misc: "",
      selectedResidentOption: null,

      materialTypeIdError: null,
      internalRefError: null,
      brandIdError: null,
      modelIdError: null,

      disabled: false,

      // Lightbox vars
      photoIndex: 0,
      lightboxIsOpen: false,
    };

    // Update existing equipment
    if (this.props.equipment) {
      const resident = this.props.residents.find((r) => r._id === this.props.equipment.resident_id);

      this.state = {
        material_type_id: this.props.equipment.material_type_id,
        internal_ref: this.props.equipment.internal_ref,
        brand_id: this.props.equipment.brand_id,
        model_id: this.props.equipment.model_id,
        serial_nbr: this.props.equipment.serial_nbr || "",
        purchase_date: DateUtil.toyyyyMMdd(this.props.equipment.purchase_date),
        warranty: this.boolValue(this.props.equipment.warranty),
        affectation: this.enumValue(this.props.equipment.affectation),
        resident_id: this.props.equipment.resident_id || "",
        floor: this.props.equipment.floor || "",
        room: this.props.equipment.room || "",
        owner: this.enumValue(this.props.equipment.owner),
        state: this.enumValue(this.props.equipment.state),
        materiovigilance: this.enumValue(this.props.equipment.materiovigilance),
        misc: this.props.equipment.misc || "",
        selectedResidentOption: resident ? {value: resident._id, label: `${resident.name} ${resident.first_name}`} : null,

        disabled: false,

        // Lightbox vars
        photoIndex: 0,
        lightboxIsOpen: false,
      };
    }
  }

  numValue(val) {
    if (val !== undefined && val !== null) return val;
    return 0;
  }

  enumValue(val) {
    if (val !== undefined && val !== null) return val;
    return "";
  }

  boolValue(val) {
    if (val !== undefined && val !== null) return val;
    return false;
  }

  getModel(modelId) {
    for (let model of this.props.models) {
      if (model._id === modelId) return model;
    }
  }

  onChange(field, value) {
    // If affectation is not resident, remove potential resident
    if (field === "affectation" && parseInt(value) !== EqAffectation.RESIDENT)
      this.setState({ resident_id: "", selectedResidentOption: null });

    return this.setState({ [field]: value });
  }

  update(field, value) {
    if (!this.props.equipment) return;

    if (field === "affectation") {
      return this._update(field, value, () => this._update("resident_id", ""));
    }

    this._update(field, value);
  }

  _update(field, value, successCallback) {
    var data = {
      establishmentId: this.props.establishmentId,
      equipmentId: this.props.equipment._id,
      updatedField: field,
      updatedValue: value,
    };

    this.props.onUpdateEquipment(data, successCallback);
  }

  getModels(brandId, models) {
    let results = [];

    for (let model of models) {
      if (model.brand_id === brandId) results.push(model);
    }

    return results;
  }

  getResidentsSelectOptions() {
    const options = [];
    const residents = this.props.residents.filter((r) => r.establishment_id === this.props.establishmentId).sort((a, b) => ArrayUtil.ascendingSort(a.name, b.name));
    for (const r of residents) {
      options.push({value: r._id, label: `${r.name} ${r.first_name}`})
    }
    return options;
  }

  render() {
    var materialsTypesNode = this.props.materialsTypes.map(
      (material_type_id) => {
        return (
          <option key={material_type_id._id} value={material_type_id._id}>
            {material_type_id.name}
          </option>
        );
      }
    );

    var eqAffectationNode = Object.values(EqAffectation).map((option) => {
      return (
        <option key={option} value={option}>
          {this.props.intl.formatMessage({ id: "EqAffectation." + option })}
        </option>
      );
    });

    var eqOwnerNode = Object.values(EqOwner).map((option) => {
      return (
        <option key={option} value={option}>
          {this.props.intl.formatMessage({ id: "EqOwner." + option })}
        </option>
      );
    });

    var eqStateNode = Object.values(EqState).map((option) => {
      return (
        <option key={option} value={option}>
          {this.props.intl.formatMessage({ id: "EqState." + option })}
        </option>
      );
    });

    var floorNode = () => {
      let floorOptions = [];

      for (let i = 0; i < 21; i++) {
        if (i === 0) {
          floorOptions.push(
            <option key={"floorNull"} value="">
              {" "}
            </option>
          );
          floorOptions.push(
            <option
              key={"floor" + 0}
              value={this.props.intl.formatMessage({ id: "Ground.Floor.Abbr" })}
            >
              {this.props.intl.formatMessage({ id: "Ground.Floor.Abbr" })}
            </option>
          );
        } else {
          floorOptions.push(
            <option key={"floor" + i} value={i}>
              {i}
            </option>
          );
        }
      }

      return floorOptions;
    };

    let title = (
      <FormattedMessage
        id={this.props.equipment ? "Modify.Equipment" : "Add.Equipment"}
      />
    );

    var images = [];
    var docs = [];
    if (this.props.equipment && this.props.equipment.files) {
      for (let file of this.props.equipment.files) {
        var extension = FileUtil.extension(file);
        if (extension === "jpg" || extension === "jpeg" || extension === "png")
          images.push(file);
        else docs.push(file);
      }
    }

    // Lightbox
    const { photoIndex, lightboxIsOpen } = this.state;

    const lightboxImages = images.map((file) => {
      return (
        APIUrl.getEquipmentFile +
        this.props.equipment._id +
        "/" +
        file +
        "?token=" +
        APIUrl.jwtToken
      );
    });

    return (
      <Modal
        show={true}
        onHide={() => this.props.close()}
        backdrop={"static"}
        size="xl"
      >
        <Modal.Header closeButton>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          {images && images.length > 0 && (
            <div
              className="light-card p-3 mb-3 rounded"
              style={{ overflowX: "scroll", whiteSpace: "nowrap" }}
            >
              <div className="" style={{ height: "150px" }}>
                {images &&
                  images.length > 0 &&
                  images.map((file) => (
                    <img
                      onClick={() => this.setState({ lightboxIsOpen: true })}
                      src={
                        APIUrl.getEquipmentFile +
                        this.props.equipment._id +
                        "/" +
                        file +
                        "?token=" +
                        APIUrl.jwtToken
                      }
                      key={Math.random()}
                      height="150"
                      width="150"
                      alt=""
                      style={{
                        marginLeft: "10px",
                        marginRight: "10px",
                        marginBottom: "25px",
                        display: "inline-block",
                      }}
                      className="imageLightBox"
                    />
                  ))}
              </div>

              {/* Lightbox plugin begins here */}
              <div>
                {lightboxIsOpen && (
                  <Lightbox
                    reactModalStyle={{
                      overlay: {
                        zIndex: 1080,
                        backgroundColor: "transparent",
                      },
                    }}
                    mainSrc={lightboxImages[photoIndex]}
                    nextSrc={
                      lightboxImages[(photoIndex + 1) % lightboxImages.length]
                    }
                    prevSrc={
                      lightboxImages[
                      (photoIndex + lightboxImages.length - 1) %
                      lightboxImages.length
                      ]
                    }
                    onCloseRequest={() =>
                      this.setState({ lightboxIsOpen: false })
                    }
                    onMovePrevRequest={() =>
                      this.setState({
                        photoIndex:
                          (photoIndex + lightboxImages.length - 1) %
                          lightboxImages.length,
                      })
                    }
                    onMoveNextRequest={() =>
                      this.setState({
                        photoIndex: (photoIndex + 1) % lightboxImages.length,
                      })
                    }
                  />
                )}
              </div>
              {/* Lightbox plugin ends here */}
            </div>
          )}

          <div className="row">
            <div className="col-12 col-lg-6">
              <div className="form-group row">
                <CustomLabel
                  label={this.props.intl.formatMessage({ id: "Type" })}
                  htmlFor="equipment-type"
                  labelClassName="col-sm-5"
                />
                <div id="equipment-type" className="col-sm-7 pb-3">
                  <select
                    id="equipment-type"
                    className="form-control"
                    disabled={true}
                    defaultValue={this.state.material_type_id}
                  >
                    <option value="">
                      {this.props.intl.formatMessage({ id: "Select" })}...
                    </option>
                    {materialsTypesNode}
                  </select>
                  <div className="text-danger">
                    <small>{this.state.materialTypeIdError}</small>
                  </div>
                </div>
                <CustomLabel
                  label={this.props.intl.formatMessage({ id: "Intern.Ref" })}
                  htmlFor="equipment-int-ref"
                  labelClassName="col-sm-5"
                />
                <div id="equipment-int-ref" className="col-sm-7 pb-3">
                  <input
                    type="text"
                    className="form-control"
                    id="equipment-int-ref"
                    autoComplete="off"
                    value={this.state.internal_ref}
                    disabled={true}
                  />
                  <div className="text-danger">
                    <small>{this.state.internalRefError}</small>
                  </div>
                </div>
                <CustomLabel
                  label={this.props.intl.formatMessage({ id: "Brand" })}
                  htmlFor="equipment-brand"
                  labelClassName="col-sm-5"
                />
                <div id="equipment-brand" className="col-sm-7 pb-3">
                  <ComboBox
                    disabled={true}
                    defaultOption={this.state.brand_id}
                    options={this.props.brands}
                    onCreateOption={(data, successCallback) => { }}
                  />
                  <div className="text-danger">
                    <small>{this.state.brandIdError}</small>
                  </div>
                </div>
                <CustomLabel
                  label={this.props.intl.formatMessage({ id: "Model" })}
                  htmlFor="equipment-model"
                  labelClassName="col-sm-5"
                />
                <div id="equipment-model" className="col-sm-7 pb-3">
                  <ComboBox
                    disabled={true}
                    defaultOption={this.state.model_id}
                    options={this.getModels(
                      this.state.brand_id,
                      this.props.models
                    )}
                    onCreateOption={(data, successCallback) => { }}
                  />
                  <div className="text-danger">
                    <small>{this.state.modelIdError}</small>
                  </div>
                </div>
                <CustomLabel
                  label={this.props.intl.formatMessage({
                    id: "Serial.Number",
                  })}
                  htmlFor="equipment-serial-number"
                  labelClassName="col-sm-5"
                />
                <div id="equipment-serial-number" className="col-sm-7 pb-3">
                  <input
                    type="number"
                    className="form-control"
                    id="equipment-serial-number"
                    autoComplete="off"
                    disabled={true}
                    value={this.state.serial_nbr}
                  />
                </div>
                <CustomLabel
                  label={this.props.intl.formatMessage({
                    id: "Purchase.Date",
                  })}
                  htmlFor="equipment-purchase-date"
                  labelClassName="col-sm-5"
                />
                <div
                  id="equipment-series-purchase-date"
                  className="col-sm-7 pb-3 mb-2"
                >
                  <input
                    type="date"
                    className="form-control"
                    id="equipment-series-purchase-date"
                    autoComplete="off"
                    disabled={true}
                    value={this.state.purchase_date}
                  />
                </div>
                <CustomLabel
                  label={this.props.intl.formatMessage({ id: "Warranty" })}
                  htmlFor="equipment-model"
                  labelClassName="col-sm-5 custom-control-label no-after no-before"
                />
                <div className="col-sm-7">
                  <div className="custom-switch switch-primary">
                    <input
                      type="checkbox"
                      id="equipment-warranty"
                      className="custom-control-input switch-bg-green"
                      disabled={true}
                      checked={this.state.warranty}
                    />
                    <CustomLabel
                      htmlFor="equipment-warranty"
                      labelClassName="custom-control-label"
                    />
                  </div>
                </div>

                <div className="col-12 mt-4" />
              </div>
            </div>

            <div className="col-12 col-lg-6">
              <div className="form-group row">
                <CustomLabel
                  label={this.props.intl.formatMessage({ id: "Affectation" })}
                  htmlFor="equipment-affectation"
                  className="col-sm-5"
                />
                <div id="equipment-affectation" className="col-sm-7 pb-3">
                  <select
                    id="equipment-affectation"
                    className="form-control"
                    onChange={(e) =>
                      this.onChange("affectation", e.target.value)
                    }
                    onBlur={(e) => this.update("affectation", e.target.value)}
                    defaultValue={this.state.affectation}
                  >
                    <option value="">
                      {this.props.intl.formatMessage({ id: "Select" })}...
                    </option>
                    {eqAffectationNode}
                  </select>
                </div>
                <CustomLabel
                  label={this.props.intl.formatMessage({ id: "Resident" })}
                  htmlFor="equipment-resident"
                  className="col-sm-5"
                />
                <div id="equipment-resident" className="col-sm-7 pb-3">
                  <Select
                    placeholder={<FormattedMessage id="Select.Resident" />}
                    noOptionsMessage={() => (
                      <FormattedMessage id={"No.Result"} />
                    )}
                    onChange={(option) => {
                      this.setState({
                        selectedResidentOption: option,
                        resident_id: option.value,
                      });
                        this.update("resident_id", option.value)
                    }}
                    value={this.state.selectedResidentOption}
                    isDisabled={
                      parseInt(this.state.affectation) !==
                      EqAffectation.RESIDENT
                    }
                    options={this.getResidentsSelectOptions()}
                  />
                </div>
                <CustomLabel
                  label={this.props.intl.formatMessage({ id: "Floor" })}
                  htmlFor="equipment-floor"
                  className="col-sm-5"
                />
                <div id="equipment-floor" className="col-sm-7 pb-3">
                  <select
                    className="form-control"
                    id="equipment-floor"
                    autoComplete="off"
                    onChange={(e) => this.onChange("floor", e.target.value)}
                    onBlur={(e) => this.update("floor", e.target.value)}
                    value={this.state.floor}
                  >
                    {floorNode()}
                  </select>
                </div>

                <CustomLabel
                  label={this.props.intl.formatMessage({ id: "Room" })}
                  htmlFor="equipment-room"
                  className="col-sm-5"
                />
                <div id="equipment-room" className="col-sm-7 pb-3">
                  <input
                    type="text"
                    className="form-control"
                    id="equipment-room"
                    autoComplete="off"
                    onChange={(e) => this.onChange("room", e.target.value)}
                    onBlur={(e) => this.update("room", e.target.value)}
                    value={this.state.room}
                  />
                </div>

                <CustomLabel
                  label={this.props.intl.formatMessage({ id: "Owner" })}
                  htmlFor="equipment-owner"
                  className="col-sm-5"
                />
                <div id="equipment-owner" className="col-sm-7 pb-3">
                  <select
                    id="equipment-owner"
                    className="form-control"
                    disabled={true}
                    defaultValue={this.state.owner}
                  >
                    <option value="">
                      {this.props.intl.formatMessage({ id: "Select" })}...
                    </option>
                    {eqOwnerNode}
                  </select>
                </div>

                <CustomLabel
                  label={this.props.intl.formatMessage({ id: "State" })}
                  htmlFor="equipment-state"
                  className="col-sm-5"
                />
                <div id="equipment-state" className="col-sm-7 pb-3">
                  <select
                    id="equipment-state"
                    className="form-control"
                    disabled={true}
                    defaultValue={this.state.state}
                  >
                    <option value="">
                      {this.props.intl.formatMessage({ id: "Select" })}...
                    </option>
                    {eqStateNode}
                  </select>
                </div>

                <CustomLabel
                  label={this.props.intl.formatMessage({
                    id: "Materiovigilance",
                  })}
                  htmlFor="equipment-materiovigilance"
                  className="col-sm-5"
                />
                <div
                  id="equipment-materiovigilance"
                  className="col-sm-7 pb-3"
                >
                  {/* <div
                      id="equipment-materiovigilance"
                      className="col-sm-7 pb-2"
                    > */}
                  {this.props.materiovigilance}
                  {/* </div> */}
                </div>

                <CustomLabel
                  label={this.props.intl.formatMessage({ id: "Misc" })}
                  htmlFor="equipment-misc"
                  className="col-sm-5"
                />
                <div id="equipment-misc" className="col-sm-7 pb-3">
                  <input
                    type="text"
                    className="form-control"
                    id="equipment-misc"
                    autoComplete="off"
                    disabled={true}
                    value={this.state.misc}
                  />
                </div>

                <CustomLabel
                  label={this.props.intl.formatMessage({
                    id: "Attached.Documents",
                  })}
                  htmlFor="equipment-docs"
                  className="col-sm-5"
                />
                <div className="col-sm-7 pb-3">
                  <div className="pt-1">
                    {docs &&
                      docs.length > 0 &&
                      docs.map((file) => (
                        <div key={file}>
                          <a
                            role="button"
                            href={
                              APIUrl.getEquipmentFile +
                              this.props.equipment._id +
                              "/" +
                              file +
                              "?token=" +
                              APIUrl.jwtToken
                            }
                            rel="noopener noreferrer"
                            download={file}
                            target="_blank"
                          >
                            {file}
                          </a>
                        </div>
                      ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    materialsTypes: state.materialsTypes,
    residents: state.residents,
    brands: state.brands,
    models: state.models,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onAddEquipment: (establishmentId, equipment, successCallback) =>
      dispatch(addEquipment(establishmentId, equipment, successCallback)),
    onCheckIfEquipmentExists: (data, existsCallback, noExistsCallback) =>
      dispatch(checkIfEquipmentExists(data, existsCallback, noExistsCallback)),
    onUpdateEquipment: (data, successCallback) =>
      dispatch(updateEquipment(data, successCallback)),
    onAddBrand: (data, successCallback) =>
      dispatch(addBrand(data, successCallback)),
    onAddModel: (data, successCallback) =>
      dispatch(addModel(data, successCallback)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(EquipmentModal));
