import React from "react";
import { FormattedMessage } from "react-intl";
import OrderProductRow from "./OrderProductRow";
import Paginator from "../sub/Paginator";
import { nanoid } from "nanoid";

class OrderProducts extends React.Component {
  constructor(props) {
    super(props);

    this.paginator = new Paginator(this);
  }

  render() {
    const { order, paginate } = this.props;
    if (order.products.length === 0) return null;
    if (paginate) this.paginator.init(order.products.length);

    let productNode = order.products.map((product) => {
      if (paginate && !this.paginator.keep()) return null;
      return (
        <OrderProductRow key={"product-" + nanoid()} product={product} />
      );
    });

    let commentNode = null;
    if (order.comment && order.comment !== "") {
      commentNode = (
        <tr>
          <td colSpan="5">
            <div className={"alert alert-info mb-0"} role="alert">
              <b>
                <FormattedMessage id="Comment" />
              </b>{" "}
              : {order.comment}
            </div>
          </td>
        </tr>
      );
    }

    return (
      <React.Fragment>
        <table className="table table-striped tablee4coll m-0">
          <thead>
            <tr>
              <th scope="col">
                <FormattedMessage id="Reference" />
              </th>
              <th scope="col">
                <FormattedMessage id="Designation" />
              </th>
              <th scope="col">
                <FormattedMessage id="Quantity" />
              </th>
              <th scope="col">
                <FormattedMessage id="Unit.Price.Excl.Tax" />
              </th>
              <th scope="col">
                <FormattedMessage id="Total.Excl.Tax" />
              </th>
            </tr>
          </thead>
          <tbody>
            {productNode}
            {this.paginator.render() &&
              <tr>
                <td className="pt-2 pb-0 mb-0" colSpan="5">
                  {this.paginator.render()}
                </td>
              </tr>
            }
            {commentNode}
          </tbody>
        </table>
      </React.Fragment>
    );
  }
}

export default OrderProducts;
