import { GET_URL } from "../../actions/authentication/authentication";

export default function url(state = "", action) {
  switch (action.type) {
    case GET_URL:
      return action.url;
    default:
      return state;
  }
}
