import React from "react";
import { connect } from "react-redux";
import { FormattedMessage } from "react-intl";
import { Doughnut } from "react-chartjs-2";
import Util from "../../util/Util";
import FleetSummaryModal from "./FleetSummaryModal";
import EqOwner from "../../enums/EqOwner";
import { Badge } from "react-bootstrap";
class FleetSummary extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      modal: null,
      materialsTypes: this.props.materialsTypes,
      equipments: this.props.equipments,
      user: this.props.user,
    };
  }

  countEquipments(materialTypeId, equipments) {
    var nbr = 0;

    for (let equipment of equipments) {
      if (equipment.material_type_id === materialTypeId) nbr++;
    }

    return nbr;
  }

  countOwnerEquipments(materialTypeId, equipments, owner) {
    let nbr = 0;

    for (let equipment of equipments) {
      if (
        equipment.material_type_id === materialTypeId &&
        equipment.owner === owner
      )
        nbr++;
    }

    return nbr;
  }

  buildChartData(materialsTypes, equipments, isLine = false) {
    var data = {
      labels: [],
      datasets: [
        {
          data: [],
          backgroundColor: [],
          pointBackgroundColor: [],
          pointBorderColor: [],
          borderColor: [],
          borderWidth: [],
        },
      ],
    };
    for (let materialsType of materialsTypes) {
      data.labels.push(materialsType.name);
      // Data
      data.datasets[0].data.push(
        this.countEquipments(materialsType._id, equipments)
      );

      // BG color
      var color = Util.stringToColor(materialsType.name);
      if (!isLine) data.datasets[0].backgroundColor.push(color);
      else {
        data.datasets[0].pointBackgroundColor.push(color);
        data.datasets[0].pointBorderColor.push(color);
        data.datasets[0].backgroundColor.push("rgba(0, 0, 0, 0)");
        data.datasets[0].borderColor.push(color);
        data.datasets[0].hoverBorderColor.push(color);
        data.datasets[0].hoverBorderWidth.push(8);
        data.datasets[0].borderWidth.push(5);
      }
    }
    return data;
  }
  closeModal() {
    this.setState({ modal: null });
  }

  openSummaryModal() {
    this.setState({
      modal: (
        <FleetSummaryModal
          close={() => this.closeModal()}
          equipments={this.state.equipments}
        />
      ),
    });
  }

  render() {
    if (!this.props.limit) {
      var i = 0;
      var equipmentsSumNode = this.props.materialsTypes.map((materialsType) => {
        if (this.props.limit && ++i > this.props.limit) return null;

        const rowTotal = this.countEquipments(
          materialsType._id,
          this.state.equipments
        );
        const rowEhpad = this.countOwnerEquipments(
          materialsType._id,
          this.state.equipments,
          EqOwner.INTERNAL_EHPAD
        );
        const rowPsdm = this.countOwnerEquipments(
          materialsType._id,
          this.state.equipments,
          EqOwner.DM_FROM_PSDM
        );
        const rowExternal = this.countOwnerEquipments(
          materialsType._id,
          this.state.equipments,
          EqOwner.EXTERNAL
        );

        return (
          (rowTotal > 0) ?
            <tr key={materialsType._id}>
              <td>{materialsType.name}</td>
              <td className="col-2 text-center">
                <Badge
                  variant={rowTotal > 0 ? "info" : "light"}
                  className="w-100"
                >
                  {rowTotal}
                </Badge>
              </td>
              <td className="col-2 text-center">
                <Badge
                  variant={rowEhpad > 0 ? "warning" : "light"}
                  className="w-100"
                >
                  {rowEhpad}
                </Badge>
              </td>
              <td className="col-2 text-center">
                <Badge
                  variant={rowPsdm > 0 ? "success" : "light"}
                  className="w-100"
                >
                  {rowPsdm}
                </Badge>
              </td>
              <td className="col-2 text-center">
                <Badge
                  variant={rowExternal > 0 ? "danger" : "light"}
                  className="w-100"
                >
                  {rowExternal}
                </Badge>
              </td>
            </tr>
            :
            ""
        );
      });

      return (
        <table className="table tablee4coll">
          <thead>
            <tr>
              <th scope="col">
                <FormattedMessage id="Type" />
              </th>
              <th scope="col" className="text-center">
                <FormattedMessage id="Total" />
              </th>
              <th scope="col" className="text-center">
                <FormattedMessage id="EqOwner.quickview.0" />
              </th>
              <th scope="col" className="text-center">
                <FormattedMessage id="EqOwner.quickview.1" />
              </th>
              <th scope="col" className="text-center">
                <FormattedMessage id="EqOwner.quickview.2" />
              </th>
            </tr>
          </thead>
          <tbody>{equipmentsSumNode}</tbody>
        </table>
      );
    }
    var options = {
      //responsive: false,
      //tooltips: {enabled: false},// annule le hover
      legend: {
        display: true,
      },
    };
    return (
      <div>
        {this.state.equipments.length < 1 ? (
          <Doughnut
            key={Math.random()}
            options={options}
            data={this.buildChartData(
              this.state.materialsTypes,
              this.props.equipments
            )}
          />
        ) : (
          <div>
            <Doughnut
              key={Math.random()}
              options={options}
              data={this.buildChartData(
                this.state.materialsTypes,
                this.state.equipments
              )}
            />
            <div className="row d-flex justify-content-center">
              <span
                className="btn btn-m btn-outline-secondary"
                onClick={(e) => this.openSummaryModal()}
              >
                <FormattedMessage id="Detail" />
              </span>
            </div>
          </div>
        )}
        {this.state.modal}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.user,
    materialsTypes: state.materialsTypes,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    //
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(FleetSummary);
