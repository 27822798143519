import { GET_RESIDENTS } from "../../actions/residents/residents";

export default function residents(state = [], action) {
  switch (action.type) {
    case GET_RESIDENTS:
      return action.residents;
    default:
      return state;
  }
}
