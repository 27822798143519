import React from "react";
import Orders from "../orders/Orders";
import Estimates from "../estimates/Estimates";
import FleetSummary from "../fleet/FleetSummary";
import Requests from "../requests/Requests";
import { connect } from "react-redux";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";
import { Card } from "react-bootstrap";
import Icon from "../sub/Icon";

class SplitHome extends React.Component {
  render() {
    return (
      <React.Fragment>
        <div className="row">
          <div className="col-12 col-lg-6">
            <div className="m-2 zoom-7">
              <Card className="p-3">
                <Card.Title>
                  <h4>
                    <FormattedMessage id="Summary.Orders" />
                  </h4>
                </Card.Title>
                <Orders limit={5} />
                <Link to="/home/orders" className="overlay text-center">
                  <Icon icon="magnifying-glass" />
                </Link>
              </Card>
            </div>
          </div>

          <div className="col-12 col-lg-6">
            <div className="m-2 zoom-7">
              <Card className="p-3">
                <Card.Title>
                  <h4>
                    <FormattedMessage id="Summary.Estimates" />
                  </h4>
                </Card.Title>
                <Estimates limit={5} />
                <Link to="/home/estimates" className="overlay text-center">
                  <Icon icon="magnifying-glass" />
                </Link>
              </Card>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-12 col-lg-6 ">
            <div className="m-2 zoom-1">
              <Card className="p-3">
                <Card.Title>
                  <h4>
                    <FormattedMessage id="Fleet.Summary" />
                  </h4>
                </Card.Title>
                <FleetSummary limit={1} equipments={this.props.equipments} />
              </Card>
            </div>
          </div>

          <div className="col-12 col-lg-6 ">
            <div className="m-2 zoom-7">
              <Card className="p-3">
                <Card.Title>
                  <h4>
                    <FormattedMessage id="Summary.Requests" />
                  </h4>
                </Card.Title>
                <Requests limit={5} />
                <Link to="/home/requests" className="overlay text-center">
                  <Icon icon="magnifying-glass" />
                </Link>
              </Card>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    equipments: state.equipments,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    //
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SplitHome);
