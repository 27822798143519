import React from "react";
import { connect } from "react-redux";
import ModelsAdminTable from "./ModelsAdminTable";

class ModelsAdmin extends React.Component {
  render() {
    return (
      <React.Fragment>
        <ModelsAdminTable
          equipments={this.props.equipments}
          brands={this.props.brands}
          models={this.props.models}
        />
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.user,
    equipments: state.equipments,
    brands: state.brands,
    models: state.models,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    //
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ModelsAdmin);
