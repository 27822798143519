import React from "react";
import OrdersAdmin from "../orders/admin/OrdersAdmin";
import Estimates from "../estimates/Estimates";
import RequestsAdmin from "../requests/admin/RequestsAdmin";
import Establishments from "../clients/Establishments";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";
import { Card } from "react-bootstrap";
import Icon from "../sub/Icon";

export default class SplitHomeAdmin extends React.Component {
  render() {
    return (
      <React.Fragment>
        <div className="row">
          <div className="col-12 col-lg-6">
            <div className="m-2 zoom-7">
              <Card className="p-3">
                <Card.Title>
                  <h4>
                    <FormattedMessage id="Summary.Orders.Admin" />
                  </h4>
                </Card.Title>
                <OrdersAdmin limit={5} />
                <Link to="/home/orders" className="overlay text-center">
                  <Icon icon="magnifying-glass" />
                </Link>
              </Card>
            </div>
          </div>

          <div className="col-12 col-lg-6">
            <div className="m-2 zoom-7">
              <Card className="p-3">
                <Card.Title>
                  <h4>
                    <FormattedMessage id="Summary.Estimates.Admin" />
                  </h4>
                </Card.Title>
                <Estimates limit={5} />
                <Link to="/home/estimates" className="overlay text-center">
                  <Icon icon="magnifying-glass" />
                </Link>
              </Card>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-12 col-lg-6 ">
            <div className="m-2 zoom-7">
              <Card className="p-3">
                <Card.Title>
                  <h4>
                    <FormattedMessage id="Summary.Clients.Admin" />
                  </h4>
                </Card.Title>
                <Establishments limit={5} />
                <Link to="/home/clients" className="overlay text-center">
                  <Icon icon="magnifying-glass" />
                </Link>
              </Card>
            </div>
          </div>

          <div className="col-12 col-lg-6 ">
            <div className="m-2 zoom-7">
              <Card className="p-3">
                <Card.Title>
                  <h4>
                    <FormattedMessage id="Summary.Requests.Admin" />
                  </h4>
                </Card.Title>
                <RequestsAdmin limit={5} />
                <Link to="/home/requests" className="overlay text-center">
                  <Icon icon="magnifying-glass" />
                </Link>
              </Card>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
