import React from 'react';
import { Popover, OverlayTrigger, PopoverTitle, PopoverContent } from 'react-bootstrap';
import Icon from './Icon.js';

class PopoverHelper extends React.Component {

    render() {

        const title = this.props.title || false;
        const content = this.props.content || false;
        const placement = this.props.placement || "right";
        const trigger = this.props.trigger || "click";
        const variant = this.props.variant || "dark"

        let myPopover =
            <Popover>
                {title && <PopoverTitle>
                    {title}
                </PopoverTitle>}
                <PopoverContent className="text-justify">
                    {content}
                </PopoverContent>
            </Popover>

        return (
            <OverlayTrigger trigger={trigger} placement={placement} overlay={myPopover}>
                <Icon icon="circle-question" className={"cursor-pointer ml-1 text-" + variant}/>
            </OverlayTrigger>
        );
    }
}

export default PopoverHelper;