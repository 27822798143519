import React from "react";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import ProductsBudget from "../ProductsBudget";
import OverTimeBudget from "../OverTimeBudget";
import TopProductsBudget from "../TopProductsBudget";
import Icon from "../../sub/Icon";
import Util from "../../../util/Util";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import TableToolbar from "../../sub/bootstrap/TableToolbar";

class BudgetAdmin extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      establishmentId: null,
    };
  }

  selectEstablishment(establishmentId) {
    this.setState({ establishmentId: establishmentId });
  }

  getClient(clientId) {
    for (let client of this.props.clients) {
      if (client._id === clientId) return client;
    }
  }

  getOrders(establishmentId) {
    var orders = [];

    for (let order of this.props.orders) {
      var client = this.getClient(order.id_client);

      if (!client) continue;

      if (client.establishment_id === establishmentId) {
        orders.push(order);
      }
    }
    return orders;
  }

  getEstablishmentSettings(establishmentId) {
    for (let establishmentSettings of this.props.establishmentsSettings) {
      if (establishmentSettings.establishment_id === establishmentId)
        return establishmentSettings;
    }
  }

  render() {
    var establishmentsNode = this.props.establishments.map((establishment) => {
      return (
        <option key={establishment._id} value={establishment._id}>
          {establishment.name}
        </option>
      );
    });

    var establishmentSelectNode = (
      <TableToolbar>
        <Icon icon="building" className="ml-auto mr-4" />
        <select
          id="fleet-filter-establishmentId"
          className="form-control mr-auto"
          onChange={(e) => this.selectEstablishment(e.target.value)}
        >
          <option value="">
            {this.props.intl.formatMessage({
              id: "Select.Establishment",
            })}
            ...
          </option>
          {establishmentsNode}
        </select>
      </TableToolbar>
    );

    // The admin hasn't picked a client yet
    if (
      !this.state.establishmentId ||
      Util.emptyString(this.state.establishmentId)
    )
      return establishmentSelectNode;

    var orders = this.getOrders(this.state.establishmentId);
    var establishmentSettings = this.getEstablishmentSettings(
      this.state.establishmentId
    );

    return (
      <React.Fragment>
        {establishmentSelectNode}

        <Tabs defaultActiveKey="categories" id="budgetTabs">
          <Tab
            eventKey="categories"
            title={this.props.intl.formatMessage({ id: "Categories" })}
          >
            <ProductsBudget orders={orders} />
          </Tab>
          <Tab
            eventKey="products"
            title={this.props.intl.formatMessage({ id: "Products" })}
          >
            <TopProductsBudget orders={orders} />
          </Tab>
          <Tab
            eventKey="budgets"
            title={this.props.intl.formatMessage({ id: "Budgets" })}
          >
            <OverTimeBudget
              orders={orders}
              establishmentSettings={establishmentSettings}
              establishmentId={this.state.establishmentId}
            />
          </Tab>
        </Tabs>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    establishments: state.establishments,
    orders: state.orders,
    clients: state.clients,
    establishmentsSettings: state.establishmentsSettings,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    //
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(BudgetAdmin));
