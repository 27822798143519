import APIUrl from "../../APIUrl";
import axios from "axios";

export const GET_OLD_PRODUCTS = "GET_OLD_PRODUCTS";

function getOldProductsAction(oldProducts) {
  return { type: GET_OLD_PRODUCTS, oldProducts: oldProducts };
}

// Small hack that enables us to not ask the BE twice for an old product
var alreadyRequestedIds = {};
var removeAlreadyRequestedIds = function (ids) {
  let idsToSend = [];

  for (let id of ids) {
    if (!alreadyRequestedIds[id]) {
      idsToSend.push(id);
      alreadyRequestedIds[id] = true;
    }
  }

  return idsToSend;
};

export const getOldProducts = function (data) {
  return function (dispatch) {
    // Careful, don't ask the BE twice for a product
    let idsToSend = removeAlreadyRequestedIds(data.ids);
    if (idsToSend.length === 0) return;

    return axios
      .post(APIUrl.getManyProducts, { ids: idsToSend })
      .then(function (response) {
        dispatch(getOldProductsAction(response.data));
      })
      .catch(function (err) {
        throw err;
      });
  };
};

export const resetOldProducts = function () {
  alreadyRequestedIds = {};
};
