import React from "react";
import { FormattedMessage } from "react-intl";
import { Button, Modal } from "react-bootstrap";

class BarCodeModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      disabled: false,
      barCodeNumber: 24,
      barCodeNumberMin: 24,
      barCodeNumberMax: 2016,
    };
  }

  isCorrectNumber() {
    return (
      this.state.disabled ||
      this.state.barCodeNumber > this.state.barCodeNumberMax ||
      this.state.barCodeNumber <= 0
    );
  }

  print = () => {
    if (this.isCorrectNumber()) return;

    var callback = async () => {
      await this.props.successCallback(this.state.barCodeNumber);
      this.props.close();
    };

    this.setState({ disabled: true }, callback);
  };

  onChange = (e) => {
    this.setState({ barCodeNumber: e.target.value });
  };

  render() {
    return (
      <Modal
        show={true}
        onHide={() => this.props.close()}
        backdrop={"static"}
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <FormattedMessage id="Bar.Codes.Number" />
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <input
            type="number"
            className="form-control"
            id="barCodeNumber"
            onChange={this.onChange}
            value={this.state.barCodeNumber}
            min={this.state.barCodeNumberMin}
            max={this.state.barCodeNumberMax}
            step={this.state.barCodeNumberMin}
          />
        </Modal.Body>

        <Modal.Footer>
          <Button variant="secondary" onClick={() => this.props.close()}>
            <FormattedMessage id="Cancel" />
          </Button>
          <Button
            variant="info"
            onClick={() => this.print()}
            disabled={this.isCorrectNumber()}
          >
            <FormattedMessage id="Print" />
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default BarCodeModal;
