import React from "react";
import { connect } from "react-redux";
import { FormattedMessage, injectIntl } from "react-intl";
import DateUtil from "../../util/DateUtil";
import RequestTypes from "../../enums/RequestTypes";
import MenuButton from "../sub/bootstrap/MenuButton";

class RequestRow extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      modal: null,
    };
  }

  openRequestDetailModal(request) {
    this.props.openRequestDetailModal(request);
  }

  render() {
    // var status = <FormattedMessage id={"Request.Status." + this.props.request.status} />;
    let status, statusClassName;

    // /!\ WARNING: RequestRow.js is used both for estimates and requests !!!
    // This test below checks which functionnality we are on right now
    if (this.props.request.request_type === RequestTypes.NEW_MATERIAL) {
      if (parseInt(this.props.request.status) === 0)
        statusClassName = "success";
      if (parseInt(this.props.request.status) === 1)
        statusClassName = "warning";
      else if (parseInt(this.props.request.status) === 2)
        statusClassName = "info";
      else if (parseInt(this.props.request.status) === 3)
        statusClassName = "danger";

      status = (
        <div className={"alert alert-" + statusClassName + " p-2"}>
          &nbsp;
          {this.props.intl.formatMessage({
            id: "Estimate.Status." + this.props.request.status,
          })}
          &nbsp;
        </div>
      );
    } else {
      if (parseInt(this.props.request.status) === 0) statusClassName = "danger";
      if (parseInt(this.props.request.status) === 1)
        statusClassName = "warning";
      else if (parseInt(this.props.request.status) === 2)
        statusClassName = "success";
      else if (parseInt(this.props.request.status) === 3)
        statusClassName = "danger";

      status = (
        <div className={"alert alert-" + statusClassName + " p-2"}>
          &nbsp;
          {this.props.intl.formatMessage({
            id: "Request.Status." + this.props.request.status,
          })}
          &nbsp;
        </div>
      );
    }

    return (
      <tr key={this.props.key} className="d-flex align-items-center">
        <td className="col">
          {DateUtil.toDate(this.props.request.creation_date)}
        </td>
        <td className="col"> {this.props.request.client_name}</td>
        {this.props.includeType && (
          <td className="col">
            <FormattedMessage
              id={
                this.props.request.type_of_renewal === undefined
                  ? "Request.Type." + this.props.request.request_type
                  : this.props.request.type_of_renewal === 0
                    ? "Request.Type.1"
                    : this.props.request.type_of_renewal === 1 && "Request.Type.3"
              }
            />
          </td>
        )}
        {this.props.request.request_type !== RequestTypes.NEW_MATERIAL && (
          <td className="col">{this.props.request.material_type_name}</td>
        )}
        <td className="col">
          <div className="truncate">{this.props.request.precisions}</div>
        </td>
        <td className={!this.props.limit ? "col col-2" : "col col-3"}>
          {status}
        </td>
        {!this.props.limit && (
          <td className="col col-1 text-center">
            <MenuButton
              icon={"eye"}
              onClick={(e) => this.openRequestDetailModal(this.props.request)}
              hover={<FormattedMessage id="Consult" />}
            />
          </td>
        )}
      </tr>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    //
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    //
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(RequestRow));
