import { GET_PRODUCTS, SET_NEWPRODUCTS } from "../../actions/products/products";

export default function products(state = [], action) {
  switch (action.type) {
    case GET_PRODUCTS:
      return action.products;
    case SET_NEWPRODUCTS:
      let response = [...state];
      let reponseHere = false;
      if (action.products.length !== 0) {
        for (let i = 0; i < action.products.length; i++) {
          reponseHere = false;
          for (let z = 0; z < response.length; z++) {
            if (response[z]._id.includes(action.products[i]._id))
              reponseHere = true;
          }
          if (response.length !== 0) {
            if (reponseHere === false) {
              response.push(action.products[i]);
            }
          } else {
            response.push(action.products[i]);
          }
        }
      }
      return response;
    default:
      return state;
  }
}
