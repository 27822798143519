import React from "react";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import Collaborator from "./Collaborator";
import Assignments from "./Assignments";

import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import { Modal } from "react-bootstrap";

class CollaboratorsModal extends React.Component {
  getCollaborator(collaboratorId) {
    if (!collaboratorId) return null;

    for (let collaborator of this.props.collaborators) {
      if (collaborator._id === collaboratorId) return collaborator;
    }
  }

  render() {
    var collaborator = this.getCollaborator(this.props.collaboratorId);

    var aClassName = collaborator ? "" : "disabled";

    return (
      <Modal
        show={true}
        onHide={() => this.props.close()}
        backdrop={"static"}
        size="xl"
      >
        <Modal.Body>
          <button
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
            onClick={() => this.props.close()}
          >
            <span aria-hidden="true">&times;</span>
          </button>

          <Tabs defaultActiveKey="collaboratorInfo" id="collaboratorTabs">
            <Tab
              eventKey="collaboratorInfo"
              title={this.props.intl.formatMessage({ id: "Informations" })}
            >
              <Collaborator
                company={this.props.company}
                collaborator={collaborator}
                onCollaboratorCreated={(collaborator) =>
                  this.props.onCollaboratorCreated(collaborator)
                }
              />
            </Tab>
            <Tab
              eventKey="clientSettings"
              title={this.props.intl.formatMessage({ id: "Assignments" })}
              disabled={aClassName}
            >
              <Assignments collaborator={collaborator} />
            </Tab>
          </Tabs>
        </Modal.Body>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    collaborators: state.collaborators,
    company: state.company,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    //
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(CollaboratorsModal));
