import React from "react";
import { connect } from "react-redux";
import { FormattedMessage, injectIntl } from "react-intl";
import RequestTypes from "../../enums/RequestTypes";
import RequestStatus from "../../enums/RequestStatus";
import { createRequest } from "../../actions/requests/requests";
import EquipmentOverview from "./EquipmentOverview";
import EqRenewalTypeNode from "../../enums/EqRenewalTypeNode";
import CustomLabel from "../sub/CustomLabel";
import { NotificationManager } from "react-notifications";
import { Modal } from "react-bootstrap";
import TextareaCounter from 'react-textarea-counter';

import "react-notifications/lib/notifications.css";

class RenewalRequest extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      material_type_id: "",
      precisions: "",
      type_of_renewal: "",
      disabled: false,
      disabledInputs: false,
    };

    if (this.props.request) {
      this.state = {
        material_type_id: this.props.request.material_type_id,
        type_of_renewal:
          this.props.request.type_of_renewal !== undefined
            ? this.props.request.type_of_renewal
            : 0,
        precisions: this.props.request.precisions,
        disabledInputs: true,
        disabled: false,
      };
    }
  }

  create() {
    if (this.state.disabled) return;
    if (
      this.state.precisions !== "" &&
      this.state.type_of_renewal !== "" &&
      this.state.type_of_renewal !== 10
    ) {
      var data = {
        request_type: RequestTypes.RENEWAL,
        type_of_renewal: this.state.type_of_renewal,
        equipment_id: this.props.equipment._id,
        material_type_id: this.props.equipment.material_type_id,
        precisions: this.state.precisions,
        status: RequestStatus.NEW,
      };

      this.setState({ disabled: true });

      this.props.onCreateRequest(data, () => this.props.close());
    } else {
      this.NotifEmptyFieldRenewal();
    }
  }
  NotifEmptyFieldRenewal = () => {
    let NotifEmptyFieldRenewal = this.props.intl.formatMessage({
      id: "Materiovigilance.EmptyField",
    });

    NotificationManager.error(NotifEmptyFieldRenewal);
  };

  onChange(key, value) {
    this.setState({ [key]: value });
  }

  render() {
    //*************** List of renewal type ********/
    var renewalClassName = "";
    if (parseInt(this.state.type_of_renewal) === 0)
      renewalClassName = "alert-warning";
    if (parseInt(this.state.type_of_renewal) === 1)
      renewalClassName = "alert-danger";

    // console.log("coucou", renewalClassName);
    var eqRenewalTypeNode = Object.values(EqRenewalTypeNode).map((option) => {
      return (
        <option key={option} value={option}>
          {this.props.intl.formatMessage({
            id: "EqRenewalTypeNode." + option,
          })}
        </option>
      );
    });
    return (
      <React.Fragment>
        <EquipmentOverview equipment={this.props.equipment} />
        <div className="form-group row">
          <CustomLabel
            label={this.props.intl.formatMessage({ id: "Type" })}
            htmlFor="renewal-type"
            required={this.props.required}
          />
          <div className="col-12 col-sm-7 mb-20">
            {!this.state.disabledInputs ? (
              <select
                id="renewal-type"
                className={"form-control " + renewalClassName}
                value={this.state.type_of_renewal}
                onChange={(e) =>
                  this.setState({
                    type_of_renewal: parseInt(e.target.value),
                  })
                }
              >
                <option key={10} value={10}>
                  {this.props.intl.formatMessage({ id: "Please.Choose" }) +
                    "..."}
                </option>
                {eqRenewalTypeNode}
              </select>
            ) : (
              <div className={"form-control " + renewalClassName}>
                <h6 className="space-between">
                  {this.props.intl.formatMessage({
                    id: "EqRenewalTypeNode." + this.state.type_of_renewal,
                  })}
                </h6>
              </div>
            )}
          </div>
          <CustomLabel
            label={this.props.intl.formatMessage({ id: "Precisions" })}
            htmlFor="precisions"
            required={this.props.required}
          />
          <div className="col-12 col-sm-7">
            <TextareaCounter
              countLimit={1000}
              className="textareaCounter"
              id="precisions"
              value={this.state.precisions}
              onChange={(e) => this.onChange("precisions", e.target.value)}
              disabled={this.state.disabledInputs}
            />
          </div>
        </div>

        {!this.state.disabledInputs && (
          <Modal.Footer>
            <button
              type="button"
              className="btn btn-secondary"
              data-dismiss="modal"
              onClick={() => this.props.close()}
            >
              <FormattedMessage id="Cancel" />
            </button>
            <button
              type="button"
              className="btn btn-info"
              onClick={() => this.create()}
              disabled={this.state.disabled}
            >
              <FormattedMessage id="Create" />
            </button>
          </Modal.Footer>
        )}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    materialsTypes: state.materialsTypes,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onCreateRequest: (data, successCallback) =>
      dispatch(createRequest(data, successCallback)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(RenewalRequest));
