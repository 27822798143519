import APIUrl from "../../APIUrl";
import axios from "axios";

export const GET_COMPANY = "GET_COMPANY";

export function getCompanyAction(company) {
  return { type: GET_COMPANY, company: company };
}

export const getCompany = function () {
  return function (dispatch) {
    return axios
      .get(APIUrl.getCompany)
      .then(function (response) {
        if (response) dispatch(getCompanyAction(response.data));
      })
      .catch(function (err) {
        throw err;
      });
  };
};
