import APIUrl from "../../APIUrl";
import axios from "axios";

export const GET_CLIENTS = "GET_CLIENTS";

function getClientsAction(clients) {
  return { type: GET_CLIENTS, clients: clients };
}

export const getClients = function () {
  return function (dispatch) {
    return axios
      .get(APIUrl.getClients)
      .then(function (response) {
        dispatch(getClientsAction(response.data));
      })
      .catch(function (err) {
        throw err;
      });
  };
};

// =============================================================================
// =================================== ADMIN ===================================
// =============================================================================

export const getClientsAdmin = function () {
  return function (dispatch) {
    return axios
      .get(APIUrl.getClientsAdmin)
      .then(function (response) {
        dispatch(getClientsAction(response.data));
      })
      .catch(function (err) {
        throw err;
      });
  };
};

export const addClient = function (data, successCallback) {
  return function (dispatch) {
    return axios
      .post(APIUrl.addClient, data)
      .then(function (response) {
        dispatch(getClientsAdmin());
        successCallback();
      })
      .catch(function (err) {
        throw err;
      });
  };
};

export const updateClient = function (data) {
  return function (dispatch) {
    return axios
      .post(APIUrl.updateClient, data)
      .then(function (response) {
        dispatch(getClientsAdmin());
      })
      .catch(function (err) {
        throw err;
      });
  };
};

export const deleteClient = function (data) {
  return function (dispatch) {
    return axios
      .post(APIUrl.deleteClient, data)
      .then(function (response) {
        dispatch(getClientsAdmin());
      })
      .catch(function (err) {
        throw err;
      });
  };
};
