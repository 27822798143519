import { GET_MATERIALS_TYPES } from "../../../actions/settings/materialsTypes/materialsTypes";

export default function materialsTypes(state = [], action) {
  switch (action.type) {
    case GET_MATERIALS_TYPES:
      return action.materialsTypes;
    default:
      return state;
  }
}
