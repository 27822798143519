import { GET_CLIENT_CODE } from "../../actions/apiMust/apiMust";

export default function apiMust(state = [], action) {
  switch (action.type) {
    case GET_CLIENT_CODE:
      return action.getClientCode;
    default:
      return state;
  }
}
