import APIUrl from "../../APIUrl";
import axios from "axios";
import { getEstablishmentEquipments } from "../equipments/equipments";

export const GET_MAINTENANCES = "GET_MAINTENANCES";

function getMaintenancesAction(maintenances) {
  return { type: GET_MAINTENANCES, maintenances: maintenances };
}

export const getMaintenances = function () {
  return function (dispatch) {
    return axios
      .get(APIUrl.getMaintenances)
      .then(function (response) {
        if (response) dispatch(getMaintenancesAction(response.data));
      })
      .catch(function (err) {
        throw err;
      });
  };
};

export const getMaintenance = function (establishmentId) {
  return function (dispatch) {
    return axios
      .post(APIUrl.getMaintenance, { establishmentId: establishmentId })
      .then(function (response) {
        if (response) dispatch(getMaintenancesAction(response.data));
      })
      .catch(function (err) {
        throw err;
      });
  };
};

export const getMaintenanceByEquipmentId = function (equipmentId, successCallback) {
  return function (dispatch) {
    return axios
      .post(APIUrl.getMaintenanceByEquipmentId, { equipmentId: equipmentId })
      .then(function (response) {
        // if (response) dispatch(getMaintenancesAction(response.data));
        return successCallback(response.data);
      })
      .catch(function (err) {
        throw err;
      });
  };
};

export const getMaintenanceById = function (maintenanceId, successCallback) {
  return function (dispatch) {
    return axios
      .post(APIUrl.getMaintenanceById, {maintenanceId: maintenanceId})
      .then(function (response) {
        if (response) successCallback(response.data)
      })
      .catch(function (err) {
        throw err;
      });
  };
};

export const hasAlreadyMaintenance = function (equipmentId) {
  return function () {
    return axios
      .post(APIUrl.hasAlreadyMaintenance, { equipmentId })
      .then(function (response) {
        if (response) return response.data;
      })
      .catch(function (err) {
        throw err;
      });
  };
};

// ==================================================================
// =========================== ADMIN ================================
// ==================================================================

export const addMaintenance = function (
  establishmentId,
  data,
  successCallback
) {
  return function (dispatch) {
    return axios
      .post(APIUrl.addMaintenance, data)
      .then(function (response) {
        dispatch(getEstablishmentEquipments(establishmentId));
        dispatch(getMaintenance(establishmentId));
        if (response) successCallback(response.data);
      })
      .catch(function (err) {
        throw err;
      });
  };
};

export const deleteMaintenance = function (
  establishmentId,
  data,
  successCallback
) {
  return function (dispatch) {
    return axios
      .post(APIUrl.deleteMaintenance, data)
      .then(function (response) {
        dispatch(getEstablishmentEquipments(establishmentId));
        dispatch(getMaintenance(establishmentId));
        if (response) successCallback();
      })
      .catch(function (err) {
        throw err;
      });
  };
};