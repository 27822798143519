export default class EstablishmentEquipmentUtil {
  constructor(establishmentId, equipments) {
    this.establishmentId = establishmentId;
    this.equipments = equipments;
  }

  countEquipments() {
    var total = 0;

    for (let equipment of this.equipments) {
      if (equipment.establishment_id === this.establishmentId) {
        total++;
      }
    }

    return total;
  }
}
