import { GET_REQUESTS } from "../../actions/requests/requests";

export default function requests(state = [], action) {
  switch (action.type) {
    case GET_REQUESTS:
      return action.requests;
    default:
      return state;
  }
}
