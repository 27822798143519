import React from "react";
import { connect } from "react-redux";
import { NotificationManager } from "react-notifications";
import { FormattedMessage, injectIntl } from "react-intl";
import Util from "../../util/Util";
import { updateUser } from "../../actions/user/user";
import ModalManager from "../sub/modals/ModalManager";
import CustomLabel from "../sub/CustomLabel";

import "react-notifications/lib/notifications.css";

class UpdatePwdForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      password: "",
      passwordConfirmation: "",
      disabled: false,
      error: null,
      modal: null,
    };
  }

  onChange(key, value) {
    this.setState({
      [key]: value,
      error: null,
    });
  }

  hasEmptyField() {
    return (
      Util.emptyString(this.state.password) ||
      Util.emptyString(this.state.passwordConfirmation)
    );
  }

  closeModal() {
    this.setState({
      modal: null,
      password: "",
      passwordConfirmation: "",
    });
  }

  update() {
    // Check password parity
    if (this.state.password !== this.state.passwordConfirmation) {
      let errorMessage = this.props.intl.formatMessage({
        id: "Password.Does.Not.Match",
      });
      NotificationManager.error(errorMessage);
      return;
    }

    // Check password syntax
    if (!Util.checkValidPasswordForRecovery(this.state.password)) {
      let errorMessage = this.props.intl.formatMessage({
        id: "Password.Not.Conform",
      });
      NotificationManager.error(errorMessage);
      return;
    }

    /*
        if (this.state.password !== this.state.passwordConfirmation) {
            this.setState({ error: <FormattedMessage id="Password.Does.Not.Match" /> });
            return;
        }

        if (Util.emptyString(this.state.password) || Util.emptyString(this.state.passwordConfirmation) || this.state.disabled) return;
        */

    var data = {
      updatedField: "password",
      updatedValue: this.state.password,
    };

    this.setState({ disabled: true });

    var onUpdatePwdSuccess = () => {
      var title = <FormattedMessage id="Success" />;
      var content = <FormattedMessage id="Password.Reset.Success" />;
      this.setState({
        disabled: false,
        modal: (
          <ModalManager
            showModal={true}
            title={title}
            content={content}
            closeModal={() => this.closeModal()}
            modalType="success"
          />
        ),
      });
    };

    this.props.onUpdateUser(data, onUpdatePwdSuccess);
  }

  render() {
    return (
      <React.Fragment>
        <div className="col-12 col-sm-8">
          <div className="form-group row">
            <CustomLabel
              label={this.props.intl.formatMessage({ id: "Password" })}
              htmlFor="password"
              labelClassName="col-12 col-sm-5"
            />
            <div className="col-12 col-sm-7">
              <input
                className="form-control"
                type="password"
                rows="7"
                autoComplete="off"
                value={this.state.password}
                onChange={(e) => this.onChange("password", e.target.value)}
              />
            </div>
          </div>
          <div className="form-group row">
            <CustomLabel
              label={this.props.intl.formatMessage({
                id: "Password.Confirmation",
              })}
              htmlFor="passwordConfirmation"
              labelClassName="col-12 col-sm-5"
            />
            <div className="col-12 col-sm-7">
              <input
                className="form-control"
                type="password"
                rows="7"
                autoComplete="off"
                value={this.state.passwordConfirmation}
                onChange={(e) =>
                  this.onChange("passwordConfirmation", e.target.value)
                }
              />
            </div>
          </div>

          <br />
          <br />

          <button
            type="submit"
            className="btn btn-info btn-block col-7 m-auto"
            onClick={(e) => this.update()}
            disabled={this.state.disabled || this.hasEmptyField()}
          >
            <FormattedMessage id="Reset" />
          </button>
        </div>
        {this.state.modal}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.user,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onUpdateUser: (data, successCallback) =>
      dispatch(updateUser(data, successCallback)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(UpdatePwdForm));
