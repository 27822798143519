import React, { useState } from "react";
import { connect } from "react-redux";
import { FormattedMessage, injectIntl } from "react-intl";
import {
  addMaterialsType,
  deleteMaterialTypeImg,
} from "../../../../../actions/settings/materialsTypes/materialsTypes";
import {
  updateMaterialType,
  addMaterialTypeImg,
} from "../../../../../actions/settings/materialsTypes/materialsTypes";
import materialTypesSugg from "./materialTypesSugg.json";
import Util from "../../../../../util/Util";
import APIUrl from "../../../../../APIUrl";
import StringUtil from "../../../../../util/StringUtil";
import CustomLabel from "../../../../sub/CustomLabel";
import FileDropZone from "../../../../sub/FileDropZone";
import {
  Alert,
  Col,
  Form,
  InputGroup,
  Modal,
  Row,
  Tab,
  Tabs,
} from "react-bootstrap";
import { nanoid } from "nanoid";
import UploadCustomImgModal from "../../../../sub/modals/UploadCustomImgModal";
import MenuButton from "../../../../sub/bootstrap/MenuButton";
import MaterialsTypesModalCheckpoints from "./MaterialsTypesModalCheckpoints";

function MaterialsTypesModalAdmin({
  materialTypeId,
  materialsTypes,
  closeModal,
  intl,
  onAddMaterialsType,
  onUpdateMaterialType,
  onAddMaterialTypeImg,
  onDeleteMaterialTypeImg,
}) {
  const getMaterialType = (materialTypeId) => {
    if (!materialTypeId) return null;

    for (const materialType of materialsTypes) {
      if (materialType._id === materialTypeId) return materialType;
    }
  };

  const materialType = getMaterialType(materialTypeId);
  const [name, setName] = useState(materialType ? materialType.name : "");
  const [nameError, setNameError] = useState("");
  const [checkpoints, setCheckpoints] = useState(
    materialType ? materialType.checkpoints || [] : []
  );
  const [disabled, setDisabled] = useState(false);
  const [file, setFile] = useState(null);
  const [modal, setModal] = useState(null);

  const closeParentModal = () => closeModal();
  const close = () => setModal(null);

  const isMaterialTypeNameAlreadyExists = (name) => {
    for (const materialTypeName of materialsTypes) {
      if (materialTypeName.name.toUpperCase() === name.toUpperCase().trim()) {
        setNameError(<FormattedMessage id="Material.Exist" />);
        return true;
      }
    }
    return false;
  };

  const getSuggestions = (materialType) => {
    if (!materialType) return;

    const checkpoints = [];

    for (const m of materialTypesSugg) {
      if (m.name === materialType) {
        for (const c of m.checkpoints) {
          checkpoints.push(intl.formatMessage({ id: c }));
        }
      }
    }

    return checkpoints;
  };

  const onChange = (value) => {
    setName(value);

    if (Util.emptyString(value)) {
      return setNameError(<FormattedMessage id="Material.Empty.Error" />);
    }

    if (isMaterialTypeNameAlreadyExists(value)) return;

    setNameError("");
    setDisabled(false);

    const suggestions = getSuggestions(value);
    if (suggestions && suggestions.length > 0) {
      setCheckpoints(checkpoints.concat(suggestions));
    }
  };

  const add = () => {
    setDisabled(true);

    const data = {
      name: name.trim(),
      checkpoints: checkpoints,
    };

    const callback = (newMaterialType) => {
      sendImg(file, newMaterialType._id, () => closeParentModal());
    };

    onAddMaterialsType(data, callback);
  };

  const update = (fieldName, fieldValue) => {
    if (!materialType || nameError) return;

    onUpdateMaterialType({
      materialTypeId: materialType._id,
      fieldName: fieldName,
      fieldValue: fieldValue.trim(),
    });
  };

  const onDropFile = (file, clearCallback) => {
    if (nameError) return;

    if (!materialType) return setFile(file);

    sendImg(file, materialType._id, clearCallback);
  };

  const sendImg = (file, materialTypeId, callback) => {
    if (!file) return callback();

    let formData = new FormData();
    formData.append(file.name, file);

    const successCallback = () => {
      if (callback) callback();
    };

    onAddMaterialTypeImg(materialTypeId, formData, successCallback);
  };

  const deleteImg = (materialType) => {
    if (!materialType) return;

    onDeleteMaterialTypeImg(materialType._id);
  };

  const checkSimilarities = () => {
    let materialsTypes = [];
    for (let materialsType of materialsTypes)
      materialsTypes.push(materialsType.name);
    let simMaterialsTypes = [];
    outer: for (let m0 of materialsTypes) {
      let m1 = name;

      if (m0 === m1) continue;

      for (let s of simMaterialsTypes) {
        if ((s[0] === m1 && s[1] === m0) || (s[0] === m0 && s[1] === m1)) {
          continue outer;
        }
      }

      // console.log(m0 + " / " + m1 + " : " + StringUtil.similarity(m0, m1));
      if (StringUtil.similarity(m0, m1) > 0.8) {
        simMaterialsTypes.push([m0, m1]);
      }
    }

    return simMaterialsTypes;
  };

  const openUploadImgModal = (materialTypeId, currentImage) => {
    setModal(
      <UploadCustomImgModal
        closeModal={() => close()}
        currentImage={currentImage}
        mode="upload"
        textInfo={<FormattedMessage id="Imagery.Message.Material.Type.1" />}
        onComplete={(files) => sendImg(files[0], materialTypeId, close())}
      />
    );
  };

  const simMaterialsTypes = checkSimilarities();

  const imgSrc = materialType
    ? APIUrl.getMaterialTypeImg +
      materialType._id +
      "/" +
      materialType.name +
      "/" +
      Math.random() +
      "?token=" +
      APIUrl.jwtToken
    : null;

  const replaceImgBtn =
    materialType && materialType.file ? (
      <MenuButton
        icon="trash"
        variant="danger"
        onClick={() => deleteImg(materialType)}
        size="sm"
        hover={<FormattedMessage id="Image.Delete" />}
      />
    ) : (
      <MenuButton
        icon="upload"
        onClick={() => openUploadImgModal(materialType._id, imgSrc)}
        size="sm"
        hover={<FormattedMessage id="Image.Update" />}
      />
    );

  return (
      <Modal
        show={true}
        onHide={() => closeParentModal()}
        backdrop={"static"}
        size="xl"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <FormattedMessage id="Material.Type.Details" />
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <Tabs id="mt-tabs" mountOnEnter>
            <Tab
              eventKey="mt-general"
              title={<FormattedMessage id="General.Informations" />}
            >
              <Row className="m-3 justify-content-center">
                {materialType && (
                  <Col lg={4} xs={12}>
                    <Row className="justify-content-center">
                      <img
                        src={imgSrc}
                        className="m-3 img-thumbnail"
                        alt={"material_type_img" + Math.random()}
                        height="150"
                        width="150"
                      />
                    </Row>
                    <Row className="justify-content-center">
                      {replaceImgBtn}
                    </Row>
                  </Col>
                )}
                <Col lg={8} xs={12}>
                  <Row className="mt-3">
                    <CustomLabel
                      labelClassName="none"
                      label={intl.formatMessage({ id: "Name" })}
                      htmlFor="name"
                      required
                    />

                    <InputGroup hasValidation style={{ borderBottom: 0 }}>
                      <Form.Control
                        id="name"
                        type="text"
                        value={name}
                        onChange={(e) => onChange(e.target.value.toUpperCase())}
                        onBlur={(e) =>
                          update("name", e.target.value.toUpperCase())
                        }
                        isInvalid={nameError}
                        required
                      />
                      <Form.Control.Feedback type="invalid">
                        {nameError}
                      </Form.Control.Feedback>
                    </InputGroup>
                  </Row>
                </Col>
              </Row>
              {!materialType && (
                <Row className="justify-content-center mt-3">
                  <Col lg={8} xs={12}>
                    <CustomLabel
                      labelClassName="none"
                      label={intl.formatMessage({ id: "Image" })}
                      htmlFor="image"
                    />
                    <FileDropZone
                      onDropFile={(file, clearCallback) =>
                        onDropFile(file, clearCallback)
                      }
                      acceptedExtensions={["jpg", "png"]}
                      multiple={false}
                    />
                  </Col>
                </Row>
              )}
              <Row className="justify-content-center mt-3">
                {!materialType && simMaterialsTypes.length > 0 && (
                  <Alert variant="warning">
                    <FormattedMessage id="MaterialTypes.Similarities" /> :
                    <ul className="pb-0 mb-2">
                      {simMaterialsTypes.map((materialType) => (
                        <li key={nanoid(10)}>{materialType[0]}</li>
                      ))}
                    </ul>
                    <FormattedMessage id="MaterialTypes.Fix" />
                  </Alert>
                )}
              </Row>
            </Tab>
            <Tab
              eventKey="mt-checkpoints"
              title={<FormattedMessage id="Checkpoint" />}
              disabled={!materialType}
            >
              <MaterialsTypesModalCheckpoints materialType={materialType} />
            </Tab>
          </Tabs>
        </Modal.Body>

        {!materialType && (
          <Modal.Footer>
            <MenuButton variant="secondary" onClick={() => closeParentModal()}>
              <FormattedMessage id="Cancel" />
            </MenuButton>
            <MenuButton
              variant="info"
              onClick={() => add()}
              disabled={disabled || nameError || name === ""}
            >
              <FormattedMessage id="Add" />
            </MenuButton>
          </Modal.Footer>
        )}
        {modal}
      </Modal>
  );
}

const mapStateToProps = (state) => {
  return {
    materialsTypes: state.materialsTypes,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onAddMaterialsType: (data, successCallback) =>
      dispatch(addMaterialsType(data, successCallback)),
    onUpdateMaterialType: (data) => dispatch(updateMaterialType(data)),
    onAddMaterialTypeImg: (MaterialTypeId, data, successCallback) =>
      dispatch(addMaterialTypeImg(MaterialTypeId, data, successCallback)),
    onDeleteMaterialTypeImg: (materialTypeId, successCallback) =>
      dispatch(deleteMaterialTypeImg(materialTypeId, successCallback)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(MaterialsTypesModalAdmin));
