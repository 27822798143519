import React from "react";
import FileDropZone from "../../sub/FileDropZone";
import CustomLabel from "../../sub/CustomLabel";
import { FormattedMessage, injectIntl } from "react-intl";
import FileUtil from "../../../util/FileUtil";
import ApiURL from "../../../APIUrl";
import EqOwner from "../../../enums/EqOwner";
import { Button, Modal } from "react-bootstrap";
import Icon from "../../sub/Icon";

class EquipmentFileModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      disabled: false,
      file: null,
      formVisibility: true,
      progressBarVisibility: false,
    };
  }

  onDropFile(file) {
    this.setState({ file: file });
  }

  // Download dynamically generated Excel template from backend
  downloadTemplate() {
    let eqOwnerNode = {};

    Object.values(EqOwner).map((option) => {
      return (eqOwnerNode[option] = this.props.intl.formatMessage({
        id: "EqOwner." + option,
      }));
    });

    return FileUtil.dowloadFileHack(
      ApiURL.getTemplate,
      "template_equipements",
      "xlsx",
      { eqOwnerNode: eqOwnerNode }
    );
  }

  onComplete() {
    this.setState({
      disabled: true,
      formVisibility: false,
      progressBarVisibility: true,
    });

    this.props.onComplete(this.state.file);
  }

  disableButton() {
    return !this.state.file;
  }

  render() {
    const { closeModal, intl } = this.props;
    return (
      <Modal
        show={true}
        onHide={() => closeModal()}
        backdrop={"static"}
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <FormattedMessage id="Import.Equipments" />
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <h4 className="mb-5 w-100 text-center ">
            <FormattedMessage id="Step.1" />
          </h4>

          {this.state.progressBarVisibility && <div
            className="text-center mb-5 "
          >
            <Icon icon="gear" className="fa-spin text-success mb-3" size="3x" />
            <div className="progress" style={{ height: "30px" }}>
              <div
                className="progress-bar progress-bar-striped progress-bar-animated bg-success"
                role="progressbar"
                aria-valuenow="100"
                aria-valuemin="0"
                aria-valuemax="100"
                style={{ width: "100%" }}
              >
                <strong>
                  <FormattedMessage id="Import.Mercurial.In.Progress" />
                </strong>
              </div>
            </div>
          </div>}

          {this.state.formVisibility && <div>
            <CustomLabel
              label={intl.formatMessage({ id: "Import.File.Equipments" })}
              htmlFor="name"
              labelClassName="col-12"
              required
            />
            <FileDropZone
              onDropFile={(file) => this.onDropFile(file)}
              acceptedExtensions={["xls", "xlsx"]}
              multiple={false}
            />

            <div className="text-center">
              <button
                className="btn btn-info text-center mt-3"
                onClick={() => this.downloadTemplate()}
              >
                <Icon icon="file-excel" className="mr-2" />
                <FormattedMessage id="Mercurials.Download.Template" />
              </button>
            </div>
          </div>}
        </Modal.Body>

        <Modal.Footer>
          <Button variant="secondary" onClick={() => closeModal()}>
            <FormattedMessage id="Cancel" />
          </Button>
          <Button
            variant="info"
            onClick={() => this.onComplete()}
            disabled={this.disableButton()}
          >
            <FormattedMessage
              id={
                !this.state.progressBarVisibility
                  ? "Step.2"
                  : "Plz.Wait"
              }
            />
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}

export default injectIntl(EquipmentFileModal);
