import { GET_MATERIOVIGILANCE_EQUIPEMENT } from "../../../actions/settings/materiovigilance/materiovigilance";

export default function materiovigilance(state = [], action) {
  switch (action.type) {
    case GET_MATERIOVIGILANCE_EQUIPEMENT:
      return action.materiovigilance;
    default:
      return state;
  }
}
