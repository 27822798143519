import React from "react";
import { connect } from "react-redux";
import { FormattedMessage } from "react-intl";
import { deleteMaterialType } from "../../../../../actions/settings/materialsTypes/materialsTypes";
import Icon from "../../../../sub/Icon";
import ActionMenu from "../../../../sub/ActionMenu";

function MaterialTypeAdminRow({
  materialType,
  openEditModal,
  openConfModal,
  onDeleteMaterialType,
}) {
  const deleteMaterialType = (materialId) => {
    if (!materialId) return;
    onDeleteMaterialType({ materialId: materialId });
  };

  const modalTitle = <FormattedMessage id="Confirm" />;
  const modalContent = (
    <React.Fragment>
      <FormattedMessage id="Material.Type.Remove.Confirmation" />
      <br />
      <br />
      <div className="card text-white bg-danger">
        <div className="card-header">
          <Icon icon="triangle-exclamation" className="mr-2 text-white" />
          <FormattedMessage id="Warning" />
        </div>
        <div className="card-body">
          <p className="card-text">
            <FormattedMessage id="MaterialType.Delete.Warning.Content" />
          </p>
        </div>
      </div>
    </React.Fragment>
  );

  const successCallback = () => deleteMaterialType(materialType._id);

  const menuItems = [];

  menuItems.push(
    {
      icon: "pen-to-square",
      action: (e) => openEditModal(materialType),
      text: <FormattedMessage id="Modify" />,
    },
    {
      icon: "trash",
      action: (e) => openConfModal(modalTitle, modalContent, successCallback),
      text: <FormattedMessage id="Delete" />,
    }
  );

  const menuAction = <ActionMenu items={menuItems} />;

  return (
    <tr key={materialType._id} className="d-flex">
      <td className="col">{materialType.name}</td>
      <td className="col col-1 text-center">{menuAction}</td>
    </tr>
  );
}

const mapStateToProps = (state) => {
  return {
    //
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onDeleteMaterialType: (data) => dispatch(deleteMaterialType(data)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MaterialTypeAdminRow);
