import React from "react";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import ProductsBudget from "./ProductsBudget";
import OverTimeBudget from "./OverTimeBudget";
import TopProductsBudget from "./TopProductsBudget";

import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";

class Budget extends React.Component {
  render() {
    return (
      <Tabs defaultActiveKey="categories" id="budgetTabs">
        <Tab
          eventKey="categories"
          title={this.props.intl.formatMessage({ id: "Categories" })}
        >
          <ProductsBudget orders={this.props.orders} />
        </Tab>
        <Tab
          eventKey="products"
          title={this.props.intl.formatMessage({ id: "Products" })}
        >
          <TopProductsBudget orders={this.props.orders} />
        </Tab>
        <Tab
          eventKey="budgets"
          title={this.props.intl.formatMessage({ id: "Budgets" })}
        >
          <OverTimeBudget
            orders={this.props.orders}
            establishmentSettings={this.props.establishmentSettings}
          />
        </Tab>
      </Tabs>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    orders: state.orders,
    establishmentSettings: state.establishmentSettings,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    //
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(Budget));
