import { GET_EQUIPMENTS_REQUESTS } from "../../actions/equipmentsRequest/equipmentsRequest";

export default function equipmentsRequest(state = [], action) {
  switch (action.type) {
    case GET_EQUIPMENTS_REQUESTS:
      return action.equipments_requests;
    default:
      return state;
  }
}
