import React from "react";
import { connect } from "react-redux";
import { FormattedMessage } from "react-intl";
import { withRouter } from "react-router-dom";
import { logout } from "../../../actions/authentication/authentication";
import ActionMenu from "../../sub/ActionMenu";
import Util from "../../../util/Util";
import { getTokenOnlineHelp } from "../../../actions/settings/onlineHelp/onlineHelp";
import Roles from "../../../enums/Roles";

class MenuProfile extends React.Component {
  logout(e) {
    e.preventDefault();
    e.stopPropagation();

    // let that = this;

    function onLogoutSuccess() {
      // that.props.history.push('/');
      return window.location.replace(Util.checkHttps(process.env.REACT_APP_LOGIN_URL));
    }

    // Call logout and redirect on success
    this.props.onLogout(onLogoutSuccess);
  }


  openOnlineHelp() {
    if (this.props.user.role === Roles.ADMIN || this.props.user.role === Roles.SALES_REP) {
      this.props.onGetTokenOnlineHelp(
        { data: process.env.REACT_APP_KB_ENDPOINT_PSDM },
        (e) => { window.open(e.link, '_blank').focus(); }
      );
    } else {
      this.props.onGetTokenOnlineHelp(
        { data: process.env.REACT_APP_KB_ENDPOINT_CLIENT },
        (e) => { window.open(e.link, '_blank').focus(); }
      );
    }
  }

  render() {
    let menuItems = [];

    menuItems.push({
      icon: "pen-to-square",
      href: "#/home/account",
      text: <FormattedMessage id="My.Account" />,
    });

    /*
    menuItems.push({
      icon: "circle-question",
      action: () => this.openOnlineHelp(),
      text: <FormattedMessage id="Online.Help" />,
    });
    */

    menuItems.push({
      icon: "circle-info",
      href: "#/home/changelog",
      disabled: (this.props.user.role === Roles.CLIENT) && true,
      text: "v" + process.env.REACT_APP_VERSION,
    });

    menuItems.push({
      icon: "power-off",
      action: (e) => this.logout(e),
      text: <FormattedMessage id="Disconnect" />,
    });

    const menuAction = <ActionMenu
      items={menuItems}
      icon="user"
      size="2xl"
      header={this.props.user.first_name + " " + this.props.user.name}
    />;

    return menuAction;
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.user,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onLogout: (successCallback) => dispatch(logout(successCallback)),
    onGetTokenOnlineHelp: (endpoint, successCallback) => dispatch(getTokenOnlineHelp(endpoint, successCallback)),
  };
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(MenuProfile)
);
