export default class DateUtil {
  static toDate(date) {
    date = new Date(date);
    let dd = date.getDate();
    let MM = date.getMonth() + 1;
    const yyyy = date.getFullYear();

    if (dd < 10) dd = `0${dd}`;
    if (MM < 10) MM = `0${MM}`;

    return `${dd}/${MM}/${yyyy}`;
  }

  static toyyyyMMdd(date) {
    if (!date) return "";

    var inputDate = new Date(date);

    var year = inputDate.getFullYear();
    var month = inputDate.getMonth() + 1;
    var day = inputDate.getDate();

    if (month < 10) month = "0" + month;
    if (day < 10) day = "0" + day;

    if (year.toString().length === 1) {
      year = "000" + year.toString();
    }
    if (year.toString().length === 2) {
      year = "00" + year.toString();
    }
    if (year.toString().length === 3) {
      year = "0" + year.toString();
    }

    var formattedDate = `${year}-${month}-${day}`;

    return formattedDate;
  }

  static toDateWithHour(date) {
    date = new Date(date);
    let dd = date.getDate();
    let MM = date.getMonth() + 1;
    const yyyy = date.getFullYear();

    if (dd < 10) dd = `0${dd}`;
    if (MM < 10) MM = `0${MM}`;

    let hh = date.getHours();
    let mm = date.getMinutes();

    if (mm < 10) mm = `0${mm}`;

    return `${dd}/${MM}/${yyyy} ${hh}:${mm}`;
  }

  static getDateWithOffset(date, offsetDays, displayHour = false) {
    if (!date) return "-";
    const offset = offsetDays * 24 * 3600 * 1000;
    const newDate = new Date(new Date(date).getTime() + offset);

    return displayHour ? this.toDateWithHour(newDate) : this.toDate(newDate);
  }
}
