import React from "react";
import { connect } from "react-redux";
import Users from "./Users";
import { FormattedMessage } from "react-intl";
import MercurialStatus from "../../enums/MercurialStatus";
import ActionMenu from "../sub/ActionMenu";
import MenuButton from "../sub/bootstrap/MenuButton";

class EstablishmentsRow extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      collapsed: true,
      modal: null,
    };
  }

  getMercurialName(establishmentId) {
    var settings = null;
    for (let s of this.props.establishmentsSettings) {
      if (s.establishment_id === establishmentId) {
        settings = s;
        break;
      }
    }

    // If no settings, or mercurial wasn't defined yet
    if (!settings || !settings.mercurial_id) return null;

    for (let m of this.props.mercurials) {
      if (
        m._id === settings.mercurial_id &&
        m.status === MercurialStatus.ACTIVE
      ) {
        let version = "";
        if (m.version !== 1) version = "(v" + m.version + ")";
        return m.name + " " + version;
      }
    }

    return "";
  }

  collapse() {
    this.setState({
      collapsed: !this.state.collapsed,
    });
  }

  getRep(establishmentId) {
    for (let collaborator of this.props.collaborators) {
      if (
        !collaborator.establishments ||
        collaborator.establishments.length === 0
      )
        continue;

      for (let establishment of collaborator.establishments) {
        if (establishment.establishment_id === establishmentId)
          return collaborator;
      }
    }
  }

  render() {
    var establishment = this.props.establishment;

    var rep = this.getRep(this.props.establishment._id);
    var repName = "";
    if (rep) repName = rep.first_name + " " + rep.name;

    // https://stackoverflow.com/questions/37308719/react-component-wait-for-required-props-to-render
    // https://zaiste.net/posts/javascript-destructuring-assignment-default-values/
    const { enabled: crmEnabled = false, software: crmSoftware = null } = this
      .props.company.crm
      ? this.props.company.crm
      : {};

    let menuItems = [];

    menuItems.push(
      // edit establishment
      {
        icon: "pen-to-square",
        action: (e) => this.props.openModal(establishment),
        text: <FormattedMessage id="Modify" />
      },
      // show users
      {
        icon: this.state.collapsed ? "eye" : "eye-slash",
        action: (e) => this.collapse("client-" + establishment._id),
        text: this.state.collapsed ? (<FormattedMessage id="Display.User" />) : (<FormattedMessage id="Hide.User" />)
      },
    );

    let menuAction = (
      <ActionMenu
        items={menuItems}
      />
    );

    return (
      <React.Fragment>
        <tr key={establishment._id}>
          <td className="align-middle">{establishment.name.toUpperCase()}</td>
          <td className="align-middle">
            {establishment.address} {establishment.postal_code}{" "}
            {establishment.city}
          </td>
          <td className="align-middle">
            {this.getMercurialName(establishment._id)}
          </td>
          <td className="align-middle">{repName} </td>
          {crmEnabled && (
            <td className="align-middle text-center">
              <MenuButton
                icon={establishment.crm_id
                  ? "circle-check"
                  : "circle-xmark"}
                variant={
                  establishment.crm_id
                    ? "outline-success"
                    : "outline-danger"
                }
                onClick={(e) => this.props.displayAPIModal(establishment)}
                hover={<FormattedMessage
                  id={
                    establishment.crm_id
                      ? "API.CRM.Sync.Ok"
                      : "API.CRM.Sync.Nok"
                  }
                  values={{ crmSoftware: crmSoftware }}
                />}
              />
            </td>
          )}
          {!this.props.limit && (
            <td className="align-middle text-center">
              {menuAction}
            </td>
          )}
        </tr>

        {!this.state.collapsed && <tr>
          <td colSpan="8" className="p-1">
            {this.props.clients.length > 0 ? (
              <Users clients={this.props.clients} cssStyle={"table m-0"} />
            ) : (
              <div className="alert alert-secondary m-0" role="alert">
                <FormattedMessage id="Empty.Users" />
              </div>
            )}
          </td>
        </tr>}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    establishmentsSettings: state.establishmentsSettings,
    mercurials: state.mercurials,
    products: state.products,
    oldProducts: state.oldProducts,
    orders: state.orders,
    requests: state.requests,
    equipments: state.equipments,
    collaborators: state.collaborators,
    user: state.user,
    company: state.company,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(EstablishmentsRow);
