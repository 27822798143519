import APIUrl from "../../../APIUrl";
import axios from 'axios';

export const getTokenOnlineHelp = function (data, successCallback) {
    return function (dispatch) {
        return axios.post(APIUrl.getTokenOnlineHelp, data)
            .then(function (response) {
                return successCallback(response.data);
            })
            .catch(function (err) {
                throw (err);
            });
    };
};