import React from "react";
import { FormattedMessage } from "react-intl";
import OrderProductRowAdmin from "./OrderProductRowAdmin";

class OrderProductsAdmin extends React.Component {
  render() {
    let key = 0;
    let productNode = this.props.order.products.map((product) => {
      if (product.length === 0) return null;
      return (
        <OrderProductRowAdmin
          key={++key + "-" + product.id_product}
          keyRow={++key + "-" + product.id_product}
          order={this.props.order}
          product={product}
          onClickCallback={this.props.onClickCallback}
        />
      );
    });

    return (
      <table id="bootstrap-data-table-export" className="table tablee4coll m-0">
        <thead>
          <tr>
            <th scope="col">
              <FormattedMessage id="Reference" />
            </th>
            <th scope="col">
              <FormattedMessage id="Designation" />
            </th>
            <th scope="col">
              <FormattedMessage id="Quantity" />
            </th>
            <th scope="col">
              <FormattedMessage id="Unit.Price.Excl.Tax" />
            </th>
            <th scope="col">
              <FormattedMessage id="Total.Excl.Tax" />
            </th>
            <th scope="col" className="text-center">
              <FormattedMessage id="Product.Unavailable" />
            </th>
            <th scope="col" className="text-center">
              <FormattedMessage id="Product.Available" />
            </th>
          </tr>
        </thead>
        <tbody>{productNode}</tbody>
      </table>
    );
  }
}

export default OrderProductsAdmin;
