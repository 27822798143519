import React, { useState } from "react";
import FileDropZone from "../sub/FileDropZone";
import Icon from "../sub/Icon";
import { FormattedMessage } from "react-intl";
import { Button, Modal } from "react-bootstrap";

interface MercurialUpdModalProps {
  closeModal: () => void;
  onComplete: (mercurial: any, file: File | null) => void;
  mercurial: any;
}

const MercurialUpdModal: React.FC<MercurialUpdModalProps> = (props) => {
  const [state, setState] = useState({
    disabled: false,
    file: null,
  });

  const onDropFile = (file: any) => {
    setState({ ...state, file: file });
  };

  const onComplete = () => {
    setState({ ...state, disabled: true });
    props.onComplete(props.mercurial, state.file);
  };

  const disableButton = () => {
    return !state.file || state.disabled;
  };

  return (
    <div className="modal-bg show">
      <Modal show={true} onHide={props.closeModal} backdrop="static" size="lg">
        <Modal.Header closeButton>
          <Modal.Title>
            <FormattedMessage id="Mercurials.Update" />
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <h4 className="mb-3 w-100 text-center">
            <FormattedMessage id="Upload.New.File" />
          </h4>
          <p className="mb-3 w-100 text-center font-weight-light">
            <FormattedMessage id="Mercurials.Update.Upload.Info" />
          </p>

          <div className="card text-white bg-warning  mb-3">
            <div className="card-header">
              <Icon icon="triangle-exclamation" className="mr-2" />
              <FormattedMessage id="Mercurials.Update.Warning.Title" />
            </div>
            <div className="card-body">
              <p className="card-text">
                <FormattedMessage id="Mercurials.Update.Warning.Content" />
              </p>
            </div>
          </div>

          <FileDropZone
            onDropFile={(file: any) => onDropFile(file)}
            acceptedExtensions={["xls", "xlsx"]}
            multiple={false}
          />
        </Modal.Body>

        <Modal.Footer>
          <Button variant="secondary" onClick={props.closeModal}>
            <FormattedMessage id="Cancel" />
          </Button>
          <Button
            variant="info"
            onClick={onComplete}
            disabled={disableButton()}
          >
            <FormattedMessage id="Update" />
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default MercurialUpdModal;
