var OrderStatus = {
  TBV: 10, // A valider
  NEW: 0, // Nouvelle
  PENDING: 1, // En préparation
  SENDING: 6, // En cours de livraison
  SENT: 2, // Livrée
  // RECEIVED: 3,
  BLOCKED: 4, // En reliquat
  CANCELED: 5,   // Annulée
};

module.exports = OrderStatus;
