import APIUrl from "../../APIUrl";
import axios from "axios";
import { getEstablishmentsSettings } from "./establishmentSettings";

export const GET_ESTABLISHMENTS = "GET_ESTABLISHMENTS";

function getEstablishmentsAction(establishments) {
  return { type: GET_ESTABLISHMENTS, establishments: establishments };
}

export const getEstablishments = function () {
  return function (dispatch) {
    return axios
      .get(APIUrl.getEstablishments)
      .then(function (response) {
        dispatch(getEstablishmentsAction(response.data));
      })
      .catch(function (err) {
        throw err;
      });
  };
};

export const getEstablishment = function (establishmentId, successCallback) {
  return function (dispatch) {
    return axios
      .post(APIUrl.getEstablishmentById, { establishmentId })
      .then(function (response) {
        return successCallback(response.data);
      })
      .catch(function (err) {
        throw err;
      });
  };
};

export const addEstablishment = function (data, successCallback) {
  return function (dispatch) {
    return axios
      .post(APIUrl.addEstablishment, data)
      .then(function (response) {
        dispatch(getEstablishments());
        dispatch(getEstablishmentsSettings());

        successCallback(response.data);
      })
      .catch(function (err) {
        throw err;
      });
  };
};

export const updateEstablishment = function (data, successCallback) {
  return function (dispatch) {
    return axios
      .post(APIUrl.updateEstablishment, data)
      .then(function (response) {
        dispatch(getEstablishments());

        // Optional callback
        if (successCallback) successCallback(response.data);
      })
      .catch(function (err) {
        throw err;
      });
  };
};
