import React from "react";
import { Route, Switch } from "react-router-dom";
import { NotificationContainer } from "react-notifications";
import Families from "../families/Families";
import FamiliesAdmin from "../families/FamiliesAdmin";
import Products from "../products/Products";
import Cart from "../cart/Cart";
import Orders from "../orders/Orders";
import OrdersAdmin from "../orders/admin/OrdersAdmin";
import MenuSettingsAdmin from "../settings/admin/MenuSettingsAdmin";
import { connect } from "react-redux";
import { getUser } from "../../actions/user/user";
import { getRgpd } from "../../actions/rgpd/rgpd";
// import { getProducts, getProductsAdmin } from "../../actions/products/products";
import { getCart } from "../../actions/cart/cart";
import { getOrders, getOrdersAdmin } from "../../actions/orders/orders";
import { getClientsAdmin, getClients } from "../../actions/clients/clients";
import { getEstablishments } from "../../actions/establishments/establishments";
import { getEstablishment } from "../../actions/establishment/establishment";
import { getOrdersSettings } from "../../actions/settings/ordersSettings/ordersSettings";
import { getOrdersSettingsAdmin } from "../../actions/settings/ordersSettings/admin/ordersSettings";
import { getRequests, getAllRequests } from "../../actions/requests/requests";
import { getPaymentMethods } from "../../actions/configurables/paymentMethods";
import MenuAdmin from "./Menus/MenuAdmin";
import MenuSalesRep from "./Menus/MenuSalesRep";
import Menu from "./Menus/Menu";
import Roles from "../../enums/Roles";
import NotificationSubject from "../../enums/NotificationSubject";
import { FormattedMessage } from "react-intl";
import ModalManager from "../sub/modals/ModalManager";
import {
  getNotifs,
  deleteNotif,
} from "../../actions/notifications/notifications";
import { getMaterialsTypes } from "../../actions/settings/materialsTypes/materialsTypes";
import SplitHomeAdmin from "./SplitHomeAdmin";
import SplitHome from "./SplitHome";
import Establishments from "../clients/Establishments";
import Requests from "../requests/Requests";
import RequestsAdmin from "../requests/admin/RequestsAdmin";
import Estimates from "../estimates/Estimates";
import {
  getMercurialsAdmin,
  getMercurial,
} from "../../actions/mercurials/mercurials";
import Fleet from "../fleet/Fleet";
import FleetAdmin from "../fleet/admin/FleetAdmin";
import {
  getEstablishmentsSettings,
  getEstablishmentSettings,
} from "../../actions/establishments/establishmentSettings";
import { getEquipmentsRequest } from "../../actions/equipmentsRequest/equipmentsRequest";
import { getEquipments } from "../../actions/equipments/equipments";
import { getResidents } from "../../actions/residents/residents";
import { getBrands } from "../../actions/configurables/brands";
import { getModels } from "../../actions/configurables/models";
import { getAllResidents } from "../../actions/residents/residents";
import { getCheckpoints } from "../../actions/checkpoints/checkpoints";
import { getCollaboratorTypes } from "../../actions/configurables/collaboratorTypes";
import { getGeneralSettings } from "../../actions/settings/generalSettings/generalSettings";
import { getCollaborators } from "../../actions/collaborators/collaborators";
import { getCompany } from "../../actions/company/company";
import { getAdmin } from "../../actions/admin/admin";
import RgpdModal from "../rgpd/RgpdModal";
import Budget from "../budget/Budget";
import BudgetAdmin from "../budget/admin/BudgetAdmin";
import Account from "../account/Account";
import {
  getPromoOperations,
  getPromoOperationsAdmin,
} from "../../actions/promoOperations/promoOperations";
import { getMateriovigilanceEquipement } from "../../actions/settings/materiovigilance/materiovigilance";
import Mercurials from "../mercurials/Mercurials";
import Changelog from "../changelog/Changelog";

class Homepage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      rgpdModal: null,
    };
  }

  closeRgpdModal() {
    this.setState({ rgpdModal: null });
  }

  componentDidMount() {
    Promise.all([
      // First, fetch the user
      this.props.onGetRgpd(),
      this.props.onGetUser(),
    ]).then(() => {
      // Next things to fetch
      if (!this.props.rgpd)
        this.setState({
          rgpdModal: <RgpdModal closeRgpdModal={() => this.closeRgpdModal()} />,
        });

      this.props.onGetCart();
      this.props.onGetEstablishment();
      this.props.onGetAllEquipmentsRequest();
      this.props.onGetNotifs();
      this.props.onGetMaterialsTypes();
      this.props.onGetBrands();
      this.props.onGetModels();
      this.props.onGetCheckpoints();
      this.props.onGetCollaboratorTypes();
      this.props.onGetGeneralSettings();
      this.props.onGetPaymentMethods();
      this.props.onGetMateriovigilanceEquipement();

      // Client specific
      if (this.props.user.role === Roles.CLIENT) {
        // this.props.onGetProducts();
        this.props.onGetOrders();
        this.props.onGetOrdersSettings();
        this.props.onGetRequests();
        this.props.onGetEquipments();
        this.props.onGetResidents();
        this.props.onGetEstablishmentSettings();
        this.props.onGetMercurial();
        this.props.onGetAdmin();
        this.props.onGetClients();
        this.props.onGetPromoOperations();
        // this.props.onGetMateriovigilanceEquipement();
      }

      // Admin & Sales rep
      if (
        this.props.user.role === Roles.SALES_REP ||
        this.props.user.role === Roles.ADMIN
      ) {
        // this.props.onGetProductsAdmin();
        this.props.onGetCompany();
        this.props.onGetOrdersAdmin();
        this.props.onGetClientsAdmin();
        this.props.onGetEstablishments();
        this.props.onGetMercurials();
        this.props.onGetAllRequests();
        this.props.onGetEstablishmentsSettings();
        this.props.onGetAllResidents();
        this.props.onGetOrdersSettingsAdmin();
        // this.props.onGetMateriovigilanceEquipement();
      }

      // Admin specific
      if (this.props.user.role === Roles.ADMIN) {
        this.props.onGetCollaborators();
        this.props.onGetPromoOperationsAdmin();
      }
    });
  }

  closeNotifModal(notif) {
    this.props.onDeleteNotif({ notifId: notif._id });
  }

  render() {
    var ordersSettings;
    if (
      this.props.ordersSettings.length === 0 &&
      this.props.user.role === Roles.CLIENT
    )
      return null;
    if (
      this.props.ordersSettings.length !== 0 &&
      this.props.user.role === Roles.CLIENT
    )
      ordersSettings = this.props.ordersSettings;

    var totalProduct = 0;
    for (var p of this.props.cart) totalProduct += p.quantity;

    var menu = (
      <Menu ordersSettings={ordersSettings} totalProduct={totalProduct} />
    );
    var switchComponent = <SwitchComponent />;

    if (this.props.user.role === Roles.SALES_REP) {
      menu = <MenuSalesRep totalProduct={totalProduct} />;
      switchComponent = <SwitchComponentSalesRep />;
    }

    if (this.props.user.role === Roles.ADMIN) {
      menu = <MenuAdmin totalProduct={totalProduct} />;
      switchComponent = <SwitchComponentAdmin />;
    }

    let notifModals = [];
    if (this.props.notifications.length !== 0) {
      for (let notif of this.props.notifications) {
        if (notif.subject === NotificationSubject.MERCURIAL_DELETE_OR_UPDATE) {
          let title = (
            <FormattedMessage id="Notification.Mercurial.Upd.Title" />
          );
          let content = (
            <FormattedMessage id="Notification.Mercurial.Upd.Content" />
          );
          notifModals.push(
            <ModalManager
              showModal={true}
              key={notif._id}
              title={title}
              content={content}
              successCallback={() => this.closeNotifModal(notif)}
              closeModal={() => this.closeNotifModal(notif)}
              modalType="notification"
            />
          );
        }
      }
    }

    return (
      <React.Fragment>
        {menu}

        <div className="container-fluid p-3">{switchComponent}</div>

        {notifModals}

        {this.state.rgpdModal}
        <NotificationContainer />
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    rgpd: state.rgpd,
    user: state.user,
    cart: state.cart,
    notifications: state.notifications,
    ordersSettings: state.ordersSettings,
    materiovigilance: state.materiovigilance,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onGetUser: () => dispatch(getUser()),
    onGetRgpd: () => dispatch(getRgpd()),
    onGetCart: () => dispatch(getCart()),
    // onGetProducts: () => dispatch(getProducts()),
    // onGetProductsAdmin: () => dispatch(getProductsAdmin()),
    onGetOrders: () => dispatch(getOrders()),
    onGetNotifs: () => dispatch(getNotifs()),
    onDeleteNotif: (data) => dispatch(deleteNotif(data)),
    onGetOrdersSettings: () => dispatch(getOrdersSettings()),
    onGetRequests: () => dispatch(getRequests()),
    onGetMaterialsTypes: () => dispatch(getMaterialsTypes()),
    onGetMercurial: () => dispatch(getMercurial()),
    onGetEquipments: () => dispatch(getEquipments()),
    onGetAllEquipmentsRequest: () => dispatch(getEquipmentsRequest()),
    onGetResidents: () => dispatch(getResidents()),
    onGetBrands: () => dispatch(getBrands()),
    onGetCollaboratorTypes: () => dispatch(getCollaboratorTypes()),
    onGetModels: () => dispatch(getModels()),
    onGetCheckpoints: () => dispatch(getCheckpoints()),
    onGetEstablishmentSettings: () => dispatch(getEstablishmentSettings()),
    onGetEstablishment: () => dispatch(getEstablishment()),
    onGetAdmin: () => dispatch(getAdmin()),
    onGetGeneralSettings: () => dispatch(getGeneralSettings()),
    onGetClients: () => dispatch(getClients()),
    onGetPaymentMethods: () => dispatch(getPaymentMethods()),
    onGetPromoOperations: () => dispatch(getPromoOperations()),
    onGetMateriovigilanceEquipement: () =>
      dispatch(getMateriovigilanceEquipement()),

    // ADMIN & SALES_REP
    onGetOrdersAdmin: () => dispatch(getOrdersAdmin()),
    onGetClientsAdmin: () => dispatch(getClientsAdmin()),
    onGetEstablishments: () => dispatch(getEstablishments()),
    onGetMercurials: () => dispatch(getMercurialsAdmin()),
    onGetAllRequests: () => dispatch(getAllRequests()),
    onGetEstablishmentsSettings: () => dispatch(getEstablishmentsSettings()),
    onGetAllResidents: () => dispatch(getAllResidents()),
    onGetCompany: () => dispatch(getCompany()),

    // ADMIN only
    onGetOrdersSettingsAdmin: () => dispatch(getOrdersSettingsAdmin()),
    onGetCollaborators: () => dispatch(getCollaborators()),
    onGetPromoOperationsAdmin: () => dispatch(getPromoOperationsAdmin()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Homepage);

class SwitchComponent extends React.Component {
  render() {
    return (
      <Switch>
        <Route path="/home/budget" component={Budget} />
        <Route path="/home/account" component={Account} />
        <Route path="/home/requests" component={Requests} />
        <Route path="/home/estimates" component={Estimates} />
        <Route path="/home/families" component={Families} />
        <Route
          path="/home/products/mercurial/:mercurialId/:famille/:product"
          component={Products}
        />
        <Route
          path="/home/products/mercurial/:mercurialId/:famille"
          component={Products}
        />
        <Route path="/home/cart" component={Cart} />
        <Route path="/home/orders" component={Orders} />
        <Route path="/home/fleet" component={Fleet} />
        <Route path="/home" component={SplitHome} />
      </Switch>
    );
  }
}

class SwitchComponentSalesRep extends React.Component {
  render() {
    return (
      <Switch>
        <Route path="/home/budget" component={BudgetAdmin} />
        <Route path="/home/account" component={Account} />
        <Route path="/home/requests" component={RequestsAdmin} />
        <Route path="/home/estimates" component={Estimates} />
        <Route path="/home/families" component={FamiliesAdmin} />
        <Route
          path="/home/products/mercurial/:mercurialId/:famille/:product"
          component={Products}
        />
        <Route
          path="/home/products/mercurial/:mercurialId/:famille"
          component={Products}
        />
        <Route path="/home/cart" component={Cart} />
        <Route path="/home/orders" component={OrdersAdmin} />
        <Route path="/home/fleet" component={FleetAdmin} />
        <Route path="/home/settings" component={MenuSettingsAdmin} />
        <Route path="/home/clients" component={Establishments} />
        <Route path="/home/changelog" component={Changelog} />
        <Route path="/home" component={SplitHomeAdmin} />
      </Switch>
    );
  }
}

class SwitchComponentAdmin extends React.Component {
  render() {
    return (
      <Switch>
        <Route path="/home/budget" component={BudgetAdmin} />
        <Route path="/home/account" component={Account} />
        <Route path="/home/requests" component={RequestsAdmin} />
        <Route path="/home/estimates" component={Estimates} />
        <Route path="/home/families/:mercurialId" component={FamiliesAdmin} />
        <Route path="/home/mercuriales" component={Mercurials} />
        <Route
          path="/home/products/mercurial/:mercurialId/:famille/:product"
          component={Products}
        />
        <Route
          path="/home/products/mercurial/:mercurialId/:famille"
          component={Products}
        />
        <Route path="/home/cart" component={Cart} />
        <Route path="/home/orders" component={OrdersAdmin} />
        <Route path="/home/fleet" component={FleetAdmin} />
        <Route path="/home/settings" component={MenuSettingsAdmin} />
        <Route path="/home/clients" component={Establishments} />
        <Route path="/home/changelog" component={Changelog} />
        <Route path="/home" component={SplitHomeAdmin} />
      </Switch>
    );
  }
}
