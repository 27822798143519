import React from "react";
import { connect } from "react-redux";
import BrandsTableAdmin from "./BrandsAdminTable";

class BrandsAdmin extends React.Component {
  render() {
    return (
      <React.Fragment>
        <BrandsTableAdmin
          equipments={this.props.equipments}
          brands={this.props.brands}
        />
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.user,
    equipments: state.equipments,
    brands: state.brands,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    //
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(BrandsAdmin);
