import APIUrl from "../../APIUrl";
import axios from "axios";

export const GET_PAYMENT_DEADLINES = "GET_PAYMENT_DEADLINES";

function getPaymentDeadlinesAction(paymentDeadlines) {
  return { type: GET_PAYMENT_DEADLINES, paymentDeadlines: paymentDeadlines };
}

export const getPaymentDeadlines = function () {
  return function (dispatch) {
    return axios
      .get(APIUrl.getPaymentDeadlines)
      .then(function (response) {
        dispatch(getPaymentDeadlinesAction(response.data));
      })
      .catch(function (err) {
        throw err;
      });
  };
};

export const addPaymentDeadline = function (data, successCallback) {
  return function (dispatch) {
    return axios
      .post(APIUrl.addPaymentDeadline, data)
      .then(function (response) {
        dispatch(getPaymentDeadlines());
        successCallback(response.data);
      })
      .catch(function (err) {
        throw err;
      });
  };
};
