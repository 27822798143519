import React from "react";
import { connect } from "react-redux";
import { FormattedMessage, injectIntl } from "react-intl";
import Util from "../../util/Util";
import RequestTypes from "../../enums/RequestTypes";
import EstimateStatus from "../../enums/EstimateStatus";
import {
  addRequestFiles,
  createRequest,
  createRequestAdmin,
  deleteRequestFile,
  updateRequest,
} from "../../actions/requests/requests";
import CustomLabel from "../sub/CustomLabel";
import Roles from "../../enums/Roles";
import { Alert, Button, Modal } from "react-bootstrap";
import CustomFileDropZone from "../sub/CustomFileDropZone";
import APIUrl from "../../APIUrl";
import TextareaCounter from 'react-textarea-counter';

class EstimateModalAdmin extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      client_id: "",
      precisions: "",
      disabled: false,
      disabledInputs: false,
    };

    if (this.props.request) {
      this.state = {
        precisions: this.props.request.precisions,
        response: this.props.request.response,
      };
    }
  }

  create() {
    if (this.hasEmptyField() || this.state.disabled) return;

    let data = {
      client_id: this.state.client_id,
      request_type: RequestTypes.NEW_MATERIAL,
      precisions: this.state.precisions,
      status: EstimateStatus.PENDING,
    };

    this.setState({ disabled: true });
    if (this.props.user.role === Roles.CLIENT) {
      this.props.onCreateRequest(data, () => this.props.close());
    } else {
      this.props.onCreateRequestAdmin(data, () => this.props.close());
    }
  }

  hasEmptyField() {
    if (this.props.user.role === Roles.CLIENT) {
      return Util.emptyString(this.state.precisions);
    } else {
      return (
        Util.emptyString(this.state.client_id) ||
        Util.emptyString(this.state.precisions)
      );
    }
  }

  onChange(key, value) {
    this.setState({ [key]: value });
  }

  update(request) {
    let response = this.state.response;

    let data = {
      requestId: request._id,
      updatedField: "response",
      updatedValue: response,
    };

    this.props.onUpdateRequest(data, () => { });
  }

  render() {
    if (this.props.user.role !== Roles.CLIENT) {
      var clientsSelectOptions = null;
      if (this.props.clients.length > 0) {
        clientsSelectOptions = this.props.clients.map((client) => {
          return (
            <option key={client._id} value={client._id}>
              {client.first_name} {client.name}
            </option>
          );
        });
      }
    }

    return (
      <Modal
        show={true}
        onHide={() => this.props.close()}
        backdrop={"static"}
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <FormattedMessage
              id={!this.props.request ? "Create.Request" : "Estimate.Request"}
            />
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          {!this.props.request && this.props.user.role !== Roles.CLIENT && (
            <div className="form-group row">
              <CustomLabel
                label={this.props.intl.formatMessage({ id: "Client" })}
                htmlFor="clientId"
                labelClassName="col-12 col-sm-5"
                required
              />
              <div className="col-12 col-sm-7">
                <select
                  id="clientId"
                  className="form-control"
                  value={this.state.client_id}
                  onChange={(e) => this.onChange("client_id", e.target.value)}
                  disabled={this.state.disabledInputs}
                >
                  <option value=""></option>
                  {clientsSelectOptions}
                </select>
              </div>
            </div>
          )}

          <div className="form-group row">
            <CustomLabel
              label={this.props.intl.formatMessage({ id: "Precisions" })}
              htmlFor="precisions"
              labelClassName="col-12 col-sm-5"
              required
            />
            <div className="col-12 col-sm-7">
              <TextareaCounter
                countLimit={1000}
                className="textareaCounter"
                id="precisions"
                value={this.state.precisions}
                onChange={(e) => this.onChange("precisions", e.target.value)}
                disabled={this.state.disabledInputs || this.props.request}
              />
            </div>
          </div>

          {this.props.request && (
            <>
              <hr />
              <CustomFileDropZone
                files={this.props.request.files}
                data={this.props.request}
                userRole={this.props.user.role}
                addFiles={(requestId, data, successCallback) =>
                  this.props.onaddRequestFiles(
                    requestId,
                    data,
                    successCallback
                  )
                }
                deleteFile={(data, successCallback) =>
                  this.props.ondeleteRequestFile(data, successCallback)
                }
                url={APIUrl.getRequestFile}
              />

              <div className="form-group row mt-3">
                <CustomLabel
                  label={this.props.intl.formatMessage({ id: "Response" })}
                  htmlFor="response"
                />
                {(this.props.user.role !== Roles.CLIENT ||
                  (this.props.user.role === Roles.CLIENT &&
                    this.state.response)) && (
                    <div className="col-12 col-sm-7">
                      <TextareaCounter
                        countLimit={1000}
                        className="textareaCounter"
                        id="response"
                        value={this.state.response}
                        disabled={this.props.user.role === Roles.CLIENT}
                        onChange={(e) => this.onChange("response", e.target.value)}
                        onBlur={() => this.update(this.props.request)}
                      />
                    </div>
                  )}
                {this.props.user.role === Roles.CLIENT &&
                  !this.state.response && (
                    <div className="col-12 col-sm-7">
                      <Alert variant="info">
                        Aucune réponse pour le moment
                      </Alert>
                    </div>
                  )}
              </div>
            </>
          )}
        </Modal.Body>

        {!this.props.request && !this.state.disabledInputs && (
          <Modal.Footer>
            <Button variant="secondary" onClick={() => this.props.close()}>
              <FormattedMessage id="Cancel" />
            </Button>

            <Button
              variant={"info"}
              onClick={() => this.create()}
              disabled={this.state.disabled || this.hasEmptyField()}
            >
              <FormattedMessage id="Create" />
            </Button>
          </Modal.Footer>
        )}
      </Modal>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    clients: state.clients,
    user: state.user,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onCreateRequestAdmin: (data, successCallback) =>
      dispatch(createRequestAdmin(data, successCallback)),
    onCreateRequest: (data, successCallback) =>
      dispatch(createRequest(data, successCallback)),
    onUpdateRequest: (data, successCallback) =>
      dispatch(updateRequest(data, successCallback)),
    onaddRequestFiles: (requestId, data, successCallback) =>
      dispatch(addRequestFiles(requestId, data, successCallback)),
    ondeleteRequestFile: (data, successCallback) =>
      dispatch(deleteRequestFile(data, successCallback)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(EstimateModalAdmin));
