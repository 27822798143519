export default class EstablishmentRequestUtil {
  constructor(establishmentId, requests, clients) {
    this.establishmentId = establishmentId;
    this.requests = requests;
    this.clients = clients;
  }

  countRequests() {
    var total = 0;

    for (let request of this.requests) {
      var client = this.getClient(request.client_id);
      if (!client) continue;

      if (client.establishment_id === this.establishmentId) {
        total++;
      }
    }

    return total;
  }

  getClient(clientId) {
    for (let client of this.clients) {
      if (client._id === clientId) return client;
    }
  }

  static getEstablishment(establishmentId, establishments) {
    for (var establishment of establishments) {
      if (establishment._id === establishmentId) return establishment;
    }
  }
}
