export default class EquipmentUtil {
  static getEquipmentsByEstablishments(establishments, equipments) {
    let equipmentResponse = [];
    for (let equipment of equipments) {
      for (let establishment of establishments) {
        if (!equipmentResponse.includes(equipment)) {
          if (equipment.establishments_id === establishment._id) {
            equipmentResponse.push(equipment);
          }
        }
      }
    }
    //console.log(equipmentResponse);
    return equipmentResponse;
  }

  static getEquipmentsByMaterialTypes(MaterialTypes, equipments) {
    let equipmentResponse = [];
    for (let equipment of equipments) {
      if (equipment.material_type_id === MaterialTypes) {
        equipmentResponse.push(equipment);
      }
    }
    return equipmentResponse;
  }

  static getMaterialTypesByEquipments(materialTypes, equipments) {
    let materialTypeResponse = [];
    for (let equipment of equipments) {
      for (let materialType of materialTypes) {
        //console.log(equipment.material_type_id+" === "+materialType._id);
        if (equipment.material_type_id === materialType._id) {
          let materialAdding = {
            equipment: equipment,
            materialName: materialType.name,
          };
          materialTypeResponse.push(materialAdding);
        }
      }
    }
    return materialTypeResponse;
  }

  static getEquipments(establishmentId, equipments) {
    var equipmentsList = [];

    for (var equipment of this.props.equipments) {
      if (equipment.establishment_id === establishmentId)
        equipmentsList.push(equipment);
    }

    return equipmentsList;
  }

  static getEquipment(equipmentId, equipments) {
    for (var equipment of equipments) {
      if (equipment._id === equipmentId) return equipment;
    }
  }
}
