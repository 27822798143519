import { GET_PAYMENT_METHODS } from "../../actions/configurables/paymentMethods";

export default function paymentMethods(state = [], action) {
  switch (action.type) {
    case GET_PAYMENT_METHODS:
      return action.paymentMethods;
    default:
      return state;
  }
}
