import React from "react";
import { connect } from "react-redux";
import EquipmentsMapping from "../../../util/EquipmentsMapping";
import eqOwner from "../../../enums/EqOwner";
import ArrayUtil from "../../../util/ArrayUtil";
import ObjectUtil from "../../../util/ObjectUtil";
import Util from "../../../util/Util";
import CustomLabel from "../../sub/CustomLabel";
import { FormattedMessage, injectIntl } from "react-intl";
import Icon from "../../sub/Icon";
import { Button, Modal } from "react-bootstrap";
import ModalManager from "../../sub/modals/ModalManager";

class ImportColumnsModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      modal: null,
      disabled: false,
      autoMatching: false,
      missingRequiredColumns: [],
      errorsFound: [],
      formVisibility: true,
      progressBarVisibility: false,
      maxErrorToDisplay: 50, // Define the number of errors (if any) to be displayed in a parsed file
      allowSameSelectValue: true, // If true, value of the same column can be associated to multiple selects
    };
  }

  formatValue(mappingKey, key, value) {
    let formattedValue = "" + value;

    // Specific format to format Excel dates
    // https://stackoverflow.com/questions/16229494/converting-excel-date-serial-number-to-date-using-javascript
    // We bypass date conversion if provided value is a dash ("-")
    if (
      (mappingKey === "purchase_date" || mappingKey === "warranty") &&
      value !== "-" &&
      EquipmentsMapping[mappingKey] === key
    ) {
      // https://www.w3docs.com/snippets/javascript/how-to-format-a-javascript-date.html
      function addLeadingZeros(n) {
        if (n <= 9) {
          return "0" + n;
        }
        return n;
      }

      let dateColumn = new Date(
        Math.round((formattedValue - 25569) * 86400 * 1000)
      );
      formattedValue =
        addLeadingZeros(dateColumn.getDate()) +
        "/" +
        addLeadingZeros(dateColumn.getMonth() + 1) +
        "/" +
        dateColumn.getFullYear();
    }

    if (formattedValue.length > 100)
      formattedValue = formattedValue.substring(0, 100) + "...";

    return formattedValue;
  }

  // Build selects to match columns in the file
  buildSelects() {
    let selects = [];
    let firstRowKeys = Object.keys(this.props.fileData[0]);
    let hintIcon;
    let selectClass;

    for (let mappingKey of Object.keys(EquipmentsMapping)) {
      // Help user and pre-select the closest column (select default value)
      let matchColumn =
        this.state.missingRequiredColumns.indexOf(
          EquipmentsMapping[mappingKey]
        ) !== -1
          ? false
          : EquipmentsMapping[mappingKey];

      if (!matchColumn) {
        hintIcon = (
          <Icon
            icon="circle-exclamation"
            hover={<FormattedMessage id="Mercurials.Auto.Detect.No.Match.2" />}
          />
        );

        selectClass = "text-danger";
      } else {
        hintIcon = (
          <Icon
            icon="circle-check"
            hover={<><FormattedMessage id="Mercurial.Column.Reference.Name" /> : {EquipmentsMapping[mappingKey]}</>}
          />
        );

        selectClass = "text-success";
      }

      var optionsNode = firstRowKeys.map((key) => {
        let value = this.props.fileData[0][key];

        let optionElement;

        if (
          !this.state.autoMatching ||
          (this.state.autoMatching && !matchColumn)
        ) {
          // When autoMatching did not found matching column or autoMatching is disabled, we build a select with the whole list of columns in options
          optionElement = (
            <option key={key + "-" + value} value={key}>
              [{key}] : {this.formatValue(mappingKey, key, value)}
            </option>
          );
        } else {
          // When autoMatching is enabled and worked well (column found) we don't need to display the whole list of columns.
          // Only displaying the matched one in select options
          if (key === EquipmentsMapping[mappingKey]) {
            optionElement = (
              <option key={key + "-" + value} value={key}>
                {this.formatValue(mappingKey, key, value)}
              </option>
            );
          }
        }

        return optionElement;
      });

      selects.push(
        <div className="form-row mb-2" key={mappingKey}>
          <CustomLabel
            label={EquipmentsMapping[mappingKey]}
            htmlFor={mappingKey}
            labelClassName="col-sm-4"
          />
          {/* <label htmlFor={mappingKey} className="col-sm-4">{EquipmentsMapping[mappingKey]}</label> */}
          <div id={mappingKey} className="col-sm-7">
            <select
              key={Math.random()}
              className="form-control column-select"
              defaultValue={
                this.state.autoMatching && matchColumn && matchColumn
              }
              onChange={(e) => this.manageSelectChange(e, mappingKey + "_hint")}
            >
              <option value="">
                {this.props.intl.formatMessage({ id: "Select" })}...
              </option>
              {optionsNode}
            </select>
          </div>
          <div
            id={mappingKey + "_hint"}
            className={"col-sm-1 d-flex align-items-center " + selectClass}
          >
            {this.state.autoMatching && hintIcon}
          </div>
        </div>
      );
    }

    return selects;
  }

  getSelColumn(key) {
    let famDiv = document.getElementById(key);
    let select = famDiv.firstChild;
    return select.options[select.selectedIndex].value;
  }

  onComplete() {
    // Prevent post to backend if button to next step is disabled
    // (means that some column matching already needs to be done)
    if (this.state.disabled || !this.selectsAllHaveValues()) return false;

    this.setState({
      disabled: true,
    });

    var columns = {};

    // Store association between required column and matching column in the file (labels can be different if we didnt used automatching or partial automatching)
    for (let key of Object.keys(EquipmentsMapping)) {
      columns[key] = this.getSelColumn(key);
    }

    /*
        PERFORM INTEGRITY CHECKS UPON DATA BEFORE SENDING MERCURIAL TO THE BACKEND
        */
    if (this.checkDataIntegrity(columns)) {
      this.setState({
        formVisibility: false,
        progressBarVisibility: true,
      });

      return this.props.onComplete(columns);
    }
  }

  /**
   * Performs checks upon data.
   * We try to avoid sending data that will be rejected by the backend (because of Mongo/Mongoose field types for example)
   *
   * @param {*} columnsReferenceList
   */
  checkDataIntegrity(columnsReferenceList) {
    let currentError;
    let eqOwnerValues = Object.keys(eqOwner);

    // Default maxlength for a value (if checked)
    // May be locally changed on some values
    let maxlength;

    // Add an error to the stack
    const addError = (error) => {
      if (Util.typeOf(error) === "Object") {
        let arr = this.state.errorsFound;
        arr.push(error);
        this.setState({ errorsFound: arr });
      }
    };

    // Loop through file rows
    for (let row of this.props.fileData) {
      if (this.state.errorsFound.length === this.state.maxErrorToDisplay) {
        break;
      }

      // Get current row keys
      let currentRowKeys = Object.keys(row);

      /**
       * Compare with required keys to find if any field is missing
       */
      let missingFieldsForCurrentRow = ArrayUtil.difference(
        Object.values(columnsReferenceList),
        currentRowKeys
      );

      // If some empty or missing fields are found in the file, we add them to the stack trace
      if (missingFieldsForCurrentRow.length > 0) {
        for (let missingField of missingFieldsForCurrentRow) {
          currentError = {
            numRow: row.__rowNum__ + 1,
            field: missingField,
            targetField:
              EquipmentsMapping[
              ObjectUtil.getKeyByValue(columnsReferenceList, missingField)
              ],
            value: "",
            hint: <FormattedMessage id="Field.Cant.Be.Empty" />,
          };

          addError(currentError);
        }
      }

      /**
       * CHECK SPECIAL FIELDS (where we know that value must be an integer or a float for example)
       */
      for (let key of currentRowKeys) {
        if (this.state.errorsFound.length === this.state.maxErrorToDisplay) {
          break;
        }

        let EquipmentsMappingReferenceKey = ObjectUtil.getKeyByValue(
          columnsReferenceList,
          key
        );

        switch (true) {
          // Perform tests on date fields
          case EquipmentsMappingReferenceKey === "purchase_date":
          case EquipmentsMappingReferenceKey === "warranty":
            // We allow those fields to be bypassed.
            // To allow this, we must provide a dash caracter ("-") in the selected field
            if (!Util.emptyString(row[key]) && row[key] !== "-") {
              // Convert field value to date
              let checkDate = new Date(row[key]);

              // Check if ocnverted value is a valid date
              if (
                Object.prototype.toString.call(checkDate) === "[object Date]"
              ) {
                // it is a date
                if (isNaN(checkDate.getTime())) {
                  // d.valueOf() could also work
                  // date is not valid
                  currentError = {
                    numRow: row.__rowNum__ + 1,
                    field: key,
                    targetField:
                      EquipmentsMapping[EquipmentsMappingReferenceKey],
                    value: row[key],
                    hint: (
                      <FormattedMessage
                        id="Value.Is.Not.Valid.Date"
                        values={{ value: row[key] }}
                      />
                    ),
                  };
                  addError(currentError);
                } else {
                  // date is valid
                }
              } else {
                // not a date
                currentError = {
                  numRow: row.__rowNum__ + 1,
                  field: key,
                  targetField: EquipmentsMapping[EquipmentsMappingReferenceKey],
                  value: row[key],
                  hint: (
                    <FormattedMessage
                      id="Value.Is.Not.Valid.Date"
                      values={{ value: row[key] }}
                    />
                  ),
                };
                addError(currentError);
              }
            }

            break;

          // We check if the material type exists in props (from redux)
          // If not, we do not allow material type creation so we handle an exception
          case EquipmentsMappingReferenceKey === "type":
            maxlength = 32;
            if (row[key].length > maxlength) {
              currentError = {
                numRow: row.__rowNum__ + 1,
                field: key,
                targetField: EquipmentsMapping[EquipmentsMappingReferenceKey],
                value: row[key],
                hint: (
                  <FormattedMessage
                    id="Error.Maxlength"
                    values={{ value: row[key], maxlength: maxlength }}
                  />
                ),
              };
              addError(currentError);
            } else if (
              this.props.materialsTypes.filter(
                (material_type) => material_type.name === row[key]
              ).length !== 1
            ) {
              currentError = {
                numRow: row.__rowNum__ + 1,
                field: key,
                targetField: EquipmentsMapping[EquipmentsMappingReferenceKey],
                value: row[key],
                hint: (
                  <FormattedMessage
                    id="Material.Type.Not.Found"
                    values={{ value: row[key] }}
                  />
                ),
              };
              addError(currentError);
            }

            break;

          /**
           * SEARCH FOR DUPLICATE EQUIPMENT ALREADY STORED IN DATABASE
           * by checking both establishmentId and internal_ref
           */
          case EquipmentsMappingReferenceKey === "internal_ref":
            let filter = {
              establishment_id: this.props.establishmentId,
              internal_ref: row[key],
            };

            let duplicateEquipments = this.props.equipments.filter(function (
              item
            ) {
              for (var key in filter) {
                if (item[key] === undefined || item[key] !== filter[key])
                  return false;
              }
              return true;
            });

            // Check maxlength
            maxlength = 16;
            if (row[key].length > maxlength) {
              currentError = {
                numRow: row.__rowNum__ + 1,
                field: key,
                targetField: EquipmentsMapping[EquipmentsMappingReferenceKey],
                value: row[key],
                hint: (
                  <FormattedMessage
                    id="Error.Maxlength"
                    values={{ value: row[key], maxlength: maxlength }}
                  />
                ),
              };
              addError(currentError);
            } else {
              // A duplicate equipment is found in redux store
              if (duplicateEquipments.length > 0) {
                currentError = {
                  numRow: row.__rowNum__ + 1,
                  field: key,
                  targetField: EquipmentsMapping[EquipmentsMappingReferenceKey],
                  value: row[key],
                  hint: (
                    <FormattedMessage
                      id="Material.Duplicate"
                      values={{ value: row[key] }}
                    />
                  ),
                };
                addError(currentError);
              }
            }

            break;

          // We check if the owner is a valid value in corresponding enum
          case EquipmentsMappingReferenceKey === "owner":
            let ownerId = row[key].split("-");
            let index = ownerId.shift();

            if (!eqOwnerValues[index.trim()]) {
              currentError = {
                numRow: row.__rowNum__ + 1,
                field: key,
                targetField: EquipmentsMapping[EquipmentsMappingReferenceKey],
                value: row[key],
                hint: (
                  <FormattedMessage
                    id="Owner.Type.Not.Found"
                    values={{ value: row[key] }}
                  />
                ),
              };
              addError(currentError);
            }

            break;

          case EquipmentsMappingReferenceKey === "brand":
          case EquipmentsMappingReferenceKey === "model":
          case EquipmentsMappingReferenceKey === "serial_nbr":
            maxlength = 32;
            // Check maxlength
            if (row[key].length > maxlength) {
              currentError = {
                numRow: row.__rowNum__ + 1,
                field: key,
                targetField: EquipmentsMapping[EquipmentsMappingReferenceKey],
                value: row[key],
                hint: (
                  <FormattedMessage
                    id="Error.Maxlength"
                    values={{ value: row[key], maxlength: maxlength }}
                  />
                ),
              };
              addError(currentError);
            }
            break;

          case EquipmentsMappingReferenceKey === "floor":
            if (row[key] !== "-") {
              maxlength = 3;
              // Check maxlength
              if (row[key].length > maxlength) {
                currentError = {
                  numRow: row.__rowNum__ + 1,
                  field: key,
                  targetField: EquipmentsMapping[EquipmentsMappingReferenceKey],
                  value: row[key],
                  hint: (
                    <FormattedMessage
                      id="Error.Maxlength"
                      values={{ value: row[key], maxlength: maxlength }}
                    />
                  ),
                };
                addError(currentError);
              }
            }
            break;

          case EquipmentsMappingReferenceKey === "room":
            maxlength = 32;
            // Check maxlength
            if (row[key].length > maxlength) {
              currentError = {
                numRow: row.__rowNum__ + 1,
                field: key,
                targetField: EquipmentsMapping[EquipmentsMappingReferenceKey],
                value: row[key],
                hint: (
                  <FormattedMessage
                    id="Error.Maxlength"
                    values={{ value: row[key], maxlength: maxlength }}
                  />
                ),
              };
              addError(currentError);
            }
            break;

          default:
            // Convert field to string to evaluate it
            let fieldValue = row[key].toString();
            // Double check string fields that may contain only spaces (so they are not considered as empty)
            // We trim the value in order to catch'em as well eventually
            if (fieldValue.trim() === "") {
              currentError = {
                numRow: row.__rowNum__ + 1,
                field: key,
                targetField: EquipmentsMapping[EquipmentsMappingReferenceKey],
                value: "",
                hint: <FormattedMessage id="Field.Cant.Be.Empty" />,
              };

              addError(currentError);
            }
            break;
        }
      }
    }

    // If some errors have been detected on fields values while parsing file (and after column matching), we abort the import process
    // And we display a list of found errors
    if (this.state.errorsFound.length > 0) {
      return this.openDataIntegrityModal(
        this.state.errorsFound,
        columnsReferenceList
      );
    }

    return true;
  }

  openDataIntegrityModal(dataProblems, columns) {
    var errorModalTitle = <FormattedMessage id="Error" />;
    var errorModalContent = (
      <div>
        <div className="alert alert-danger">
          <div>
            <FormattedMessage id="Mercurial.File.Missing.Data" />
          </div>
          {Object.values(dataProblems).length ===
            this.state.maxErrorToDisplay && (
              <div>
                <FormattedMessage
                  id="Mercurial.File.Error.Count"
                  values={{ count: Object.values(dataProblems).length }}
                />
              </div>
            )}
        </div>
        <table className="table table-striped tablee4coll">
          <thead>
            <tr className="d-flex">
              <th scope="col" className="col col-1">
                <FormattedMessage id="Line" />
              </th>
              <th scope="col" className="col col-3">
                <FormattedMessage id="Column.In.File" />
              </th>
              <th scope="col" className="col col-3">
                <FormattedMessage id="Target.Field" />
              </th>
              <th scope="col" className="col col-5">
                <FormattedMessage id="Hint" />
              </th>
            </tr>
          </thead>
          <tbody>
            {dataProblems.map((row, index) => {
              return (
                <tr key={index} className="d-flex">
                  <td className="col col-1">{row.numRow}</td>
                  <td className="col col-3">{row.field}</td>
                  <td className="col col-3">{row.targetField}</td>
                  <td className="col col-5">{row.hint}</td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    );

    this.setState({
      modal: (
        <ModalManager
          showModal={true}
          size="xl"
          isOpen={true}
          title={errorModalTitle}
          content={errorModalContent}
          closeModal={() => this.props.closeModal()}
          buttonLabel={<FormattedMessage id="Cancel" />}
        />
      ),
    });
  }

  manageSelectChange(e, selectHint) {
    let hintElement = document.getElementById(selectHint);

    if (e.target.value !== "") {
      hintElement.classList.remove("text-danger");
      hintElement.classList.add("text-success");
    } else {
      hintElement.classList.add("text-danger");
      hintElement.classList.remove("text-success");
    }

    this.updateSelectOptions();
  }

  componentDidMount() {
    this.updateSelectOptions();
  }

  updateSelectOptions() {
    // if allowSameSelectValue is true in state (default) we allow the user to choose the same select multiple times for different values (ex: client ref and manufacturer ref linked to the same col in file)
    if (!this.state.allowSameSelectValue) {
      let selects = document.getElementsByClassName("column-select");

      for (let s of selects) {
        for (let i = 0; i < s.length; i++) {
          s.options[i].disabled = false;
        }
      }

      for (let s1 of selects) {
        var value1 = s1.value;

        for (let s2 of selects) {
          if (s1 === s2) continue;

          for (let i = 0; i < s2.length; i++) {
            if (s2.options[i].value !== "" && s2.options[i].value === value1)
              s2.options[i].disabled = true;
          }
        }
      }
    }
    this.checkDisableButton();
  }

  updateAutoMatching() {
    if (!this.state.autoMatching) {
      // When automatching is enabled, we check if all the required columns are found in the provided file
      let firstRowKeys = Object.keys(this.props.fileData[0]);
      this.setState({
        missingRequiredColumns: ArrayUtil.difference(
          Object.values(EquipmentsMapping),
          firstRowKeys
        ),
      });
    } else {
      // We reset the columns to check if automatching is disabled
      this.setState({ missingRequiredColumns: [] });
    }

    this.setState(
      { autoMatching: !this.state.autoMatching },
      this.updateSelectOptions
    );
  }

  checkDisableButton() {
    let button = document.getElementById("submit-button");
    button.disabled = this.state.disabled || !this.selectsAllHaveValues();
  }

  selectsAllHaveValues() {
    let selects = document.getElementsByClassName("column-select");

    for (let s of selects) {
      if (!s.value || s.value === "") return false;
    }

    return true;
  }

  render() {
    // Prepare as many selects as required for the mapping
    let selects = this.buildSelects();

    // Split the selects on 2 displayed columns
    let selects1stHalf = [];
    let selects2ndHalf = [];
    for (let i = 0; i < selects.length; i++) {
      if (i < selects.length / 2) selects1stHalf.push(selects[i]);
      else selects2ndHalf.push(selects[i]);
    }

    let goToNextStepButton = (
      <button
        id="submit-button"
        type="button"
        className="btn btn-info"
        onClick={() => this.onComplete()}
        disabled={this.state.disabled}
      >
        <FormattedMessage id="Import" />
      </button>
    );

    return (
      <Modal
        show={true}
        onHide={() => this.props.closeModal()}
        backdrop={"static"}
        size="xl"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <FormattedMessage id="Import.Equipments" />
          </Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <h4 className="w-100 text-center">
            <FormattedMessage id="Mercurials.Step2.Desc" />
          </h4>

          {this.state.progressBarVisibility && <div
            className="text-center mb-5 "
          >
            <Icon icon="gear" className="fa-spin fa-3x fa-fw text-success mb-3" />
            <div className="progress" style={{ height: "30px" }}>
              <div
                className="progress-bar progress-bar-striped progress-bar-animated bg-success"
                role="progressbar"
                aria-valuenow="100"
                aria-valuemin="0"
                aria-valuemax="100"
                style={{ width: "100%" }}
              >
                <strong>
                  <FormattedMessage id="Import.Mercurial.Save.Data" />
                </strong>
              </div>
            </div>
          </div>}

          {this.state.formVisibility && <div>
            <h5 className="mb-4 w-100 text-center font-weight-light">
              <FormattedMessage id="Mercurials.Step2.Read.1st.Line" />
            </h5>
            <div className="custom-switch custom-control switch-success mb-3 text-center">
              <input
                onChange={(e) => this.updateAutoMatching()}
                type="checkbox"
                className="custom-control-input switch-bg-blue"
                id="auto-matching"
                checked={this.state.autoMatching}
              />
              <CustomLabel
                label={this.props.intl.formatMessage({
                  id: "Mercurials.Auto.Detect",
                })}
                htmlFor="auto-matching"
                labelClassName="custom-control-label"
              />
            </div>
            <div className="row">
              <div className="col-12 col-lg-6">{selects1stHalf}</div>

              <div className="col-12 col-lg-6">{selects2ndHalf}</div>

              <div className="col-12">
                {this.state.missingRequiredColumns.length > 0 && (
                  <div className="alert alert-danger mt-4" role="alert">
                    <div className="row">
                      <div className="col-1 d-flex align-items-center">
                        <Icon icon="triangle-exclamation" size="3x" />
                      </div>
                      <div className="col-11">
                        <div className="mb-2">
                          <FormattedMessage id="Mercurials.Auto.Detect.No.Match.1" />
                        </div>
                        <ul className="pb-0 mb-2">
                          {this.state.missingRequiredColumns.map(
                            (requiredColumn, index) => (
                              <li key={index}>
                                <span className="badge badge-success">
                                  {requiredColumn}
                                </span>
                              </li>
                            )
                          )}
                        </ul>
                        <div className="text-justify">
                          <FormattedMessage id="Mercurials.Auto.Detect.No.Match.2" />
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>}
        </Modal.Body>

        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => this.props.closeModal()}
          >
            <FormattedMessage id="Cancel" />
          </Button>
          {goToNextStepButton}
        </Modal.Footer>
        {this.state.modal}
      </Modal>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    materialsTypes: state.materialsTypes,
    equipments: state.equipments,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    //
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(ImportColumnsModal));
