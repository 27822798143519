import { combineReducers } from "redux";
import user from "./user/userReducer";
import products from "./products/productsReducer";
import oldProducts from "./products/oldProductsReducer";
import cart from "./cart/cartReducer";
import i18n from "./i18n/i18nReducer";
import orders from "./orders/ordersReducer";
import clients from "./clients/clientsReducer";
import establishments from "./establishments/establishmentsReducer";
import ordersSettingsAdmin from "./settings/ordersSettings/admin/ordersSettingsAdminReducer";
import ordersSettings from "./settings/ordersSettings/ordersSettingsReducer";
import notifications from "./notifications/notificationsReducer";
import mercurials from "./mercurials/mercurialsReducer";
import establishmentTypes from "./configurables/establishmentTypesReducer";
import collaboratorTypes from "./configurables/collaboratorTypesReducer";
import equipmentsRequest from "./equipmentsRequest/equipmentsRequestReducer";
import brands from "./configurables/brandsReducer";
import paymentMethods from "./configurables/paymentMethodsReducer";
import paymentDeadlines from "./configurables/paymentDeadlinesReducer";
import establishmentsSettings from "./establishments/establishmentsSettingsReducer";
import establishmentSettings from "./establishments/establishmentSettingsReducer";
import materialsTypes from "./settings/materialsTypes/materialsTypesReducer";
import materiovigilance from "./settings/materiovigilance/materiovigilanceReducer";
import requests from "./requests/requestsReducer";
import residents from "./residents/residentsReducer";
import equipments from "./equipments/equipmentsReducer";
import models from "./configurables/modelsReducer";
import checkpoints from "./checkpoints/checkpointsReducer";
import maintenances from "./maintenances/maintenancesReducer";
import collaborators from "./collaborators/collabotarorsReducer";
import establishment from "./establishment/establishmentReducer";
import admin from "./admin/adminReducer";
import company from "./company/companyReducer";
import generalSettings from "./settings/generalSettings/generalSettingsReducer";
import url from "./authentication/authentication";
import rgpd from "./rgpd/rgpdReducer";
import promoOperations from "./promoOperations/promoOperationsReducer";
import apiMust from "./apiMust/apiMustReducer";

import { LOGOUT } from "../actions/authentication/authentication";

import { resetOldProducts } from "../actions/products/oldProducts";
import { resetEquipments } from "../actions/equipments/equipments";

const appReducer = combineReducers({
  user: user,
  products: products,
  oldProducts: oldProducts,
  cart: cart,
  i18n: i18n,
  orders: orders,
  clients: clients,
  establishments: establishments,
  establishmentTypes: establishmentTypes,
  establishmentsSettings: establishmentsSettings,
  establishmentSettings: establishmentSettings,
  equipmentsRequest: equipmentsRequest,
  ordersSettings: ordersSettings,
  ordersSettingsAdmin: ordersSettingsAdmin,
  mercurials: mercurials,
  notifications: notifications,
  paymentMethods: paymentMethods,
  paymentDeadlines: paymentDeadlines,
  materialsTypes: materialsTypes,
  materiovigilance: materiovigilance,
  requests: requests,
  residents: residents,
  equipments: equipments,
  brands: brands,
  models: models,
  checkpoints: checkpoints,
  maintenances: maintenances,
  collaboratorTypes: collaboratorTypes,
  collaborators: collaborators,
  establishment: establishment,
  admin: admin,
  generalSettings: generalSettings,
  url: url,
  promoOperations: promoOperations,
  company: company,
  rgpd: rgpd,
  apiMust: apiMust,
});

const urlReducer = (state, action) => {
  if (action.type === LOGOUT) {
    // Wipe all redux state
    state = undefined;

    // Call other reset functions that might need a clean state
    resetOldProducts();
    resetEquipments();
  }

  return appReducer(state, action);
};

export default urlReducer;
