import { GET_PROMO_OPERATIONS } from "../../actions/promoOperations/promoOperations";

export default function oldProducts(state = [], action) {
  switch (action.type) {
    case GET_PROMO_OPERATIONS:
      return action.promoOperations;
    default:
      return state;
  }
}
