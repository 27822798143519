import React from "react";
import { connect } from "react-redux";
import { FormattedMessage, injectIntl } from "react-intl";
import Util from "../../util/Util";
import Role from "../../enums/Roles";
import { updateUser } from "../../actions/user/user";
import CustomLabel from "../sub/CustomLabel";

class UpdateAccountForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      username: this.props.user.username,
      name: this.props.user.name,
      first_name: this.props.user.first_name,
      email: this.props.user.email,
      phone: this.props.user.phone,
      function: this.props.user.function,
      emailError: null,
    };
  }

  onChange(key, value) {
    this.setState({ [key]: value });
  }

  update(field, value) {
    if (Util.emptyString(value)) return;

    if (field === "email" && !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value)) {
      this.setState({ emailError: <FormattedMessage id="Invalid.Email" /> });
      return;
    } else {
      this.setState({ emailError: null });
    }

    var data = {
      updatedField: field,
      updatedValue: value,
    };

    this.props.onUpdateUser(data);
  }

  render() {
    return (
      <div className="col-12 col-sm-8">
        <div className="form-group row">
          <CustomLabel
            label={this.props.intl.formatMessage({ id: "Username" })}
            htmlFor="username"
            labelClassName="col-12 col-sm-5"
          />
          <div className="col-12 col-sm-7">
            <input
              className="form-control"
              type="text"
              id="username"
              autoComplete="off"
              value={this.state.username || ""}
              disabled={true}
            />
          </div>
        </div>
        <div className="form-group row">
          <CustomLabel
            label={this.props.intl.formatMessage({ id: "First.Name" })}
            htmlFor="first_name"
            labelClassName="col-12 col-sm-5"
          />
          <div className="col-12 col-sm-7">
            <input
              className="form-control"
              type="text"
              id="first_name"
              autoComplete="off"
              value={this.state.first_name || ""}
              onChange={(e) => this.onChange("first_name", e.target.value)}
              onBlur={() => this.update("first_name", this.state.first_name)}
            />
          </div>
        </div>
        <div className="form-group row">
          <CustomLabel
            label={this.props.intl.formatMessage({ id: "Name" })}
            htmlFor="name"
            labelClassName="col-12 col-sm-5"
          />
          <div className="col-12 col-sm-7">
            <input
              className="form-control"
              type="text"
              id="name"
              autoComplete="off"
              value={this.state.name || ""}
              onChange={(e) => this.onChange("name", e.target.value)}
              onBlur={() => this.update("name", this.state.name)}
            />
          </div>
        </div>
        <div className="form-group row">
          <CustomLabel
            label={this.props.intl.formatMessage({ id: "Email" })}
            htmlFor="email"
            labelClassName="col-12 col-sm-5"
          />
          <div className="col-12 col-sm-7">
            <input
              className="form-control"
              type="email"
              id="email"
              autoComplete="off"
              value={this.state.email || ""}
              onChange={(e) => this.onChange("email", e.target.value)}
              onBlur={() => this.update("email", this.state.email)}
            />
            <small className="text-danger">{this.state.emailError}</small>
          </div>
        </div>
        <div className="form-group row">
          <CustomLabel
            label={this.props.intl.formatMessage({ id: "Function" })}
            htmlFor="function"
            labelClassName="col-12 col-sm-5"
          />
          <div className="col-12 col-sm-7">
            <input
              className="form-control"
              type="text"
              id="function"
              autoComplete="off"
              value={this.state.function || ""}
              onChange={(e) => this.onChange("function", e.target.value)}
              onBlur={() => this.update("function", this.state.function)}
            />
          </div>
        </div>
        {this.props.user.role === Role.CLIENT && (
          <EstablishmentComponent
            establishment={this.props.establishment}
            intl={this.props.intl}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    //
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onUpdateUser: (data) => dispatch(updateUser(data)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(UpdateAccountForm));

class EstablishmentComponent extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      establishment: this.props.establishment.name,
      address: this.props.establishment.address,
      postal_code: this.props.establishment.postal_code,
      phone: this.props.establishment.phone,
      city: this.props.establishment.city,
    };
  }

  render() {
    return (
      <React.Fragment>
        <div className="form-group row">
          <CustomLabel
            label={this.props.intl.formatMessage({ id: "Establishment" })}
            htmlFor="establishment"
            labelClassName="col-12 col-sm-5"
          />
          <div className="col-12 col-sm-7">
            <input
              className="form-control"
              type="text"
              id="establishment"
              autoComplete="off"
              value={this.state.establishment || ""}
              disabled={true}
            />
          </div>
        </div>
        <div className="form-group row">
          <CustomLabel
            label={this.props.intl.formatMessage({ id: "Address" })}
            htmlFor="address"
            labelClassName="col-12 col-sm-5"
          />
          <div className="col-12 col-sm-7">
            <input
              className="form-control"
              type="text"
              id="address"
              autoComplete="off"
              value={this.state.address || ""}
              disabled={true}
            />
          </div>
        </div>
        <div className="form-group row">
          <CustomLabel
            label={this.props.intl.formatMessage({ id: "Postal.Code" })}
            htmlFor="postal_code"
            labelClassName="col-12 col-sm-5"
          />
          <div className="col-12 col-sm-7">
            <input
              className="form-control"
              type="text"
              id="postal_code"
              autoComplete="off"
              value={this.state.postal_code || ""}
              disabled={true}
            />
          </div>
        </div>
        <div className="form-group row">
          <CustomLabel
            label={this.props.intl.formatMessage({ id: "City" })}
            htmlFor="city"
            labelClassName="col-12 col-sm-5"
          />
          <div className="col-12 col-sm-7">
            <input
              className="form-control"
              type="text"
              id="city"
              autoComplete="off"
              value={this.state.city || ""}
              disabled={true}
            />
          </div>
        </div>
        <div className="form-group row">
          <CustomLabel
            label={this.props.intl.formatMessage({ id: "Phone" })}
            htmlFor="phone"
            labelClassName="col-12 col-sm-5"
          />
          <div className="col-12 col-sm-7">
            <input
              className="form-control"
              type="text"
              id="phone"
              autoComplete="off"
              value={this.state.phone || ""}
              disabled={true}
            />
          </div>
        </div>
      </React.Fragment>
    );
  }
}
