import APIUrl from "../../../APIUrl";
import axios from "axios";

export const GET_ORDERS_SETTINGS = "GET_ORDERS_SETTINGS";

function getOrdersSettingsAction(ordersSettings) {
  return { type: GET_ORDERS_SETTINGS, ordersSettings: ordersSettings };
}

export const getOrdersSettings = function () {
  return function (dispatch) {
    return axios
      .get(APIUrl.getOrdersSettings)
      .then(function (response) {
        dispatch(getOrdersSettingsAction(response.data));
      })
      .catch(function (err) {
        throw err;
      });
  };
};
