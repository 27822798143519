import React from "react";
import { connect } from "react-redux";
import { FormattedMessage, injectIntl } from "react-intl";
import ComboBox from "../../sub/ComboBox";
import {
  addEquipmentsRequest,
  updateEquipmentsRequest,
} from "../../../actions/equipmentsRequest/equipmentsRequest";
import { Button, Modal } from "react-bootstrap";
import CustomLabel from "../../sub/CustomLabel";
import DateUtil from "../../../util/DateUtil";
import TextareaCounter from "react-textarea-counter";
import Util from "../../../util/Util";

class EquipmentRequestModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      establishment_id: this.props.establishmentId,
      description: "",
      desired_availability_date: new Date(),
      material_type_id: "",
      creation_date: "",
      type: 0,
      disabled: true,
    };

    // Update existing equipment
    if (this.props.mode !== "new" && this.props.equipment_request) {
      this.state = {
        establishment_id: this.props.equipment_request.establishment_id,
        description: this.props.equipment_request.description,
        material_type_id: this.props.equipment_request.material_type_id,
        creation_date: this.props.equipment_request.creation_date,
        desired_availability_date:
          this.props.equipment_request.desired_availability_date,
        type: this.props.equipment_request.type,
        disabled: false,
      };
    }
  }

  hasEmptyField() {
    return (
      Util.emptyString(this.state.material_type_id) ||
      Util.emptyString(this.state.description)
    );
  }

  onChange(field, value) {
    return this.setState({ [field]: value });
  }

  update(field) {
    if (this.props.mode !== "new") {
      let data = {
        updatedField: field,
        updatedValue: this.state[field],
        equipments_request_id: this.props.equipment_request._id,
      };
      this.props.onUpdateEquipmentsRequest(data);
    }
  }

  onComplete() {
    if (this.props.mode === "new") {
      let data = {
        establishment_id: this.state.establishment_id,
        description: this.state.description,
        material_type_id: this.state.material_type_id,
        desired_availability_date: this.state.desired_availability_date,
        type: this.state.type,
        creation_date: DateUtil.toDate(new Date()),
      };
      this.props.onAddEquipmentsRequest(data, () => this.props.close());
    }
  }

  render() {
    let title = (
      <FormattedMessage
        id={
          this.props.equipment_request
            ? this.props.mode === "edit"
              ? "Equipment.Request.Modify"
              : "Equipment.Request.Consult"
            : "New.Request"
        }
      />
    );

    const date = new Date();
    const beginDate = date.setDate(date.getDate());
    const endDate = date.setDate(date.getDate() + 30);

    return (
      <Modal
        show={true}
        onHide={() => this.props.close()}
        backdrop={"static"}
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>

        <Modal.Body>
          <div className="row">
            <div className="col-12">
              <CustomLabel
                label={this.props.intl.formatMessage({ id: "Medical.Device" })}
                htmlFor="equipment-request"
                labelClassName="col-sm-5"
                required
              />
              <div id="equipment-request" className="col-sm-7 pb-3">
                <ComboBox
                  disabled={this.props.mode === "view"}
                  defaultOption={this.state.material_type_id}
                  options={this.props.materialsTypes}
                  onChange={(value) => this.onChange("material_type_id", value)}
                  onBlur={() => this.update("material_type_id")}
                />
              </div>
              <CustomLabel
                label={this.props.intl.formatMessage({ id: "Description" })}
                htmlFor="equipment-request"
                labelClassName="col-sm-5"
                required
              />
              <div id="equipment-request" className="col-auto pb-3">
                <TextareaCounter
                  className="textareaCounter"
                  countLimit={500}
                  value={this.state.description}
                  disabled={this.props.mode === "view"}
                  onChange={(e) => this.onChange("description", e.target.value)}
                  onBlur={() => this.update("description")}
                />
              </div>
              <CustomLabel
                label={this.props.intl.formatMessage({ id: "Desired.Date" })}
                htmlFor="desired_availability_date"
                labelClassName="col-sm-5"
                required
              />
              <div id="desired_availability_date" className="col-auto pb-3">
                <input
                  className="form-control"
                  type="date"
                  min={DateUtil.toyyyyMMdd(beginDate)}
                  max={DateUtil.toyyyyMMdd(endDate)}
                  value={DateUtil.toyyyyMMdd(
                    this.state.desired_availability_date
                  )}
                  disabled={this.props.mode === "view"}
                  onChange={(e) =>
                    this.onChange("desired_availability_date", e.target.value)
                  }
                  onBlur={() => this.update("desired_availability_date")}
                />
              </div>
              <CustomLabel
                label={this.props.intl.formatMessage({ id: "Type" })}
                htmlFor="type"
                labelClassName="col-sm-5"
                required
              />
              <div id="type" className="col-auto pb-3">
                <select
                  disabled={this.props.mode === "view"}
                  className="form-control"
                  value={this.state.type}
                  onChange={(e) => this.onChange("type", e.target.value)}
                  onBlur={() => this.update("type")}
                >
                  <option value="">
                    {this.props.intl.formatMessage({
                      id: "Select",
                    })}
                    ...
                  </option>
                  <option value="0">
                    {this.props.intl.formatMessage({
                      id: "Material.Type.0",
                    })}
                  </option>
                  <option value="1">
                    {this.props.intl.formatMessage({
                      id: "Material.Type.1",
                    })}
                  </option>
                </select>
              </div>
            </div>
          </div>
        </Modal.Body>
        {!this.props.equipmentRequest && this.props.mode === "new" && (
          <Modal.Footer>
            <Button variant="secondary" onClick={() => this.props.close()}>
              <FormattedMessage id="Cancel" />
            </Button>
            <Button
              variant="info"
              onClick={() => this.onComplete()}
              disabled={this.hasEmptyField()}
            >
              <FormattedMessage id={"Add"} />
            </Button>
          </Modal.Footer>
        )}
      </Modal>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    materialsTypes: state.materialsTypes,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onAddEquipmentsRequest: (data, successCallback) =>
      dispatch(addEquipmentsRequest(data, successCallback)),
    onUpdateEquipmentsRequest: (data, successCallback) =>
      dispatch(updateEquipmentsRequest(data, successCallback)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(EquipmentRequestModal));
