import React from "react";
import { connect } from "react-redux";
import { FormattedMessage, injectIntl } from "react-intl";
import DateUtil from "../../util/DateUtil";
import RequestTypes from "../../enums/RequestTypes";
import APIUrl from "../../APIUrl";
import { Badge } from "react-bootstrap";
import EqOwner from "../../enums/EqOwner";
import ActionMenu from "../sub/ActionMenu";
import Icon from "../sub/Icon";
import "react-notifications/lib/notifications.css";
class EquipmentRow extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      modal: null,
    };
  }

  getMaterialType(materialTypeId) {
    for (let materialType of this.props.materialsTypes) {
      if (materialType._id === materialTypeId) return materialType;
    }
  }

  getBrandName(brandId) {
    for (let brand of this.props.brands) {
      if (brand._id === brandId) return brand.name.toString().toUpperCase();
    }
  }

  getModelName(modelId) {
    for (let model of this.props.models) {
      if (model._id === modelId) return model.name;
    }
  }

  closeModal() {
    this.setState({ modal: null });
  }

  hasPendingRequest(equipment, requestType) {
    for (let r of this.props.requests) {
      if (
        r.equipment_id === equipment._id &&
        r.request_type === requestType &&
        r.status <= 1
      ) {
        return true;
      }
    }
    return false;
  }

  maintenanceRequired(dateBirthday, dateBirthdayString) {
    let date = new Date(dateBirthday);
    let yearBirthday = date.getFullYear() + 1;
    let monthBirthday = date.getMonth();
    let dayBirthday = date.getDate();
    let dateBirthdayTime =
      new Date(yearBirthday, monthBirthday, dayBirthday).getTime() / 1000;
    let dateNowTime = new Date().getTime() / 1000;

    if (dateBirthdayTime < dateNowTime)
      return (
        <Badge variant="danger" className="w-100">{dateBirthdayString}</Badge>
      );
    if (dateBirthdayTime - 3600 * 24 * 60 < dateNowTime)
      return (
        <Badge variant="warning" className="w-100">{dateBirthdayString}</Badge>
      );
    return (
      <Badge variant="success" className="w-100">{dateBirthdayString}</Badge>
    );
  }

  getActualAffectation(resident_id, residents) {
    for (let resident of residents) {
      if (resident._id === resident_id) {
        return resident.first_name + " " + resident.name;
      }
    }
    return this.props.intl.formatMessage({ id: "Unspecified" });
  }

  formatHeading(element) {
    return <h6>{element}</h6>;
  }

  render() {
    if (this.props.materialsTypes.length === 0) return null;

    var lastMaintenanceDate = null;
    if (
      this.props.maintenances !== undefined &&
      this.props.maintenances.length > 0
    ) {
      lastMaintenanceDate = DateUtil.toDate(
        this.props.maintenances[this.props.maintenances.length - 1]
          .creation_date
      );
      lastMaintenanceDate = this.maintenanceRequired(
        this.props.maintenances[this.props.maintenances.length - 1]
          .creation_date,
        lastMaintenanceDate
      );
    } else {
      lastMaintenanceDate = <Badge variant="light" className="w-100">-</Badge>;
    }

    if (
      this.props.maintenances !== undefined &&
      this.props.maintenances.length > 0
    ) {
      if (
        this.props.maintenanceToSign !== undefined &&
        this.props.maintenanceToSign.includes(this.props.equipment._id)
      ) {
        lastMaintenanceDate =
          this.props.maintenances[this.props.maintenances.length - 1]
            .signature_date === undefined ? (
            <Badge variant="info" className="w-100 disabled">
              {DateUtil.toDate(
                this.props.maintenances[this.props.maintenances.length - 1]
                  .creation_date
              )}
            </Badge>
          ) : (
            <Badge variant="info" className="w-100 disabled">
              {DateUtil.toDate(
                this.props.maintenances[this.props.maintenances.length - 1]
                  .signature_date
              )}
            </Badge>
          );
      } else {
        lastMaintenanceDate =
          this.props.maintenances[this.props.maintenances.length - 1]
            .signature_date === undefined
            ? DateUtil.toDate(
              this.props.maintenances[this.props.maintenances.length - 1]
                .creation_date
            )
            : DateUtil.toDate(
              this.props.maintenances[this.props.maintenances.length - 1]
                .signature_date
            );
        lastMaintenanceDate =
          this.props.maintenances[this.props.maintenances.length - 1]
            .signature_date === undefined
            ? this.maintenanceRequired(
              this.props.maintenances[this.props.maintenances.length - 1]
                .creation_date,
              lastMaintenanceDate
            )
            : this.maintenanceRequired(
              this.props.maintenances[this.props.maintenances.length - 1]
                .signature_date,
              lastMaintenanceDate
            );
      }
    } else {
      lastMaintenanceDate = <Badge variant="light" className="w-100">-</Badge>;
    }

    var equipment = this.props.equipment;
    var residents = this.props.residents;

    let materiovigilance = <Badge variant="light" className="w-100">-</Badge>;
    if (this.props.materiovigilance !== undefined) {
      var mvClassName = "";
      if (parseInt(this.props.materiovigilance) === 1) mvClassName = "danger";
      else if (parseInt(this.props.materiovigilance) === 2)
        mvClassName = "info";
      else if (parseInt(this.props.materiovigilance) === 3)
        mvClassName = "warning";

      materiovigilance = (
        <Badge variant={mvClassName} className="w-100">
          {this.props.intl.formatMessage({
            id: "EqMateriovigilance." + this.props.materiovigilance,
          })}
        </Badge>
      );
    } else {
      materiovigilance = (
        <Badge variant="success" className="w-100">
          {this.props.intl.formatMessage({ id: "EqMateriovigilance.0" })}
        </Badge>
      );
    }

    // Styling owner of the DM
    let currentOwner = <Badge variant="light" className="w-100">-</Badge>;
    if (equipment.owner !== undefined) {
      if (this.props.mode !== "quickview") {
        currentOwner = this.props.intl.formatMessage({
          id: "EqOwner." + equipment.owner,
        });
      } else {
        currentOwner = this.props.intl.formatMessage({
          id: "EqOwner.quickview." + equipment.owner,
        });
      }
      let ownerClassName;

      switch (parseInt(equipment.owner)) {
        case 0:
          ownerClassName = "warning";
          break;
        case 1:
          ownerClassName = "success";
          break;
        case 2:
          ownerClassName = "light";
          currentOwner = this.props.intl.formatMessage({ id: "External" });
          break;
        default:
          ownerClassName = "light";
          break;
      }

      currentOwner = (
        <Badge variant={ownerClassName} className="w-100">
          {currentOwner}
        </Badge>
      );
    }

    let equipmentState = <Badge variant="light" className="w-100">-</Badge>;
    if (equipment.state !== undefined) {
      equipmentState = this.props.intl.formatMessage({
        id: "EqState." + equipment.state,
      });
      let equipmentClassName;

      switch (equipment.state) {
        case 0:
          equipmentClassName = "success";
          break;
        case 1:
          equipmentClassName = "warning";
          break;
        case 2:
          equipmentClassName = "danger";
          break;
        default:
          equipmentClassName = "light";
          break;
      }

      equipmentState = (
        <Badge variant={equipmentClassName} className="w-100">
          {equipmentState}
        </Badge>
      );
    }

    var materialType = this.getMaterialType(equipment.material_type_id);
    if (!materialType) return null;

    var imgSrc =
      APIUrl.getMaterialTypeImg +
      materialType._id +
      "/" +
      materialType.name +
      "/" +
      Math.random() +
      "?token=" +
      APIUrl.jwtToken;
    var img = (
      <img
        src={imgSrc}
        alt={"material_type_img" + Math.random()}
        height="100"
        width="100"
        onError={(e) => {
          e.target.onerror = null;
          e.target.src = "/images/no_image_512.png";
        }}
      />
    );

    let equipmentAffectation = () => {
      if (equipment.affectation !== undefined) {
        if (equipment.affectation === 0) {
          return (
            <div>
              <Icon
                id={"affectation-" + equipment._id}
                size="xl"
                icon="user"
                hover={this.getActualAffectation(equipment.resident_id, residents)}
              />
            </div>
          );
        } else {
          return (
            <div>
              <Icon
                id={"affectation-" + equipment._id}
                size="xl"
                icon="users"
                hover={<FormattedMessage id="EqAffectation.1" />}
              />
            </div>
          );
        }
      } else {
        return this.props.intl.formatMessage({ id: "Non.Available.Abbr" });
      }
    };

    let menuItems = [];

    menuItems.push({
      id: null,
      icon: "eye",
      disabled: false,
      action: () => this.props.editEquipment(equipment),
      text: <FormattedMessage id="Consult" />,
    });

    if (this.props.mode !== "quickview") {
      menuItems.push({
        id: null,
        icon: "clipboard-list",
        disabled:
          equipment.owner !== EqOwner.EXTERNAL
            ? false
            : true,
        action: () => { this.props.openMaintenancesHistoryModal(equipment) },
        text: <FormattedMessage id="Maintenances" />,
      });
      menuItems.push({
        id: "intervention" + equipment._id,
        icon: this.hasPendingRequest(equipment, RequestTypes.RENEWAL) ? "recycle" : "wrench",
        disabled: (equipment.owner === EqOwner.EXTERNAL),
        notification: (this.hasPendingRequest(equipment, RequestTypes.INTERVENTION) || this.hasPendingRequest(equipment, RequestTypes.RENEWAL)) && 1,
        action: (equipment.owner !== EqOwner.EXTERNAL)
          ? () => {
            this.props.openInterventionModal(equipment)
          }
          : false,
        text: <FormattedMessage id="Interventions" />,
      });
    }

    let actionMenuIcon, actionMenuVariant;

    switch (true) {
      case this.hasPendingRequest(equipment, RequestTypes.RENEWAL):
        actionMenuIcon = "recycle";
        actionMenuVariant = "warning";
        break;
      case this.hasPendingRequest(equipment, RequestTypes.INTERVENTION):
        actionMenuIcon = "wrench";
        actionMenuVariant = "warning";
        break;
      default:
        break;
    }

    let header = "";

    switch (equipment.owner) {
      case EqOwner.EXTERNAL:
        header = <span className="text-danger"><Icon icon="exclamation-triangle" /> <FormattedMessage id="Owner" />: {this.props.intl.formatMessage({
          id: "EqOwner.quickview." + equipment.owner,
        })}</span>;
        break;
      case EqOwner.INTERNAL_EHPAD:
      case EqOwner.DM_FROM_PSDM:
        header = <span className="text-success"><Icon icon="circle-check" /> <FormattedMessage id="Owner" />: {this.props.intl.formatMessage({
          id: "EqOwner.quickview." + equipment.owner,
        })}</span>;
        break;
      default:
        header = <span className="text-dark"><Icon icon="circle-info" /> <FormattedMessage id="Owner" />: <FormattedMessage id="Undefined" /></span>;
        break;
    }

    const menuAction = <ActionMenu
      items={menuItems}
      variant={actionMenuVariant}
      icon={actionMenuIcon}
      header={header}
      size="2xl"
    />;

    return (
      <tr className="d-flex align-items-center" key={this.props.key}>
        <td className="col text-center">
          <div>
            {this.props.mode !== "quickview" && <div>{img}</div>}
            {this.getMaterialType(equipment.material_type_id).name}
          </div>
        </td>
        <td className="col smallColText">
          <div>
            <strong>{this.getBrandName(equipment.brand_id)}</strong>
          </div>
          <div className="small-font">
            <strong>{this.getModelName(equipment.model_id)}</strong>
          </div>
          <div className="small-font">
            <strong>
              <FormattedMessage id="Intern.Ref.Short" />
            </strong>
          </div>
          <div className="small-font">{equipment.internal_ref}</div>
          <div className="small-font">
            <strong>
              <FormattedMessage id="Serial.Number.Short" />
            </strong>
          </div>
          <div className="small-font">{equipment.serial_nbr}</div>
        </td>
        <td className="col">
          <div>
            <Icon

              title={this.props.intl.formatMessage({ id: "Floor" })}
              icon="turn-up"
            />{" "}
            {equipment.floor === 0 ? (
              <FormattedMessage id="Ground.Floor.Abbr" />
            ) : equipment.floor !== undefined && equipment.floor !== "" ? (
              equipment.floor
            ) : (
              this.props.intl.formatMessage({ id: "Non.Available.Abbr" })
            )}
          </div>
          <div>
            <Icon

              title={this.props.intl.formatMessage({ id: "Room" })}
              icon="bed"
            />{" "}
            {equipment.room !== undefined && equipment.room !== null
              ? equipment.room
              : this.props.intl.formatMessage({ id: "Non.Available.Abbr" })}
          </div>
        </td>
        {this.props.mode !== "quickview" && (
          <td className="col text-center">{equipmentAffectation()}</td>
        )}
        {this.props.mode !== "quickview" && (
          <td className="col text-center">
            {equipment.warranty ? (
              <Icon icon="circle-check" className="text-success" size="xl" />
            ) : (
              <Icon icon="circle-xmark" className="text-danger" size="xl" />
            )}
          </td>
        )}
        <td className="col text-center">
          {this.formatHeading(lastMaintenanceDate)}
        </td>
        <td className="col text-center">
          {this.formatHeading(equipmentState)}
        </td>
        <td className="col text-center">{this.formatHeading(currentOwner)}</td>
        <td className="col text-center">
          {this.formatHeading(materiovigilance)}
        </td>
        <td className="col text-center">
          {menuAction}
        </td>
        {this.state.modal}
      </tr>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    materialsTypes: state.materialsTypes,
    brands: state.brands,
    models: state.models,
    requests: state.requests,
    residents: state.residents,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    //
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(EquipmentRow));
