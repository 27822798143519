import React from "react";
import { connect } from "react-redux";
import { FormattedMessage, injectIntl } from "react-intl";
import { Button, Modal } from "react-bootstrap";

import Util from "../../../../../util/Util";
import StringUtil from "../../../../../util/StringUtil";

import CustomLabel from "../../../../sub/CustomLabel";

import {
  addModel,
  updateModel,
} from "../../../../../actions/configurables/models";

class ModelsAdminModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      name: "",
      associateBrand: "",
      brandId: "",
      simModels: [], // Detect similarities to avoid duplicates

      nameError: null,
      associateBrandError: null,
      disabled: false,
      modal: null,
    };

    if (this.props.modelId) {
      var model = this.getModel(this.props.modelId);

      this.state = {
        name: model.name,
        brandId: model.brand_id,
        simModels: [], // Detect similarities to avoid duplicates

        nameError: null,
        associateBrandError: null,
        disabled: false,
        modal: null,
      };
    }
  }

  getModel(modelId) {
    for (let model of this.props.models) {
      if (model._id === modelId) return model;
    }
  }

  close() {
    this.props.closeModal();
  }

  onChange(fieldName, fieldValue) {
    if (fieldName === "name") {
      // Empty? Return error
      if (Util.emptyString(fieldValue.trim())) {
        return this.setState({
          name: fieldValue,
          nameError: <FormattedMessage id="Brand.Empty.Error" />,
        });
      } else {
        this.checkDuplicate(fieldName, fieldValue);
      }
    } else {
      this.checkDuplicate(fieldName, fieldValue);
    }

    this.setState({
      [fieldName]: fieldValue,
      disabled: false,
    });
  }

  //Check that this model does not already exist (for this brand only - duplicates are allowed among brands)
  checkDuplicate(fieldName, fieldValue) {
    this.setState({ nameError: false });
    let tmpSimModels = [];

    outer: for (let model of this.props.models) {
      // Full duplicate (model and brand)
      if (
        model.name.toUpperCase() ===
        (fieldName === "name" ? fieldValue : this.state.name) &&
        model.brand_id ===
        (fieldName === "brandId" ? fieldValue : this.state.brandId)
      ) {
        return this.setState({
          fieldName: fieldValue,
          nameError: <FormattedMessage id="Model.Already.Exists" />,
        });
      } else {
        let m1 = fieldName === "name" ? fieldValue : this.state.name;

        for (let s of tmpSimModels) {
          if (
            (s[0] === m1 && s[1] === model.name) ||
            (s[0] === model.name && s[1] === m1)
          ) {
            continue outer;
          }
        }
        // console.log(model.name + " / " + m1 + " : " + StringUtil.similarity(model.name, m1));
        if (
          StringUtil.similarity(model.name, m1) > 0.5 &&
          this.state.brandId !== "" &&
          (fieldName === "brandId" ? fieldValue : this.state.brandId) ===
          model.brand_id
        ) {
          tmpSimModels.push([model.name, m1]);
        }
      }
    }

    this.setState({
      simModels: tmpSimModels.length > 0 ? tmpSimModels : [],
    });
  }

  add() {
    this.setState({ disabled: true });

    let data = {
      name: this.state.name.toUpperCase().trim(),
      brand_id: this.state.brandId,
    };

    let callback = () => {
      this.close();
    };

    this.props.onAddModel(data, callback);
  }

  update(fieldName, fieldValue) {
    if (
      !this.props.modelId ||
      this.state.nameError ||
      this.state.associateBrandError
    )
      return;

    let data = {
      modelId: this.props.modelId,
      fieldName: fieldName,
      fieldValue: fieldValue,
    };

    this.props.onUpdateModel(data);
  }

  render() {
    return (
      <React.Fragment>
        <Modal
          show={true}
          onHide={() => this.close()}
          backdrop={"static"}
          size={"lg"}
        >
          <Modal.Header closeButton>
            <Modal.Title>
              {!this.props.modelId ? (
                <FormattedMessage id="Add.Model" />
              ) : (
                <FormattedMessage id="Model.Edit" />
              )}
            </Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <div className="col-12 col-lg-8">
              <div className="form-group row">
                <CustomLabel
                  label={this.props.intl.formatMessage({
                    id: "Brand.Associate",
                  })}
                  htmlFor="associate-brand"
                  required
                />
                <div className="col-12 col-sm-7">
                  {!this.props.modelId ? (
                    <select
                      className="form-control selectlist"
                      value={this.state.brandId || ""}
                      onChange={(e) =>
                        this.onChange("brandId", e.target.value)
                      }
                      onBlur={(e) => this.update("brand_id", e.target.value)}
                    >
                      <option value="">
                        {this.props.intl.formatMessage({
                          id: "Select.Brand",
                        })}
                      </option>
                      {this.props.brands.map((brand) => {
                        return (
                          <option key={brand._id} value={brand._id}>
                            {brand.name}
                          </option>
                        );
                      })}
                    </select>
                  ) : (
                    <select
                      className="form-control selectlist"
                      value={this.state.brandId}
                      disabled={true}
                    >
                      {this.props.brands
                        .filter((brand) => brand._id === this.state.brandId)
                        .map((brand) => {
                          return (
                            <option key={brand._id} value={brand._id}>
                              {brand.name}
                            </option>
                          );
                        })}
                    </select>
                  )}
                  <small className="text-danger">
                    {this.state.associateBrandError}
                  </small>
                </div>
              </div>

              <div className="form-group row">
                <CustomLabel
                  label={this.props.intl.formatMessage({ id: "Name" })}
                  htmlFor="modelName"
                  labelClassName="col-12 col-md-4"
                  required
                />
                <div id="name" className="col-12 col-md-8">
                  <input
                    type="text"
                    className="form-control"
                    id="modelName"
                    value={this.state.name}
                    onChange={(e) =>
                      this.onChange("name", e.target.value.toUpperCase())
                    }
                    onBlur={(e) =>
                      this.update("name", e.target.value.toUpperCase())
                    }
                  />
                  <small className="text-danger">
                    {this.state.nameError}
                  </small>
                </div>
              </div>
            </div>
            {!this.props.modelId &&
              this.state.simModels.length > 0 &&
              !this.state.nameError && (
                <div className="alert alert-warning m-auto" role="alert">
                  <div className="">
                    <FormattedMessage id="Model.Similarities" /> :
                  </div>
                  <ul className="">
                    {this.state.simModels.map((model, index) => (
                      <li key={index}>{model[0]}</li>
                    ))}
                  </ul>
                  <div>
                    <FormattedMessage id="Duplicate.fix" />
                  </div>
                </div>
              )}
          </Modal.Body>

          {!this.props.modelId && (
            <Modal.Footer>
              <Button variant="secondary" onClick={() => this.close()}>
                <FormattedMessage id="Cancel" />
              </Button>
              <Button
                variant="btn btn-info"
                onClick={() => this.add()}
                disabled={
                  this.state.disabled ||
                  this.state.nameError ||
                  this.state.name === "" ||
                  this.state.brandId === ""
                }
              >
                <FormattedMessage id="Add" />
              </Button>
            </Modal.Footer>
          )}
        </Modal>
        {this.state.modal}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    models: state.models,
    brands: state.brands,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onAddModel: (data, successCallback) =>
      dispatch(addModel(data, successCallback)),
    onUpdateModel: (data) => dispatch(updateModel(data)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(ModelsAdminModal));
