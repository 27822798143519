import React from "react";
import { connect } from "react-redux";
import { FormattedMessage, injectIntl } from "react-intl";
import ComboBox from "../sub/ComboBox";
import { addPaymentMethod } from "../../actions/configurables/paymentMethods";
import { addPaymentDeadline } from "../../actions/configurables/paymentDeadlines";
import { updateEstablishmentSettings } from "../../actions/establishments/establishmentSettings";
import Util from "../../util/Util";
import EstablishmentBudgetModal from "./EstablishmentBudgetModal";
import EstablishmentBudgets from "./EstablishmentBudgets";
import MercurialStatus from "../../enums/MercurialStatus";
import CustomLabel from "../sub/CustomLabel";
import Icon from "../sub/Icon";

class EstablishmentConfiguration extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      payment_method_id: "",
      payment_deadline_id: "",
      mercurial_id: "",
      shipping_delay: "",
      min_order_amount: "",
      capacity: "",
      modal: null,
      disabled: false,
    };
    if (this.props.establishmentSettings) {
      this.state = {
        payment_method_id:
          this.props.establishmentSettings.payment_method_id !== undefined
            ? this.props.establishmentSettings.payment_method_id
            : "",
        payment_deadline_id:
          this.props.establishmentSettings.payment_deadline_id !== undefined
            ? this.props.establishmentSettings.payment_deadline_id
            : "",
        mercurial_id:
          this.props.establishmentSettings.mercurial_id !== undefined
            ? this.props.establishmentSettings.mercurial_id
            : "",
        shipping_delay:
          this.props.establishmentSettings.shipping_delay !== undefined
            ? this.props.establishmentSettings.shipping_delay
            : "",
        min_order_amount:
          this.props.establishmentSettings.min_order_amount !== undefined
            ? this.props.establishmentSettings.min_order_amount
            : "",
        capacity:
          this.props.establishmentSettings.capacity !== undefined
            ? this.props.establishmentSettings.capacity
            : "",
      };
    }
  }

  fixWithDefaultValues() {
    if (!this.props.establishmentSettings) return;

    for (let key of Object.keys(this.state)) {
      if (
        Util.emptyString(this.state[key]) &&
        !Util.emptyString(this.props.establishmentSettings[key])
      ) {
        this.setState({ [key]: this.props.establishmentSettings[key] });
      }
    }
  }

  onFieldChange(field, value) {
    // Add maximum capacity to field capacity (number of residents)
    if (field === "capacity" && value > 9999) {
      this.setState({ quantity: 9999 });
    } else {
      this.setState({ [field]: value });
    }
  }

  sendUpdate(field, value) {
    var data = {
      establishmentId: this.props.establishment._id,
      updatedField: field,
      updatedValue: value,
    };

    // Send to BE
    this.props.onUpdateEstablishmentSettings(data, () =>
      this.fixWithDefaultValues()
    );
  }

  openAnnualBudgetModal(year) {
    var budgets = this.props.establishmentSettings
      ? this.props.establishmentSettings.budgets
      : null;

    this.setState({
      modal: (
        <EstablishmentBudgetModal
          budgets={budgets}
          year={year}
          close={() => this.closeModal()}
          onComplete={(budgets) => {
            this.sendUpdate("budgets", budgets);
            this.closeModal();
          }}
        />
      ),
    });
  }

  closeModal() {
    this.setState({
      modal: null,
    });
  }
  onChange(field,value){
    this.setState({
      [field]: value,
    });
    this.sendUpdate(field,value);
  }

  render() {
    var now = new Date();
    let mercurialSelectOptions = this.props.mercurials.map((mercurial) => {
      if (
        new Date(mercurial.start_date) > now ||
        new Date(mercurial.end_date) < now
      )
        return null;
      if (mercurial.status === MercurialStatus.INACTIVE) return null;

      let version = null;
      if (mercurial.version !== 1) version = "(v" + mercurial.version + ")";

      return (
        <option key={mercurial._id} value={mercurial._id}>
          {mercurial.name} {version}{" "}
        </option>
      );
    });

    let budgets = null;
    if (
      this.props.establishmentSettings &&
      this.props.establishmentSettings.budgets &&
      this.props.establishmentSettings.budgets.length > 0
    ) {
      budgets = (
        <EstablishmentBudgets
          budgets={this.props.establishmentSettings.budgets}
          editBudget={(year) => this.openAnnualBudgetModal(year)}
          updateBudgets={(budgets) => this.sendUpdate("budgets", budgets)}
        />
      );
    }

    let residentNumber =
      this.props.establishment !== undefined &&
      this.props.residents.filter(
        (resident) => resident.establishment_id === this.props.establishment._id
      ).length;

    return (
      <React.Fragment>
        <div className="row mb-3">
          <div className="col-12 col-lg-12 col-xl-9">
            <div className="form-group row">
              <CustomLabel
                label={this.props.intl.formatMessage({ id: "Payment.Method" })}
                htmlFor="conf-payment-method"
              />
              <div className="col-12 col-md-5">
                <ComboBox
                  disabled={this.props.user.role === 2}
                  menuPlacement="auto"
                  onChange={(value) =>
                    this.onFieldChange("payment_method_id", value)
                  }
                  onBlur={(value) =>
                    this.sendUpdate("payment_method_id", value)
                  }
                  defaultOption={this.state.payment_method_id}
                  options={this.props.paymentMethods}
                  onCreateOption={(data, successCallback) =>
                    this.props.onAddPaymentMethod(data, successCallback)
                  }
                />
              </div>
            </div>

            <div className="form-group row">
              <CustomLabel
                label={this.props.intl.formatMessage({
                  id: "Payments.Deadline",
                })}
                htmlFor="conf-payment-deadline"
              />
              <div className="col-12 col-md-5">
                <ComboBox
                  disabled={this.props.user.role === 2}
                  menuPlacement="auto"
                  onChange={(value) =>
                    this.onFieldChange("payment_deadline_id", value)
                  }
                  onBlur={(value) =>
                    this.sendUpdate("payment_deadline_id", value)
                  }
                  defaultOption={this.state.payment_deadline_id}
                  options={this.props.paymentDeadlines}
                  onCreateOption={(data, successCallback) =>
                    this.props.onAddPaymentDeadline(data, successCallback)
                  }
                />
              </div>
            </div>

            <div className="form-group row">
              <CustomLabel
                label={this.props.intl.formatMessage({ id: "Mercurial" })}
                htmlFor="conf-mercurial"
              />
              <div className="col-12 col-md-5">
                <select
                  disabled={this.props.user.role === 2}
                  id="conf-mercurial"
                  className="form-control w-100"
                  defaultValue={this.state.mercurial_id}
                  onChange={(e) =>
                    this.onFieldChange("mercurial_id", e.target.value)
                  }
                  onBlur={(e) =>
                    this.sendUpdate("mercurial_id", e.target.value)
                  }
                >
                  <option value="">
                    {this.props.intl.formatMessage({ id: "Select" })}
                  </option>
                  {mercurialSelectOptions}
                </select>
              </div>
            </div>

            <div className="form-group row">
              <CustomLabel
                label={this.props.intl.formatMessage({ id: "Shipping.Delay" })}
                htmlFor="shippingDelay"
              />
              <div className="col-12 col-md-5">
                <input
                  disabled={this.props.user.role === 2}
                  type="number"
                  className="form-control"
                  id="shippingDelay"
                  min="0"
                  value={this.state.shipping_delay}
                  onChange={(e) =>
                    this.onFieldChange("shipping_delay", e.target.value)
                  }
                  onBlur={(e) =>
                    this.sendUpdate("shipping_delay", e.target.value)
                  }
                />
              </div>
            </div>

            <div className="form-group row">
              <CustomLabel
                label={this.props.intl.formatMessage({ id: "Min.Price" })}
                htmlFor="minPrice"
              />
              <div className="col-12 col-md-5">
                <input
                  disabled={this.props.user.role === 2}
                  type="number"
                  className="form-control"
                  id="minPrice"
                  min="0"
                  value={this.state.min_order_amount}
                  onChange={(e) =>
                    this.onFieldChange("min_order_amount", e.target.value)
                  }
                  onBlur={(e) =>
                    this.sendUpdate("min_order_amount", e.target.value)
                  }
                />
              </div>
            </div>
            <div className="form-group row">
              <CustomLabel
                label={this.props.intl.formatMessage({ id: "Client.Capacity" })}
                htmlFor="capacity"
              />
              <div className="col-12 col-md-5">
                <input
                  disabled={this.props.user.role === 2}
                  type="number"
                  className="form-control"
                  id="capacity"
                  min="0"
                  max="9999"
                  defaultValue={this.state.capacity}
                  onChange={(e) =>
                    this.onFieldChange("capacity", e.target.value)
                  }
                  onBlur={(e) => this.sendUpdate("capacity", e.target.value)}
                />
              </div>
            </div>
            {this.props.establishment !== undefined && (
              <div className="form-group row">
                <CustomLabel
                  label={this.props.intl.formatMessage({
                    id: "Resident.Number",
                  })}
                  htmlFor="clientNumber"
                />
                <div className="col-12 col-md-5">
                  <input
                    disabled={true}
                    type="number"
                    className="form-control"
                    id="clientNumber"
                    min="0"
                    value={residentNumber}
                  />
                </div>
              </div>
            )}
            <div className="form-group row">
              <CustomLabel
                label={this.props.intl.formatMessage({ id: "Annual.Budgets" })}
                htmlFor="shippingCost"
                labelClassName="col-10 col-sm-11 col-md-7"
              />

              <div className="col-2 col-sm-1 col-md-5">
                {this.props.user.role !== 2 && (
                  <Icon
                    id={"add-Budget"}
                    className="p-0 pt-2"
                    onClick={(e) => this.openAnnualBudgetModal()}
                    hover={<FormattedMessage id="Add" />}
                    icon="square-plus" clickable size="xl"
                  />
                )}
              </div>
              
            </div>

            {budgets}
          </div>
        </div>

        {this.state.modal}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    paymentMethods: state.paymentMethods,
    paymentDeadlines: state.paymentDeadlines,
    mercurials: state.mercurials,
    user: state.user,
    residents: state.residents,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onAddPaymentMethod: (data, successCallback) =>
      dispatch(addPaymentMethod(data, successCallback)),
    onAddPaymentDeadline: (data, successCallback) =>
      dispatch(addPaymentDeadline(data, successCallback)),
    onUpdateEstablishmentSettings: (data, successCallback) =>
      dispatch(updateEstablishmentSettings(data, successCallback)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(EstablishmentConfiguration));
