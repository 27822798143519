import APIUrl from "../../APIUrl";
import axios from "axios";

export const GET_PAYMENT_METHODS = "GET_PAYMENT_METHODS";

function getPaymentMethodsAction(paymentMethods) {
  return { type: GET_PAYMENT_METHODS, paymentMethods: paymentMethods };
}

export const getPaymentMethods = function () {
  return function (dispatch) {
    return axios
      .get(APIUrl.getPaymentMethods)
      .then(function (response) {
        dispatch(getPaymentMethodsAction(response.data));
      })
      .catch(function (err) {
        throw err;
      });
  };
};

export const addPaymentMethod = function (data, successCallback) {
  return function (dispatch) {
    return axios
      .post(APIUrl.addPaymentMethod, data)
      .then(function (response) {
        dispatch(getPaymentMethods());
        successCallback(response.data);
      })
      .catch(function (err) {
        throw err;
      });
  };
};
