import React from "react";
import { connect } from "react-redux";
import { FormattedMessage } from "react-intl";
import { Card } from "react-bootstrap";

class EstablishmentOverview extends React.Component {
  render() {
    const { establishment, admin } = this.props;

    if (!establishment) return null;

    const establishmentNode = (
      <Card>
        <Card.Header>
          <strong>
            <FormattedMessage id="Establishment" />
          </strong>
        </Card.Header>
        <Card.Body>
          <Card.Title>{establishment.name}</Card.Title>
          {establishment.address && <div>{establishment.address}</div>}
          {(establishment.postal_code || establishment.city) && (
            <div>
              {establishment.postal_code || ""} {establishment.city || ""}
            </div>
          )}
        </Card.Body>
      </Card>
    );

    const salesRepNode = admin ? (
      <Card>
        <Card.Header>
          <strong>
            <FormattedMessage id="Commercial" />
          </strong>
        </Card.Header>
        <Card.Body>
          <Card.Title>{admin.first_name} {admin.name.toUpperCase()}</Card.Title>
          {<div>{admin.email}</div>}
          {(admin.phone) && <div>{admin.phone}</div>}
        </Card.Body>
      </Card>
    ) : null;
  
    return (
      <table className="table mb-3 table-borderless" style={{ width: "100%" }}>
        <tbody>
          <tr>
          <td style={admin ? { width: "50%" } : { width: "100%" }} className="text-center pl-0">
            {establishmentNode}
          </td>
          {admin && <td style={{ width: "50%" }} className="text-center pl-0">
            {salesRepNode}
          </td>}
          </tr>
        </tbody>
      </table>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    //
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    //
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EstablishmentOverview);
