import React from "react";
import { nanoid } from "nanoid";
import SignatureCanvas from "react-signature-canvas";

export default class SignatureInput extends React.Component {
  constructor(props) {
    super(props);

    this.id = "signature-" + nanoid();

    this.state = {
      img: this.props.img || null,
    };
  }

  // We need this to resize the canvas to parent container
  componentDidMount() {
    this.resizeCanvas();

    window.addEventListener("resize", () => this.resizeCanvas());
  }

  // AJOUT pour forcer le resize sinon les bordures de la signature disparaissent.
  componentDidUpdate(prevProps) {
    if (prevProps !== this.props) {
      this.resizeCanvas();

      window.addEventListener("resize", () => this.resizeCanvas());
    }
  }

  resizeCanvas() {
    if (!this.sigCanvas) return;

    var img = this.sigCanvas.toDataURL();
    if (img) this.setState({ img: img }, this.fixCanvas);

    var canvas = document.getElementById(this.id);
    var container = document.getElementById(this.props.containerId);
    if (!canvas || !container) return;
    canvas.width = this.props.width || container.offsetWidth;
    canvas.height = this.props.height || container.offsetHeight;
  }

  fixCanvas() {
    if (this.state.img) {
      this.redrawImg(this.state.img);
      this.setState({});
    }
  }

  redrawImg(img) {
    if (!img) return;

    var canvas = document.getElementById(this.id),
      ctx = canvas.getContext("2d");
    var image = new Image();
    image.onload = function () {
      ctx.drawImage(image, 0, 0, canvas.width, canvas.height);
    };
    image.src = img;
  }
  clear() {
    this.sigCanvas.clear();
    this.props.onEnd(null);
  }

  render() {
    return (
      <SignatureCanvas
        ref={(ref) => {
          this.sigCanvas = ref;
        }}
        onEnd={() => this.props.onEnd(this.sigCanvas.toDataURL())}
        canvasProps={{ id: this.id, height: "auto" }}
        backgroundColor="white"
        clearOnResize={false}
      />
    );
  }
}
