import React from "react";
import { connect } from "react-redux";
import DateUtil from "../../util/DateUtil";
import { FormattedMessage, injectIntl } from "react-intl";
import ExcelUtil from "../../util/ExcelUtil";
import FileUtil from "../../util/FileUtil";
import MercurialStatus from "../../enums/MercurialStatus";
import { getProductsByMercurialIdAdmin } from "../../actions/products/products";
import MercurialsUtil from "../../util/mercurialsUtil.js";
import { syncWithCrm } from "../../actions/mercurials/mercurials";
import ActionMenu from "../sub/ActionMenu";
import Icon from "../sub/Icon";

class MercurialRow extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: "icon-big text-danger",
      progress: 0,
      processing: false
    };

    if (
      this.props.mercurial.synced_with_crm &&
      this.props.mercurial.synced_with_crm === true
    ) {
      this.state = {
        loading: "icon-big text-success",
        progress: 0,
      };
    }
  }

  getMercurialProducts(mercurial) {
    let products = [];

    for (let product of this.props.products) {
      if (product.mercurial_id === mercurial._id) {
        products.push(product);
      }
    }
    return products;
  }

  getMercurialProductsFromBE(mercurial) {
    let mercurialId = mercurial._id;
    let products = Promise.resolve(
      this.props.onGetProductsByMercurialIdAdmin(mercurialId)
    );
    return products;
  }

  download(mercurial) {

    this.setState({ processing: true });

    // Convert data to Excel format
    let products = this.getMercurialProductsFromBE(mercurial);
    let excelData;
    let currentMercurial = new MercurialsUtil(this.props.company);

    products.then((productsFromBE) => {
      let pro = [];

      for (let product of productsFromBE) {
        if (product.mercurial_id === mercurial._id) {
          pro.push(product);
        }
      }

      excelData = ExcelUtil.toExcel(pro, currentMercurial.getMercurialMapping(), currentMercurial.getExcludedExportFields());

      // Sanitize the file name
      let fileName = FileUtil.toFileName(mercurial.name);

      // Save the file
      ExcelUtil.save(excelData, fileName);
      this.setState({ processing: false });
    });
  }

  syncIcon(crmSoftware, status) {

    let hover;

    switch (status) {
      case true:
        hover = "API.CRM.Sync.Ok";
        break;
      default:
        hover = "API.CRM.Sync.Nok";
        break;
    }

    return <Icon
      icon={status ? "circle-check" : "circle-xmark"}
      size="xl"
      className={status ? "text-success" : "text-danger"}
      variant={!status && "far"}
      hover={<FormattedMessage
        id={hover}
        values={{ crmSoftware: crmSoftware }}
      />}
    />

  }

  checkProductsExistByMercurialId(mercurialId) {
    for (let i = 0; i < this.props.products.length; i++) {
      if (this.props.products[i].mercurial_id === mercurialId) {
        return false;
      }
    }
    return true;
  }

  render() {
    if (!this.props.mercurial) return null;

    var mercurial = this.props.mercurial;
    var products = this.getMercurialProducts(mercurial);
    var hasGreaterVersion = false;

    // https://stackoverflow.com/questions/37308719/react-component-wait-for-required-props-to-render
    // https://zaiste.net/posts/javascript-destructuring-assignment-default-values/
    const { enabled: crmEnabled = false, software: crmSoftware = null } = this
      .props.company.crm
      ? this.props.company.crm
      : {};

    for (let m of this.props.mercurials) {
      if (m.name === mercurial.name && m.version > mercurial.version) {
        hasGreaterVersion = true;
        break;
      }
    }

    let versionXS = null;
    if (hasGreaterVersion || mercurial.version > 1)
      versionXS = (
        <span className="d-table-cell d-md-none">
          (<FormattedMessage id="Version" /> {mercurial.version})
        </span>
      );

    let isOutDated = new Date(mercurial.end_date) < new Date();

    const status = isOutDated ? MercurialStatus.INACTIVE : mercurial.status;
    const isInactive = status === MercurialStatus.INACTIVE;

    let menuItems = [];

    menuItems.push(
      // Show/hide products
      {
        id: "see" + mercurial._id,
        icon: !isOutDated && !isInactive ? "eye" : "eye-slash",
        action: () => {
          if (!isOutDated && !isInactive) {
            if (this.checkProductsExistByMercurialId(mercurial._id)) {
              this.props.onGetProductsByMercurialIdAdmin(mercurial._id);
              window.location.hash = "/home/families/" + mercurial._id;
            } else {
              window.location.hash = "/home/families/" + mercurial._id;
            }
          }
        },
        disabled: isOutDated && isInactive,
        text: <FormattedMessage id="Display.Active.Mercurial" />,
      },
      {
        id: "upl" + mercurial._id,
        icon: "upload",
        action: () =>
          !isOutDated &&
          !isInactive &&
          this.props.openMercurialUpdModal(mercurial),
        disabled:
          (isOutDated && isInactive) || this.state.loading === "progress-bar",
        text: <FormattedMessage id="Mercurials.Update" />,
      },
      {
        id: "dwl" + mercurial._id,
        icon: "download",
        action: () => this.download(mercurial),
        text: <FormattedMessage id="Export.Mercurial" />,
      },
      {
        id: "images" + mercurial._id,
        icon: "file-image",
        action: () =>
          !isOutDated && !isInactive && this.props.openImgsModal(mercurial),
        disabled: isOutDated && isInactive,
        text: <FormattedMessage id="Import.Image.Mercurial" />,
      },
      {
        id: "delete" + mercurial._id,
        icon: "trash",
        action: () =>
          this.state.loading !== "progress-bar" &&
          this.props.openLayersModal(mercurial),
        disabled: this.state.loading === "progress-bar",
        text: <FormattedMessage id="Delete" />,
      },
      // /!\ Warning: We check first if crm is enabled or not before rendering menu element
      crmEnabled && {
        id: "sync" + mercurial._id,
        icon: "refresh",
        action: () => {
          if (this.state.loading !== "progress-bar") {
            this.props.openSyncModal(
              products,
              crmSoftware,
              crmEnabled,
              this.props.mercurial
            );
          }
        },
        disabled:
          this.state.loading === "progress-bar" ||
          (this.props.mercurial.synced_with_crm &&
            this.props.mercurial.synced_with_crm === true),
        text:
          this.props.mercurial.synced_with_crm &&
            this.props.mercurial.synced_with_crm === true ? (
            <FormattedMessage id="API.CRM.Already.Synchronized.With" />
          ) : (
            <FormattedMessage
              id="API.CRM.Synchronize.With"
              values={{ crmSoftware: crmSoftware }}
            />
          ),
      }
    );

    let menuAction = <ActionMenu processing={this.state.processing} items={menuItems} />;

    return (
      <React.Fragment>
        <tr
          className={(isOutDated || isInactive) ? "font-italic text-muted" : ""}
        >
          <td>
            {mercurial.name}
            {versionXS}
          </td>
          <td className="d-none d-md-table-cell">
            {DateUtil.toDate(mercurial.start_date)}
          </td>
          <td className="d-none d-md-table-cell">
            {DateUtil.toDate(mercurial.end_date)}
          </td>
          <td className="d-none d-sm-table-cell">{mercurial.file_name}</td>
          <td className="d-none d-md-table-cell">{mercurial.version}</td>
          <td>
            {this.props.intl.formatMessage({
              id: "Mercurial.Status." + status,
            })}
          </td>
          {crmEnabled && (
            <td>
              {this.state.loading !== "progress-bar" &&
                !isInactive &&
                products.length !== 0 &&
                this.props.mercurial.synced_with_crm &&
                this.props.mercurial.synced_with_crm === true &&
                this.syncIcon(
                  crmSoftware,
                  true
                )}
              {this.state.loading !== "progress-bar" &&
                !isInactive &&
                products.length !== 0 &&
                this.props.mercurial.synced_with_crm !== true &&
                this.syncIcon(
                  crmSoftware,
                  false
                )}
              {this.state.loading !== "progress-bar" &&
                !isInactive &&
                products.length === 0 &&
                this.props.mercurial.synced_with_crm &&
                this.props.mercurial.synced_with_crm === true &&
                this.syncIcon(
                  crmSoftware,
                  true
                )}
              {this.state.loading !== "progress-bar" &&
                !isInactive &&
                products.length === 0 &&
                this.props.mercurial.synced_with_crm !== true &&
                this.syncIcon(
                  crmSoftware,
                  false
                )}
              {this.state.loading !== "progress-bar" &&
                isInactive &&
                this.props.mercurial.synced_with_crm &&
                this.props.mercurial.synced_with_crm === true &&
                this.syncIcon(
                  crmSoftware,
                  true
                )}
              {this.state.loading !== "progress-bar" &&
                isInactive &&
                this.props.mercurial.synced_with_crm !== true &&
                this.syncIcon(
                  crmSoftware,
                  false
                )}
            </td>
          )}
          {!this.props.limit && (
            <td className="text-center align-middle">{menuAction}</td>
          )}
        </tr>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    products: state.products,
    company: state.company,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onGetProductsByMercurialIdAdmin: (mercurialId) =>
      dispatch(getProductsByMercurialIdAdmin(mercurialId)),
    onSyncWithCrm: (data) => dispatch(syncWithCrm(data)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(MercurialRow));
