import React from "react";
import { connect } from "react-redux";
import { injectIntl } from "react-intl";
import OrdersSettingsAdmin from "./ordersSettings/OrdersSettingsAdmin";
import GeneralSettingsAdmin from "./GeneralSettingsAdmin";
import PromotionalOperations from "./promotionalOperations/PromotionalOperations";
import Materiovigilance from "./materiovigilance/Materiovigilance";
import Collaborators from "../../settings/admin/collaborators/Collaborators";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import MenuSettingsEquipmentsAdmin from "./EquipmentsSettings/MenuSettingsEquipmentsAdmin";

class MenuSettingsAdmin extends React.Component {
  render() {
    if (Object.keys(this.props.ordersSettingsAdmin).length === 0) return null;
    if (Object.keys(this.props.generalSettings).length === 0) return null;

    return (
      <Tabs defaultActiveKey="general" id="configurationTabs">
        <Tab
          eventKey="general"
          title={this.props.intl.formatMessage({ id: "General" })}
        >
          <GeneralSettingsAdmin generalSettings={this.props.generalSettings} />
        </Tab>
        <Tab
          eventKey="collaborator"
          title={this.props.intl.formatMessage({ id: "Collaborators" })}
        >
          <Collaborators collaborators={this.props.collaborators} />
        </Tab>

        <Tab
          eventKey="settings-mercurial"
          title={this.props.intl.formatMessage({ id: "Mercurials.Management" })}
        >
          <Tabs defaultActiveKey="budget" id="settings-cost-budget">
            <Tab
              eventKey="budget"
              title={this.props.intl.formatMessage({ id: "Cost.Management" })}
            >
              <OrdersSettingsAdmin
                ordersSettings={this.props.ordersSettingsAdmin}
              />
            </Tab>
            <Tab
              eventKey="promo"
              title={this.props.intl.formatMessage({
                id: "Promotional.Operations",
              })}
            >
              <PromotionalOperations
                promoOperations={this.props.promoOperations}
              />
            </Tab>
          </Tabs>
        </Tab>
        <Tab
          eventKey="dm"
          title={this.props.intl.formatMessage({ id: "Equipments" })}
        >
          <MenuSettingsEquipmentsAdmin />
        </Tab>
        <Tab
          eventKey="materiovigilance"
          title={this.props.intl.formatMessage({ id: "Materiovigilance" })}
        >
          <Materiovigilance promoOperations={this.props.promoOperations} />
        </Tab>
      </Tabs>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    ordersSettingsAdmin: state.ordersSettingsAdmin,
    user: state.user,
    generalSettings: state.generalSettings,
    promoOperations: state.promoOperations,
    collaborator: state.collaborator,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    //
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(MenuSettingsAdmin));
