const EquipmentsMapping = {
  type: "TYPE",
  internal_ref: "REF",
  brand: "MARQUE",
  model: "MODELE",
  serial_nbr: "NUM SERIE",
  purchase_date: "DATE ACHAT",
  owner: "PROPRIETAIRE",
  warranty: "GARANTIE",
  floor: "ETAGE",
  room: "CHAMBRE",
};

export default EquipmentsMapping;
