import React from "react";
import { connect } from "react-redux";
import { FormattedMessage, injectIntl } from "react-intl";
import EqMateriovigilance from "../../../../enums/EqMateriovigilance";
import {
  addEquipementToMateriovigilance,
  updateEquipementToMateriovigilance,
  addDocMateriovigilance,
  removeMateriovigilanceFile,
  getMateriovigilanceEquipement,
} from "../../../../actions/settings/materiovigilance/materiovigilance";
import Util from "../../../../util/Util";
import FileUtil from "../../../../util/FileUtil";
import FileDropZone from "../../../sub/FileDropZone";
import APIUrl from "../../../../APIUrl";
import ModalManager from "../../../sub/modals/ModalManager";
import DateUtil from "../../../../util/DateUtil";
import CustomLabel from "../../../sub/CustomLabel";
import { NotificationManager } from "react-notifications";
import Icon from "../../../sub/Icon";
import "react-notifications/lib/notifications.css";
import { Button, Modal } from "react-bootstrap";
import TextareaCounter from 'react-textarea-counter';

class MateriovigilanceModalAdmin extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      name: "",
      checkpoints: [],
      file: null,

      nameError: null,
      disabled: false,
      modal: null,

      //**** Materiovigilance data *********/
      brand_id: undefined,
      model_id: undefined,
      informations: undefined,
      materiovigilance: "",
      doc_url: undefined,
      date: undefined,

      materioVigilanceDoc: undefined,
      emailNotif: true,
    };

    if (this.props.materiovigilanceLoaded) {
      this.state = {
        materiovigilance_id: this.props.materiovigilanceLoaded._id,
        brand_id: this.props.brandId,
        model_id: this.props.materiovigilanceLoaded.model_id,
        materiovigilance: parseInt(
          this.props.materiovigilanceLoaded.materiovigilance
        ),
        date: this.props.materiovigilanceLoaded.date,
        doc_url: this.props.materiovigilanceLoaded.doc_url,
        informations: this.props.materiovigilanceLoaded.informations,
        emailNotif: true,
      };
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.materiovigilance !== this.props.materiovigilance) {
      this.props.closeModal();
    }
  }

  openConfModal(title, content, successCallback) {
    this.setState({
      modal: (
        <ModalManager
          showModal={true}
          title={title}
          content={content}
          buttonRed={true}
          successCallback={successCallback}
          closeModal={() => this.closeModal()}
          modalType="confirmation"
        />
      ),
    });
  }

  getModels(brandId, models) {
    let results = [];

    for (let model of models) {
      if (model.brand_id === brandId) results.push(model);
    }

    return results;
  }

  getModel(modelId) {
    for (let model of this.props.models) {
      if (model._id === modelId) return model;
    }
  }

  close() {
    this.props.closeModal();
  }

  closeModal() {
    this.setState({ modal: null });
  }

  handleCheck = () => {
    this.setState({
      emailNotif: !this.state.emailNotif,
    });
  };

  //*************** NOTIFICATION GESTION FUNCTION ******************/

  NotifAllReadyInMateriovigilance = () => {
    let allReadyInMateriovigilanceList = this.props.intl.formatMessage({
      id: "Materiovigilance.AllReadyExist",
    });

    NotificationManager.error(allReadyInMateriovigilanceList);
  };

  NotifEmptyFieldMateriovigilance = () => {
    let emptyFieldMateriovigilance = this.props.intl.formatMessage({
      id: "Materiovigilance.EmptyField",
    });

    NotificationManager.error(emptyFieldMateriovigilance);
  };
  NotifNoChangeMateriovigilance = () => {
    let noChangeMateriovigilance = this.props.intl.formatMessage({
      id: "Materiovigilance.Nochange",
    });

    NotificationManager.error(noChangeMateriovigilance);
  };

  //******************* FORM GESTION FUNCTION ******************/

  onDropFile(file) {
    let formData = new FormData();
    formData.append(file.name, file, "materioVigilance");

    this.setState({ materioVigilanceDoc: formData, disabledDropZone: true });
  }

  onChange(field, value) {
    if (field === "brand_id") {
      var model = this.getModel(this.state.model_id);
      if (!model || model.brand_id !== value) this.setState({ model_id: "" });
    }
    if (field === "brand_id" && !Util.emptyString(value))
      this.setState({ brandIdError: null });
    if (field === "model_id" && !Util.emptyString(value))
      this.setState({ modelIdError: null });

    return this.setState({ [field]: value });
  }

  AddEquipementToMateriovigilance = () => {
    if (
      this.state.model_id !== undefined &&
      this.state.brand_id !== undefined &&
      this.state.materiovigilance !== "" &&
      this.state.date !== undefined &&
      this.state.date !== ""
    ) {
      if (this.props.materiovigilanceLoaded === undefined) {
        let MateriovigilanceEquipement = {
          model_id: this.state.model_id,
          brand_id: this.state.brand_id,
          materiovigilance: parseInt(this.state.materiovigilance),
          informations: this.state.informations,
          doc_url: this.state.doc_url,
          date: this.state.date,
          emailNotif: this.state.emailNotif,
        };
        var successCallback = (res) => {
          if (res === "AllReadySet") {
            this.NotifAllReadyInMateriovigilance();
          } else {
            if (this.state.materioVigilanceDoc !== undefined) {
              MateriovigilanceEquipement._id = res._id;
              this.props.onAddDocMateriovigilance(
                MateriovigilanceEquipement._id,
                this.state.materioVigilanceDoc,
                successCallback
              );
            }
          }
          this.props.closeModal();
        };
        this.props.onAddEquipementToMateriovigilance(
          MateriovigilanceEquipement,
          successCallback
        );
      } else if (this.props.materiovigilanceLoaded !== undefined) {
        if (
          this.state.materiovigilance !==
          this.props.materiovigilanceLoaded.materiovigilance ||
          this.state.date !== this.props.materiovigilanceLoaded.date ||
          this.state.materioVigilanceDoc !== undefined ||
          this.state.doc_url !== this.props.materiovigilanceLoaded.doc_url ||
          this.state.informations !==
          this.props.materiovigilanceLoaded.informations
        ) {
          let MateriovigilanceEquipement = {
            materiovigilanceID: this.props.materiovigilanceLoaded._id,
            model_id: this.state.model_id,
            brand_id: this.state.brand_id,
            materiovigilance: parseInt(this.state.materiovigilance),
            informations: this.state.informations,
            doc_url: this.state.doc_url,
            date: this.state.date,
            emailNotif: this.state.emailNotif,
          };

          successCallback = (res) => {
            if (this.state.materioVigilanceDoc === undefined) {
              this.props.closeModal();
            } else {
              if (this.state.materioVigilanceDoc !== undefined) {
                MateriovigilanceEquipement._id =
                  this.props.materiovigilanceLoaded._id;
                this.props.onAddDocMateriovigilance(
                  this.props.materiovigilanceLoaded._id,
                  this.state.materioVigilanceDoc,
                  successCallback
                );
              }
            }
          };
          this.props.onUpdateEquipementToMateriovigilance(
            MateriovigilanceEquipement,
            successCallback
          );
        } else {
          this.NotifNoChangeMateriovigilance();
        }
      }
    } else {
      this.NotifEmptyFieldMateriovigilance();
    }
  };

  //******************* RENDER ******************/
  render() {
    //***** Delete materiovigilance document modal setup
    var modalTitle = (
      <span className="text-red">
        <FormattedMessage id="Delete.Doc" />
      </span>
    );
    var modalContent = (
      <FormattedMessage id="Document.Confirm.Message" />
    );
    var successCallback = () => {
      this.props.onRemoveMateriovigilanceFile(
        this.props.materiovigilanceLoaded._id,
        (successCallback = (res) => {
          if (res.status === 200) {
            this.setState({ doc_url: undefined });
            // this.props.onGetMateriovigilanceEquipement()
          }
        })
      );
      // this.closeModal()
    };
    // console.log(successCallback)
    var mvClassName = "";
    if (parseInt(this.state.materiovigilance) === 0)
      mvClassName = "alert-success";
    if (parseInt(this.state.materiovigilance) === 1)
      mvClassName = "alert-danger";
    else if (parseInt(this.state.materiovigilance) === 2)
      mvClassName = "alert-info";
    else if (parseInt(this.state.materiovigilance) === 3)
      mvClassName = "alert-warning";

    var eqMateriovigilanceNode = Object.values(EqMateriovigilance).map(
      (option) => {
        if (option !== 0) {
          return (
            <option key={option} value={option}>
              {this.props.intl.formatMessage({
                id: "EqMateriovigilance." + option,
              })}
            </option>
          );
        } else {
          return null;
        }
      }
    );
    var brandList = this.props.brands.map((option) => {
      return (
        <option key={option._id} value={option._id}>
          {option.name}
        </option>
      );
    });
    var modelList = this.getModels(this.state.brand_id, this.props.models).map(
      (option) => {
        if (
          this.props.materiovigilance.filter((MV) => MV.model_id === option._id)
            .length === 0
        ) {
          return (
            <option key={option._id} value={option._id}>
              {option.name}
            </option>
          );
        } else {
          return (
            <option key={option._id} value={option._id} disabled={true}>
              {option.name}
            </option>
          );
        }
      }
    );
    return (
      <>
        <Modal
          show={true}
          onHide={() => this.close()}
          backdrop={"static"}
          size="xl"
        >
          <Modal.Header closeButton>
            <Modal.Title>
              <FormattedMessage id="Materiovigilance.Add" />
            </Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <div className="row">
              <div className="col-6 col-lg-6">
                <CustomLabel
                  label={
                    this.props.intl.formatMessage({
                      id: "Date.Notification",
                    }) + " :"
                  }
                  htmlFor="date"
                  labelClassName={this.props.labelClassName}
                  required={this.props.required}
                />
                <div className="col-12 pb-3">
                  <input
                    className="form-control"
                    type="date"
                    id="date"
                    autoComplete="off"
                    defaultValue={this.state.date}
                    onChange={(e) => this.onChange("date", e.target.value)}
                    onBlur={(e) => this.onChange("date", e.target.value)}
                    max={DateUtil.toyyyyMMdd(Date.now()).toString()}
                  />
                </div>
                <CustomLabel
                  label={
                    this.props.intl.formatMessage({ id: "Brand" }) + " :"
                  }
                  htmlFor="equipment-brand"
                  labelClassName={this.props.labelClassName}
                  required={this.props.required}
                />
                <div id="equipment-brand" className="col-12 pb-3">
                  <select
                    className={"form-control"}
                    onChange={(e) =>
                      this.onChange("brand_id", e.target.value)
                    }
                    onBlur={(e) => this.onChange("brand_id", e.target.value)}
                    defaultValue={this.state.brand_id}
                    disabled={
                      this.props.materiovigilanceLoaded === undefined
                        ? false
                        : true
                    }
                  >
                    <option value="">
                      {this.props.intl.formatMessage({ id: "Select" })}...
                    </option>
                    {brandList}
                  </select>
                  <div className="text-danger">
                    <small>{this.state.brandIdError}</small>
                  </div>
                </div>

                <CustomLabel
                  label={
                    this.props.intl.formatMessage({ id: "Model" }) + " :"
                  }
                  htmlFor="equipment-model"
                  labelClassName={this.props.labelClassName}
                  required={this.props.required}
                />
                <div id="equipment-model" className="col-12 pb-3">
                  <select
                    className={"form-control"}
                    onChange={(e) =>
                      this.onChange("model_id", e.target.value)
                    }
                    onBlur={(e) => this.onChange("model_id", e.target.value)}
                    defaultValue={this.state.model_id}
                    disabled={
                      this.props.materiovigilanceLoaded === undefined
                        ? false
                        : true
                    }
                  >
                    <option value="">
                      {this.props.intl.formatMessage({ id: "Select" })}...
                    </option>
                    {modelList}
                  </select>
                  <div className="text-danger">
                    <small>{this.state.modelIdError}</small>
                  </div>
                </div>
                <CustomLabel
                  label={
                    this.props.intl.formatMessage({
                      id: "Materiovigilance.Status",
                    }) + " :"
                  }
                  htmlFor="equipment-materiovigilance"
                  labelClassName={this.props.labelClassName}
                  required={this.props.required}
                />
                <div
                  id="equipment-materiovigilance"
                  className="col-sm-12 pb-2"
                >
                  <select
                    id="equipment-materiovigilance"
                    className={"form-control " + mvClassName}
                    onChange={(e) =>
                      this.onChange("materiovigilance", e.target.value)
                    }
                    onBlur={(e) =>
                      this.onChange("materiovigilance", e.target.value)
                    }
                    defaultValue={this.state.materiovigilance}
                  >
                    <option value="">
                      {this.props.intl.formatMessage({ id: "Select" })}...
                    </option>
                    {eqMateriovigilanceNode}
                  </select>
                </div>

                <div className="d-inline">
                  <input
                    type="checkbox"
                    id="emailNotif"
                    defaultChecked={this.state.emailNotif}
                    onClick={this.handleCheck}
                  />
                  <CustomLabel
                    label={
                      this.props.intl.formatMessage({
                        id: "Materiovigilance.Email.Notif",
                      }) + " :"
                    }
                    htmlFor="emailNotif"
                    labelClassName="col-form-label ml-2"
                  />
                </div>
              </div>
              <div className="col-6 col-lg-6">
                <CustomLabel
                  label={
                    this.props.intl.formatMessage({
                      id: "Associated.Documentation",
                    }) + " :"
                  }
                  htmlFor="name"
                  labelClassName={this.props.labelClassName}
                />
                <div id="name" className="col-12">
                  {(this.state.doc_url === undefined ||
                    this.state.doc_url === null) && (
                      <FileDropZone
                        onDropFile={(file) => this.onDropFile(file)}
                        acceptedExtensions={["jpg", "jpeg", "png", "pdf"]}
                        multiple={false}
                      />
                    )}
                  {this.state.doc_url !== undefined &&
                    this.state.doc_url !== null &&
                    this.props.materiovigilanceLoaded !== undefined && (
                      <div
                        key={this.props.materiovigilanceLoaded._id}
                        className="space-between dotted"
                      >
                        <a
                          role="button"
                          href={
                            APIUrl.getMateriovigilanceFile +
                            this.props.materiovigilanceLoaded._id +
                            "/" +
                            this.props.materiovigilanceLoaded.doc_url.split(
                              "/"
                            )[
                            this.props.materiovigilanceLoaded.doc_url.split(
                              "/"
                            ).length - 1
                            ] +
                            "?token=" +
                            APIUrl.jwtToken
                          }
                          rel="noopener noreferrer"
                          download={
                            this.props.materiovigilanceLoaded._id +
                            "." +
                            this.props.materiovigilanceLoaded.doc_url.split(
                              "."
                            )[1]
                          }
                          target={"_blank"}
                        >
                          {this.props.materiovigilanceLoaded.doc_url.length >
                            30
                            ? this.props.materiovigilanceLoaded._id +
                            "." +
                            FileUtil.extension(
                              this.props.materiovigilanceLoaded.doc_url
                            )
                            : this.props.materiovigilanceLoaded.doc_url}
                        </a>
                        <Icon
                          id={
                            "delete" +
                            this.props.materiovigilanceLoaded.model_id
                          }
                          className="icon-small"
                          icon="trash" clickable
                          onClick={(e) =>
                            this.openConfModal(
                              modalTitle,
                              modalContent,
                              successCallback
                            )
                          }
                        />
                      </div>
                    )}
                </div>
                <CustomLabel
                  label={
                    this.props.intl.formatMessage({ id: "Informations" }) +
                    " :"
                  }
                  labelClassName={this.props.labelClassName + " bold-label"}
                />
                <div className="col-12">
                  <TextareaCounter
                    className="textareaCounter"
                    rows={4}
                    countLimit={800}
                    value={this.state.informations}
                    onChange={(e) =>
                      this.onChange("informations", e.target.value)
                    }
                  />
                </div>
              </div>
            </div>
          </Modal.Body>

          <Modal.Footer>
            <Button variant="secondary" onClick={() => this.close()}>
              <FormattedMessage id="Cancel" />
            </Button>
            <Button
              variant="info"
              onClick={() => this.AddEquipementToMateriovigilance()}
            >
              <FormattedMessage
                id={
                  this.props.materiovigilanceLoaded === undefined
                    ? "Validate"
                    : "Update"
                }
              />
            </Button>
          </Modal.Footer>
        </Modal>
        {this.state.modal}
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    materialsTypes: state.materialsTypes,
    checkpoints: state.checkpoints,
    models: state.models,
    equipments: state.equipments,
    brands: state.brands,
    materiovigilance: state.materiovigilance,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onAddEquipementToMateriovigilance: (data, successCallback) =>
      dispatch(addEquipementToMateriovigilance(data, successCallback)),
    onUpdateEquipementToMateriovigilance: (data, successCallback) =>
      dispatch(updateEquipementToMateriovigilance(data, successCallback)),
    onAddDocMateriovigilance: (data, successCallback) =>
      dispatch(addDocMateriovigilance(data, successCallback)),
    onRemoveMateriovigilanceFile: (materiovigilanceId, successCallback) =>
      dispatch(removeMateriovigilanceFile(materiovigilanceId, successCallback)),
    onGetMateriovigilanceEquipement: () =>
      dispatch(getMateriovigilanceEquipement()),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(injectIntl(MateriovigilanceModalAdmin));
