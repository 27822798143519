import { GET_ORDERS } from "../../actions/orders/orders";

export default function orders(state = [], action) {
  switch (action.type) {
    case GET_ORDERS:
      return action.orders;
    default:
      return state;
  }
}
